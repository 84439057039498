import { CONSTANT_VALUES } from '../constants';

const { bathroomWc } = CONSTANT_VALUES;

export const defaultBathroomWc = {
  bath: {
    newBath: false,
    newBathNumber: 0,
    newBathsDimensions: [], // room-dimensions object
    changeBath: false,
  },
  wc: {
    newWc: false,
    newWcNumber: 0,
    newWcDimensions: [], // room-dimensions object
    changeWc: false,
  },
  bathWcCoatings: {
    value: false,
    dimensions: [], // room-dimensions object
    coatings: [], // bath-wc-coating objects
  },
  bathroomWcCost: bathroomWc,
};
