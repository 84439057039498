import React from 'react'

import NewExternalFrames from './NewExternalFrames';
import NewInternalDoors from './NewInternalDoors';
import InternalDoorsPainting from './InternalDoorsPainting';
import ArmoredDoors from './ArmoredDoors';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Frames = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section frames ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Κουφώματα'
          headerClick={ () => { headerClick(); } }
        />

        <NewExternalFrames />
        <NewInternalDoors />
        <InternalDoorsPainting />
        <ArmoredDoors />
      </SimpleSegment>
    </div>
  );
};

export default Frames;
