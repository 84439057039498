import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_NEW_EXTERNAL_MASONRY, SET_NEW_INTERNAL_MASONRY,
} from '../actions/action-masonry';

const { newExternal, newInternal, masonryCost } = defaultValues.masonry;

export default (state = {
  newExternal, newInternal, masonryCost,
}, action) => {
  switch (action.type) {
    case SET_NEW_EXTERNAL_MASONRY:
      return copyObject(state, {
        newExternal: action.payload,
      });
    case SET_NEW_INTERNAL_MASONRY:
      return copyObject(state, {
        newInternal: action.payload,
      });
    default:
      return state;
  }
}
 