import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setHeatersMovingAction } from '../../../redux/actions/action-heating';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const heatersMovingTypeOptions = [
  { text: 'Εντός δαπέδου σωλήνωση (τουμπόραμα/ppr)', value: 'inFloor' },
  { text: 'Εκτός δαπέδου σωλήνωση (χαλκοσωλήνα)', value: 'outOfFloor' },
];

class MovingHeaters extends Component {
  onHeatersMovingChange(value) {
    const { heatersMoving } = this.props.heating;
    heatersMoving.value = value;

    this.props.setHeatersMovingAction(heatersMoving);
  }

  onHeatersMovingNumberChange(event) {
    const { heatersMoving } = this.props.heating;
    heatersMoving.number = event.target.value;

    this.props.setHeatersMovingAction(heatersMoving);
  }

  onHeatersMovingTypeChange(value) {
    const { heatersMoving } = this.props.heating;
    heatersMoving.type = value;

    this.props.setHeatersMovingAction(heatersMoving);
  }

  render() {
    const { newSource, heatersMoving } = this.props.heating;
    const nothing = newSource.value === 'nothing';
    const segmentClass = `sub-segment ${nothing ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Ανάγκη μεταφοράς υφιστάμενων σωμάτων εντός του ιδίου χώρου'
        />

        <SimpleToggle
          label='Μεταφορά υφιστάμενων σωμάτων'
          onChange={ (value) => { this.onHeatersMovingChange(value); }}
          value={ heatersMoving.value }
        />

        { heatersMoving.value &&
          <Fragment>
            <SimpleInput
              label='Αριθμός σωμάτων'
              placeholder='Αριθμός σωμάτων'
              value={ heatersMoving.number }
              onChange={ (e) => { this.onHeatersMovingNumberChange(e) } }
              type="number"
            />

            <SimpleSelect
              label="Είδος σωλήνωσης"
              placeholder="Είδος σωλήνωσης"
              options={ heatersMovingTypeOptions }
              value={ heatersMoving.type }
              onChange={ (value) => { this.onHeatersMovingTypeChange(value) } }
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHeatersMovingAction: (value) => dispatch(setHeatersMovingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(MovingHeaters);
