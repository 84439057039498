export const frames = {
  newExternalFrames: {
    whitePVCCostPerM2: 220,
    ralPVCCostPerM2: 250,
    efectoMaderaPVCCostPerM2: 250,
    whiteAluminiumnCostPerM2: 280,
    ralAluminiumnCostPerM2: 300,
    efectoMaderaAluminiumnCostPerM2: 320,
  },
  internalDoors: {
    openingLaminateCostPerDoor: 200,
    openingVeneerCostPerDoor: 270,
    openingLacquerCostPerDoor: 280,

    slidingLaminateCostPerDoor: 350,
    slidingVeneerCostPerDoor: 420,
    slidingLacquerCostPerDoor: 430,

    doubleSlidingLaminateCostPerDoor: 640,
    doubleSlidingVeneerCostPerDoor: 780,
    doubleSlidingLacquerCostPerDoor: 800,

    splitLaminateCostPerDoor: 350,
    splitVeneerCostPerDoor: 420,
    splitLacquerCostPerDoor: 430,

    loftLaminateCostPerDoor: 100,
    loftVeneerCostPerDoor: 140,
    loftLacquerCostPerDoor: 140,
  },
  internalDoorsPaintingCost: {
    shortSimpleCostPerDoor: 50,
    tallSimpleCostPerDoor: 80,
    shortFlatbedCostPerDoor: 150,
    tallFlatbedCostPerDoor: 200,
  },
  armoredDoorsCostPerDoor: 500,
};
