export const SET_CURRENT_SOURCE_HEATING = 'SET_CURRENT_SOURCE_HEATING';
export const SET_NEW_SOURCE_HEATING = 'SET_NEW_SOURCE_HEATING';
export const SET_HEATERS_DISMANTLING_HEATING = 'SET_HEATERS_DISMANTLING_HEATING';
export const SET_HEATERS_MOVING_HEATING = 'SET_HEATERS_MOVING_HEATING';
export const SET_NEW_HEATERS_HEATING = 'SET_NEW_HEATERS_HEATING';
export const SET_NEW_BOILER_HEATING = 'SET_NEW_BOILER_HEATING';
export const SET_NEW_FIREPLACE_HEATING = 'SET_NEW_FIREPLACE_HEATING';

export const setCurrentSourceHeatingAction = (currentSource) =>
  dispatch => {
    dispatch({
      type: SET_CURRENT_SOURCE_HEATING,
      payload: currentSource,
    });
  };

export const setNewSourceHeatingAction = (newSource) =>
  dispatch => {
    dispatch({
      type: SET_NEW_SOURCE_HEATING,
      payload: newSource,
    });
  };

export const setHeatersDismantlingAction = (heatersDismantling) =>
  dispatch => {
    dispatch({
      type: SET_HEATERS_DISMANTLING_HEATING,
      payload: heatersDismantling,
    });
  };

export const setHeatersMovingAction = (heatersMoving) =>
  dispatch => {
    dispatch({
      type: SET_HEATERS_MOVING_HEATING,
      payload: heatersMoving,
    });
  };

export const setNewHeatersAction = (newHeaters) =>
  dispatch => {
    dispatch({
      type: SET_NEW_HEATERS_HEATING,
      payload: newHeaters,
    });
  };

export const setNewBoilerAction = (newBoiler) =>
  dispatch => {
    dispatch({
      type: SET_NEW_BOILER_HEATING,
      payload: newBoiler,
    });
  };

export const setNewFireplaceAction = (newFireplace) =>
  dispatch => {
    dispatch({
      type: SET_NEW_FIREPLACE_HEATING,
      payload: newFireplace,
    });
  };
