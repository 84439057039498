export const insulationCalc = (state) => {
  const { surface } = state.preliminaryQuestions;

  const {
    wallInsulation, waterproofing, thermalInsulation,
    internalFloorInsulation, insulationCost,
  } = state.insulation;
  
  let wallInsulationCost = 0;
  let waterproffingCost = 0;

  if (wallInsulation.value) {
    if (wallInsulation.sameInsulationType) {
      const wallInsulationCostPerM2 = wallInsulation.type === 'external'
        ? insulationCost.wallInsulation.externalCostPerM2
        : insulationCost.wallInsulation.internalCostPerM2;

      wallInsulationCost = wallInsulationCostPerM2 * wallInsulation.surface;
    } else {
      const intCostPerM2 = insulationCost.wallInsulation.internalCostPerM2;
      const extCostPerM2 = insulationCost.wallInsulation.externalCostPerM2;

      wallInsulationCost = intCostPerM2 * wallInsulation.internalSurface
        + extCostPerM2 * wallInsulation.externalSurface;
    }
  }

  if (waterproofing.value) {
    const waterproofingSurface = surface * insulationCost.waterproofing.calcedSurfaceCoefficient;

    let waterproffingCostPerM2 = 0;
    if (waterproofing.type === 'cementitious') {
      waterproffingCostPerM2 = insulationCost.waterproofing.cementitiousCostPerM2;
    } else if (waterproofing.type === 'asphalt') {
      waterproffingCostPerM2 = insulationCost.waterproofing.asphaltCostPerM2;
    } else if (waterproofing.type === 'epdm') {
      waterproffingCostPerM2 = insulationCost.waterproofing.epdmCostPerM2;
    }

    waterproffingCost = waterproofingSurface * waterproffingCostPerM2;
  }

  const thermalInsulationCost = thermalInsulation.value
    ? surface * insulationCost.thermalInsulationPerM2 : 0;

  const internalFloorInsulationCost = !thermalInsulation.value && internalFloorInsulation.value
    ? surface * insulationCost.internalFloorInsulationPerM2 : 0;

  const cost = {
    wallInsulationCost: {
      display: wallInsulationCost > 0,
      text: 'Κόστος μόνωσης τοίχων',
      cost: wallInsulationCost > 0 ? wallInsulationCost : 0,
    },
    waterproffingCost: {
      display: waterproffingCost > 0,
      text: 'Κόστος υγρομόνωσης',
      cost: waterproffingCost > 0 ? waterproffingCost : 0,
    },
    thermalInsulationCost: {
      display: thermalInsulationCost > 0,
      text: 'Κόστος θερμομόνωσης',
      cost: thermalInsulationCost > 0 ? thermalInsulationCost : 0,
    },
    internalFloorInsulationCost: {
      display: internalFloorInsulationCost > 0,
      text: 'Κόστος εσωτερικής θερμομόνωσης οροφών',
      cost: internalFloorInsulationCost > 0 ? internalFloorInsulationCost : 0,
    }
  };

  const all = cost.wallInsulationCost.cost
    + cost.waterproffingCost.cost
    + cost.thermalInsulationCost.cost
    + cost.internalFloorInsulationCost.cost;

  const title = 'Μονώσεις';
  const sumTitle = 'Συνολικό κόστος εργασιών μόνωσης';

  return { title, sumTitle, cost, all };
};
