import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setIndoorFloorAction } from '../../../redux/actions/action-floors';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMultiSelect from '../../simple-components/SimpleMultiSelect';

const indoorFloorTypeOptions = [
  { text: 'Πλακίδια (10 Euro / m2)', value: 'tile-cat1' },
  { text: 'Πλακίδια (20 Euro / m2)', value: 'tile-cat2' },
  { text: 'Πλακίδια (30 Euro / m2)', value: 'tile-cat3' },
  { text: 'Laminate (10 Euro / m2', value: 'laminate-cat1' },
  { text: 'Laminate (18 Euro / m2)', value: 'laminate-cat2' },
  { text: 'Laminate (25 Euro / m2)', value: 'laminate-cat3' },
  { text: 'Ξύλινο Καρφωτό (12 Euro / m2)', value: 'wooden-nailed-cat1' },
  { text: 'Ξύλινο Καρφωτό (20 Euro / m2)', value: 'wooden-nailed-cat2' },
  { text: 'Ξύλινο Καρφωτό (30 Euro / m2)', value: 'wooden-nailed-cat3' },
  { text: 'Ξύλινο Κολυμπητό (30 Euro / m2)', value: 'wooden-swimming-cat1' },
  { text: 'Ξύλινο Κολυμπητό (40 Euro / m2)', value: 'wooden-swimming-cat2' },
  { text: 'Ξύλινο Κολυμπητό (50 Euro / m2)', value: 'wooden-swimming-cat3' },
  { text: 'Μάρμαρο (30 Euro / m2)', value: 'marble-cat1' },
  { text: 'Μάρμαρο (40 Euro / m2)', value: 'marble-cat2' },
  { text: 'Μάρμαρο (50 Euro / m2)', value: 'marble-cat3' },
  { text: 'Βινυλικές Λωρίδες (15 Euro / m2)', value: 'vinyl-strips-cat1' },
  { text: 'Βινυλικές Λωρίδες (20 Euro / m2)', value: 'vinyl-strips-cat2' },
  { text: 'Βινυλικές Λωρίδες (30 Euro / m2)', value: 'vinyl-strips-cat3' },
];

class IndoorFloor extends Component {
  onIndoorFloorChange(value) {
    const { indoorFloor } = this.props.floors;
    indoorFloor.value = value;

    this.props.setIndoorFloorAction(indoorFloor);
  }

  onFloorSameTypeChange(value) {
    const { indoorFloor } = this.props.floors;
    indoorFloor.sameFloorType = value;

    this.props.setIndoorFloorAction(indoorFloor);
  }

  onFloorSurfaceChange(event, type) {
    const { indoorFloor } = this.props.floors;

    if (type === 'tile-cat1') {
      indoorFloor.tileCat1Surface = event.target.value;
    } else if (type === 'tile-cat2') {
      indoorFloor.tileCat2Surface = event.target.value;
    } else if (type === 'tile-cat3') {
      indoorFloor.tileCat3Surface = event.target.value;
    } else if (type === 'laminate-cat1') {
      indoorFloor.laminateCat1Surface = event.target.value;
    } else if (type === 'laminate-cat2') {
      indoorFloor.laminateCat2Surface = event.target.value;
    } else if (type === 'laminate-cat3') {
      indoorFloor.laminateCat3Surface = event.target.value;
    } else if (type === 'wooden-nailed-cat1') {
      indoorFloor.woodenNailedCat1Surface = event.target.value;
    } else if (type === 'wooden-nailed-cat2') {
      indoorFloor.woodenNailedCat2Surface = event.target.value;
    } else if (type === 'wooden-nailed-cat3') {
      indoorFloor.woodenNailedCat3Surface = event.target.value;
    } else if (type === 'wooden-swimming-cat1') {
      indoorFloor.woodenSwimmingCat1Surface = event.target.value;
    } else if (type === 'wooden-swimming-cat2') {
      indoorFloor.woodenSwimmingCat2Surface = event.target.value;
    } else if (type === 'wooden-swimming-cat3') {
      indoorFloor.woodenSwimmingCat3Surface = event.target.value;
    } else if (type === 'marble-cat1') {
      indoorFloor.marbleCat1Surface = event.target.value;
    } else if (type === 'marble-cat2') {
      indoorFloor.marbleCat2Surface = event.target.value;
    } else if (type === 'marble-cat3') {
      indoorFloor.marbleCat3Surface = event.target.value;
    } else if (type === 'vinyl-strips-cat1') {
      indoorFloor.vinylStripsCat1Surface = event.target.value;
    } else if (type === 'vinyl-strips-cat2') {
      indoorFloor.vinylStripsCat2Surface = event.target.value;
    } else if (type === 'vinyl-strips-cat3') {
      indoorFloor.vinylStripsCat3Surface = event.target.value;
    }

    this.props.setIndoorFloorAction(indoorFloor);
  }

  onIndoorFloorSurfaceChange(event) {
    const { indoorFloor } = this.props.floors;
    indoorFloor.surface = event.target.value;

    this.props.setIndoorFloorAction(indoorFloor);
  }

  onIndoorFloorTypeChange(value) {
    const { indoorFloor } = this.props.floors;
    indoorFloor.type = value;

    this.props.setIndoorFloorAction(indoorFloor);
  }

  onIndoorFloorTypesChange(values) {
    const { indoorFloor } = this.props.floors;
    indoorFloor.types = values;

    if (values.includes('tile-cat1')) {
      indoorFloor.tileCat1Surface = 0;
    }
    
    if (values.includes('tile-cat2')) {
      indoorFloor.tileCat2Surface = 0;
    }
    
    if (values.includes('tile-cat3')) {
      indoorFloor.tileCat3Surface = 0;
    }
    
    if (values.includes('laminate-cat1')) {
      indoorFloor.laminateCat1Surface = 0;
    }
    
    if (values.includes('laminate-cat2')) {
      indoorFloor.laminateCat2Surface = 0;
    }
    
    if (values.includes('laminate-cat3')) {
      indoorFloor.laminateCat3Surface = 0;
    }
    
    if (values.includes('wooden-nailed-cat1')) {
      indoorFloor.woodenNailedCat1Surface = 0;
    }
    
    if (values.includes('wooden-nailed-cat2')) {
      indoorFloor.woodenNailedCat2Surface = 0;
    }
    
    if (values.includes('wooden-nailed-cat3')) {
      indoorFloor.woodenNailedCat3Surface = 0;
    }
    
    if (values.includes('wooden-swimming-cat1')) {
      indoorFloor.woodenSwimmingCat1Surface = 0;
    }
    
    if (values.includes('wooden-swimming-cat2')) {
      indoorFloor.woodenSwimmingCat2Surface = 0;
    }
    
    if (values.includes('wooden-swimming-cat3')) {
      indoorFloor.woodenSwimmingCat3Surface = 0;
    }
    
    if (values.includes('marble-cat1')) {
      indoorFloor.marbleCat1Surface = 0;
    }
    
    if (values.includes('marble-cat2')) {
      indoorFloor.marbleCat2Surface = 0;
    }
    
    if (values.includes('marble-cat3')) {
      indoorFloor.marbleCat3Surface = 0;
    }
    
    if (values.includes('vinyl-strips-cat1')) {
      indoorFloor.vinylStripsCat1Surface = 0;
    }
    
    if (values.includes('vinyl-strips-cat2')) {
      indoorFloor.vinylStripsCat2Surface = 0;
    }
    
    if (values.includes('vinyl-strips-cat3')) {
      indoorFloor.vinylStripsCat3Surface = 0;
    }

    this.props.setIndoorFloorAction(indoorFloor);
  }

  render() {
    const { indoorFloor } = this.props.floors;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Δάπεδο εσωτερικών χώρων'
        />

        <SimpleToggle
          label='Δάπεδο εσωτερικών χώρων'
          onChange={ (value) => { this.onIndoorFloorChange(value); }}
          value={ indoorFloor.value }
        />

        { indoorFloor.value &&
          <SimpleToggle
            label='Ίδιο δάπεδο σε όλο το ακίνητο'
            onChange={ (value) => { this.onFloorSameTypeChange(value); }}
            value={ indoorFloor.sameFloorType }
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType &&
          <SimpleMultiSelect
            label="Τύπος δαπέδου"
            placeholder="Τύπος δαπέδου"
            options={ indoorFloorTypeOptions }
            value={ indoorFloor.types }
            onChange={ (values) => { this.onIndoorFloorTypesChange(values) } }
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('tile-cat1') &&
          <SimpleInput
            label='Εμβαδόν Πλακιδίων(10 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν Πλακιδίων(10 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.tileCat1Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'tile-cat1') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('tile-cat2') &&
          <SimpleInput
            label='Εμβαδόν Πλακιδίων(20 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν Πλακιδίων(20 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.tileCat2Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'tile-cat2') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('tile-cat3') &&
          <SimpleInput
            label='Εμβαδόν Πλακιδίων(30 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν Πλακιδίων(30 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.tileCat3Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'tile-cat3') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('laminate-cat1') &&
          <SimpleInput
            label='Εμβαδόν Laminate(10 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν Laminate(10 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.laminateCat1Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'laminate-cat1') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('laminate-cat2') &&
          <SimpleInput
            label='Εμβαδόν Laminate(18 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν Laminate(18 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.laminateCat2Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'laminate-cat2') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('laminate-cat3') &&
          <SimpleInput
            label='Εμβαδόν Laminate(25 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν Laminate(25 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.laminateCat3Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'laminate-cat3') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('wooden-nailed-cat1') &&
          <SimpleInput
            label='Εμβαδόν ξύλινου καρφωτού (12 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν ξύλινου καρφωτού (12 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.woodenNailedCat1Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden-nailed-cat1') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('wooden-nailed-cat2') &&
          <SimpleInput
            label='Εμβαδόν ξύλινου καρφωτού (20 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν ξύλινου καρφωτού (20 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.woodenNailedCat2Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden-nailed-cat2') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('wooden-nailed-cat3') &&
          <SimpleInput
            label='Εμβαδόν ξύλινου καρφωτού (30 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν ξύλινου καρφωτού (30 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.woodenNailedCat3Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden-nailed-cat3') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('wooden-swimming-cat1') &&
          <SimpleInput
            label='Εμβαδόν ξύλινου κολυμπητού (30 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν ξύλινου κολυμπητού (30 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.woodenSwimmingCat1Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden-swimming-cat1') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('wooden-swimming-cat2') &&
          <SimpleInput
            label='Εμβαδόν ξύλινου κολυμπητού (40 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν ξύλινου κολυμπητού (40 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.woodenSwimmingCat2Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden-swimming-cat2') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('wooden-swimming-cat3') &&
          <SimpleInput
            label='Εμβαδόν ξύλινου κολυμπητού (50 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν ξύλινου κολυμπητού (50 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.woodenSwimmingCat3Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden-swimming-cat3') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('marble-cat1') &&
          <SimpleInput
            label='Εμβαδόν μάρμαρου (30 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν μάρμαρου (30 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.marbleCat1Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'marble-cat1') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('marble-cat2') &&
          <SimpleInput
            label='Εμβαδόν μάρμαρου (40 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν μάρμαρου (40 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.marbleCat2Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'marble-cat2') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('marble-cat3') &&
          <SimpleInput
            label='Εμβαδόν μάρμαρου (50 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν μάρμαρου (50 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.marbleCat3Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'marble-cat3') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('vinyl-strips-cat1') &&
          <SimpleInput
            label='Εμβαδόν βυνιλικών λωρίδων (15 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν βυνιλικών λωρίδων (15 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.vinylStripsCat1Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'vinyl-strips-cat1') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('vinyl-strips-cat2') &&
          <SimpleInput
            label='Εμβαδόν βυνιλικών λωρίδων (20 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν βυνιλικών λωρίδων (20 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.vinylStripsCat2Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'vinyl-strips-cat2') } }
            type="number"
          />
        }

        { indoorFloor.value && !indoorFloor.sameFloorType && indoorFloor.types.includes('vinyl-strips-cat3') &&
          <SimpleInput
            label='Εμβαδόν βυνιλικών λωρίδων (30 Euro/m2) (σε τ.μ.)'
            placeholder='Εμβαδόν βυνιλικών λωρίδων (30 Euro/m2) (σε τ.μ.)'
            value={ indoorFloor.vinylStripsCat3Surface }
            onChange={ (e) => { this.onFloorSurfaceChange(e, 'vinyl-strips-cat3') } }
            type="number"
          />
        }

        { indoorFloor.value && indoorFloor.sameFloorType &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν δαπέδου (σε τ.μ.)'
              placeholder='Εμβαδόν δαπέδου (σε τ.μ.)'
              value={ indoorFloor.surface }
              onChange={ (e) => { this.onIndoorFloorSurfaceChange(e) } }
              type="number"
            />

            <SimpleSelect
              label="Τύπος δαπέδου"
              placeholder="Τύπος δαπέδου"
              options={ indoorFloorTypeOptions }
              value={ indoorFloor.type }
              onChange={ (value) => { this.onIndoorFloorTypeChange(value) } }
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setIndoorFloorAction: (value) => dispatch(setIndoorFloorAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(IndoorFloor);
