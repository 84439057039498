export const floorCalc = (state) => {
  const { floor } = state.dismantling;
  const { wooden, mosaic } = floor.surface;
  const {
    outdoorTile, indoorFloor, marbleAndMosaicRubbing,
    woodenRubbing, marbleFeet, floorsCost,
  } = state.floors;

  let concreteCost = 0;
  let outdoorTileCost = 0;
  let indoorFloorCost = 0;
  let marbleAndMosaicRubbingCost = 0;
  let woodenRubbingCost = 0;
  let marbleFeetCost = 0;

  if (floor.value) {
    let floorSurface = 0;
    if (floor.types.includes('wooden')) {
      floorSurface += Number(wooden);
    }

    if (floor.types.includes('mosaic')) {
      floorSurface += Number(mosaic);
    }

    concreteCost = floorSurface * floorsCost.concretePerM2;
  }

  if (outdoorTile.value && outdoorTile.surface > 0) {
    let costPerM2 = 0;
    if (outdoorTile.type === 'tile-cat1') {
      costPerM2 = floorsCost.outdoorTile.tileCat1PerM2;
    } else if (outdoorTile.type === 'tile-cat2') {
      costPerM2 = floorsCost.outdoorTile.tileCat2PerM2;
    } else if (outdoorTile.type === 'tile-cat3') {
      costPerM2 = floorsCost.outdoorTile.tileCat3PerM2;
    }

    if (costPerM2 > 0) {
      const { laborCostPerM2, gluesCostPerM2 } = floorsCost.outdoorTile;
      costPerM2 += laborCostPerM2 + gluesCostPerM2;

      outdoorTileCost = costPerM2 * outdoorTile.surface;
    }
  }

  if (indoorFloor.value && indoorFloor.surface > 0 && indoorFloor.sameFloorType) {
    let costPerM2 = 0;
    if (indoorFloor.type === 'tile-cat1') {
      costPerM2 = floorsCost.indoorFloor.tileCat1CostPerM2;
      costPerM2 += floorsCost.indoorFloor.tileOtherCostPerM2;
    } else if (indoorFloor.type === 'tile-cat2') {
      costPerM2 = floorsCost.indoorFloor.tileCat2CostPerM2;
      costPerM2 += floorsCost.indoorFloor.tileOtherCostPerM2;
    } else if (indoorFloor.type === 'tile-cat3') {
      costPerM2 = floorsCost.indoorFloor.tileCat3CostPerM2;
      costPerM2 += floorsCost.indoorFloor.tileOtherCostPerM2;
    } else if (indoorFloor.type === 'laminate-cat1') {
      costPerM2 = floorsCost.indoorFloor.laminateCat1CostPerM2;
      costPerM2 += floorsCost.indoorFloor.laminateOtherCostPerM2;
    } else if (indoorFloor.type === 'laminate-cat2') {
      costPerM2 = floorsCost.indoorFloor.laminateCat2CostPerM2;
      costPerM2 += floorsCost.indoorFloor.laminateOtherCostPerM2;
    } else if (indoorFloor.type === 'laminate-cat3') {
      costPerM2 = floorsCost.indoorFloor.laminateCat3CostPerM2;
      costPerM2 += floorsCost.indoorFloor.laminateOtherCostPerM2;
    } else if (indoorFloor.type === 'wooden-nailed-cat1') {
      costPerM2 = floorsCost.indoorFloor.woodenNailedCat1CostPerM2;
      costPerM2 += floorsCost.indoorFloor.woodenNailedOtherCostPerM2;
    } else if (indoorFloor.type === 'wooden-nailed-cat2') {
      costPerM2 = floorsCost.indoorFloor.woodenNailedCat2CostPerM2;
      costPerM2 += floorsCost.indoorFloor.woodenNailedOtherCostPerM2;
    } else if (indoorFloor.type === 'wooden-nailed-cat3') {
      costPerM2 = floorsCost.indoorFloor.woodenNailedCat3CostPerM2;
      costPerM2 += floorsCost.indoorFloor.woodenNailedOtherCostPerM2;
    } else if (indoorFloor.type === 'wooden-swimming-cat1') {
      costPerM2 = floorsCost.indoorFloor.woodenSwimmingCat1CostPerM2;
      costPerM2 += floorsCost.indoorFloor.woodenSwimmingOtherCostPerM2;
    } else if (indoorFloor.type === 'wooden-swimming-cat2') {
      costPerM2 = floorsCost.indoorFloor.woodenSwimmingCat2CostPerM2;
      costPerM2 += floorsCost.indoorFloor.woodenSwimmingOtherCostPerM2;
    } else if (indoorFloor.type === 'wooden-swimming-cat3') {
      costPerM2 = floorsCost.indoorFloor.woodenSwimmingCat3CostPerM2;
      costPerM2 += floorsCost.indoorFloor.woodenSwimmingOtherCostPerM2;
    } else if (indoorFloor.type === 'marble-cat1') {
      costPerM2 = floorsCost.indoorFloor.marbleCat1CostPerM2;
      costPerM2 += floorsCost.indoorFloor.marbleOtherCostPerM2;
    } else if (indoorFloor.type === 'marble-cat2') {
      costPerM2 = floorsCost.indoorFloor.marbleCat2CostPerM2;
      costPerM2 += floorsCost.indoorFloor.marbleOtherCostPerM2;
    } else if (indoorFloor.type === 'marble-cat3') {
      costPerM2 = floorsCost.indoorFloor.marbleCat3CostPerM2;
      costPerM2 += floorsCost.indoorFloor.marbleOtherCostPerM2;
    } else if (indoorFloor.type === 'vinyl-strips-cat1') {
      costPerM2 = floorsCost.indoorFloor.vinylStripsCat1CostPerM2;
      costPerM2 += floorsCost.indoorFloor.vinylStripsOtherCostPerM2;
    } else if (indoorFloor.type === 'vinyl-strips-cat2') {
      costPerM2 = floorsCost.indoorFloor.vinylStripsCat2CostPerM2;
      costPerM2 += floorsCost.indoorFloor.vinylStripsOtherCostPerM2;
    } else if (indoorFloor.type === 'vinyl-strips-cat3') {
      costPerM2 = floorsCost.indoorFloor.vinylStripsCat3CostPerM2;
      costPerM2 += floorsCost.indoorFloor.vinylStripsOtherCostPerM2;
    }

    indoorFloorCost = costPerM2 * indoorFloor.surface;
  } else if (indoorFloor.value && !indoorFloor.sameFloorType) {
    const {
      tileCat1Surface, tileCat2Surface, tileCat3Surface,
      laminateCat1Surface, laminateCat2Surface, laminateCat3Surface,
      woodenNailedCat1Surface, woodenNailedCat2Surface, woodenNailedCat3Surface,
      woodenSwimmingCat1Surface, woodenSwimmingCat2Surface, woodenSwimmingCat3Surface,
      marbleCat1Surface, marbleCat2Surface, marbleCat3Surface,
      vinylStripsCat1Surface, vinylStripsCat2Surface, vinylStripsCat3Surface,
    } = indoorFloor;

    const {
      tileCat1CostPerM2, tileCat2CostPerM2, tileCat3CostPerM2, tileOtherCostPerM2,
      laminateCat1CostPerM2, laminateCat2CostPerM2, laminateCat3CostPerM2, laminateOtherCostPerM2,
      woodenNailedCat1CostPerM2, woodenNailedCat2CostPerM2, woodenNailedCat3CostPerM2, woodenNailedOtherCostPerM2,
      woodenSwimmingCat1CostPerM2, woodenSwimmingCat2CostPerM2, woodenSwimmingCat3CostPerM2, woodenSwimmingOtherCostPerM2,
      marbleCat1CostPerM2, marbleCat2CostPerM2, marbleCat3CostPerM2, marbleOtherCostPerM2,
      vinylStripsCat1CostPerM2, vinylStripsCat2CostPerM2, vinylStripsCat3CostPerM2, vinylStripsOtherCostPerM2,
    } = floorsCost.indoorFloor;

    indoorFloorCost = tileCat1Surface * (tileCat1CostPerM2 + tileOtherCostPerM2)
      + tileCat2Surface * (tileCat2CostPerM2 + tileOtherCostPerM2)
      + tileCat3Surface * (tileCat3CostPerM2 + tileOtherCostPerM2)
      + laminateCat1Surface * (laminateCat1CostPerM2 + laminateOtherCostPerM2)
      + laminateCat2Surface * (laminateCat2CostPerM2 + laminateOtherCostPerM2)
      + laminateCat3Surface * (laminateCat3CostPerM2 + laminateOtherCostPerM2)
      + woodenNailedCat1Surface * (woodenNailedCat1CostPerM2 + woodenNailedOtherCostPerM2)
      + woodenNailedCat2Surface * (woodenNailedCat2CostPerM2 + woodenNailedOtherCostPerM2)
      + woodenNailedCat3Surface * (woodenNailedCat3CostPerM2 + woodenNailedOtherCostPerM2)
      + woodenSwimmingCat1Surface * (woodenSwimmingCat1CostPerM2 + woodenSwimmingOtherCostPerM2)
      + woodenSwimmingCat2Surface * (woodenSwimmingCat2CostPerM2 + woodenSwimmingOtherCostPerM2)
      + woodenSwimmingCat3Surface * (woodenSwimmingCat3CostPerM2 + woodenSwimmingOtherCostPerM2)
      + marbleCat1Surface * (marbleCat1CostPerM2 + marbleOtherCostPerM2)
      + marbleCat2Surface * (marbleCat2CostPerM2 + marbleOtherCostPerM2)
      + marbleCat3Surface * (marbleCat3CostPerM2 + marbleOtherCostPerM2)
      + vinylStripsCat1Surface * (vinylStripsCat1CostPerM2 + vinylStripsOtherCostPerM2)
      + vinylStripsCat2Surface * (vinylStripsCat2CostPerM2 + vinylStripsOtherCostPerM2)
      + vinylStripsCat3Surface * (vinylStripsCat3CostPerM2 + vinylStripsOtherCostPerM2);
  }

  if (marbleAndMosaicRubbing.value) {
    marbleAndMosaicRubbingCost = floorsCost.marbleAndMosaicRubbingCostPerM2 *
      marbleAndMosaicRubbing.surface;
  }

  if (woodenRubbing.value) {
    woodenRubbingCost = floorsCost.woodenRubbingCostPerM2 * woodenRubbing.surface;
  }

  if (marbleFeet.value) {
    let marbleFeetCostPerM2 = 0;
    if (marbleFeet.type === 'rubbing') {
      marbleFeetCostPerM2 = floorsCost.marbleFeetRubbingCostPerM2;
    } else if (marbleFeet.type === 'new-marble') {
      marbleFeetCostPerM2 = floorsCost.marbleFeetNewCostPerM2;
    }

    marbleFeetCost = marbleFeetCostPerM2 * marbleFeet.surface;
  }

  const cost = {
    concreteCost: {
      display: concreteCost > 0,
      text: 'Κόστος έγχυσης γκρό μπετόν κατά την αποξήλωση δαπέδων',
      cost: concreteCost > 0 ? concreteCost : 0,
    },
    outdoorTileCost: {
      display: outdoorTileCost > 0,
      text: 'Κόστος πλακιδίων εξωτερικών χώρων',
      cost: outdoorTileCost > 0 ? outdoorTileCost : 0,
    },
    indoorFloorCost: {
      display: indoorFloorCost > 0,
      text: 'Κόστος προμήθειας / τοποθέτησης δαπέδων εσωτερικών χώρων',
      cost: indoorFloorCost > 0 ? indoorFloorCost : 0,
    },
    marbleAndMosaicRubbingCost: {
      display: marbleAndMosaicRubbingCost > 0,
      text: 'Κόστος τριψίματος / γυαλίσματος μαρμάρινων δαπέδων ή δαπέδων με μωσαϊκό',
      cost: marbleAndMosaicRubbingCost > 0 ? marbleAndMosaicRubbingCost : 0,
    },
    woodenRubbingCost: {
      display: woodenRubbingCost > 0,
      text: 'Κόστος τριψίματος / γυαλίσματος ξύλινων δαπέδων',
      cost: woodenRubbingCost > 0 ? woodenRubbingCost : 0,
    },
    marbleFeetCost: {
      display: marbleFeetCost > 0,
      text: 'Κόστος μαρμαροποδιών',
      cost: marbleFeetCost > 0 ? marbleFeetCost : 0,
    },
  };

  const all = cost.concreteCost.cost
    + cost.outdoorTileCost.cost
    + cost.indoorFloorCost.cost
    + cost.marbleAndMosaicRubbingCost.cost
    + cost.woodenRubbingCost.cost
    + cost.marbleFeetCost.cost;

  const title = 'Δάπεδα';
  const sumTitle = 'Συνολικό κόστος εργασιών δαπέδων';

  return { title, sumTitle, cost, all };
};
