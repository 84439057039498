import { bathroomWcCalc } from './bathroom-wc-calc';
import { coatingsCalc } from './coatings-calc';
import { coolingCalc } from './cooling-calc';
import { dismantlingCalc } from './dismantling-calc';
import { electricalCalc } from './electrical-calc';
import { floorCalc } from './floor-calc';
import { framesCalc } from './frames-calc';
import { heatingCalc } from './heating-calc';
import { insulationCalc } from './insulation-calc';
import { kitchenCalc } from './kitchen-calc';
import { masonryCalc } from './masonry-calc';
import { paintsCalc } from './paints-calc';
import { plasterCalc } from './plaster-calc';
import { plasterboardCalc } from './plasterboard-calc';
import { plumbingCalc } from './plumbing-calc';
import { wallCladdingCalc } from './wall-cladding-calc';
import { wardrobesCalc } from './wardrobes-calc';

export const projectInventoryCalc = (state) => {
  const { projectInventory, projectInventoryCost } = state.projectInventory;

  const bathroomWcAll = bathroomWcCalc(state).all;
  const coatingsAll = coatingsCalc(state).all;
  const coolingAll = coolingCalc(state).all;
  const dismantlingAll = dismantlingCalc(state).all;
  const electricalAll = electricalCalc(state).all;
  const floorAll = floorCalc(state).all;
  const framesAll = framesCalc(state).all;
  const heatingAll = heatingCalc(state).all;
  const insulationAll = insulationCalc(state).all;
  const kitchenAll = kitchenCalc(state).all;
  const masonryAll = masonryCalc(state).all;
  const paintsAll = paintsCalc(state).all;
  const plasterAll = plasterCalc(state).all;
  const plasterboardAll = plasterboardCalc(state).all;
  const plumbingAll = plumbingCalc(state).all;
  const wallCladdingAll = wallCladdingCalc(state).all;
  const wardrobesAll = wardrobesCalc(state).all;

  const allOtherCalc = bathroomWcAll + coatingsAll + coolingAll + dismantlingAll
    + electricalAll + floorAll + framesAll + heatingAll + insulationAll
    + kitchenAll + masonryAll + paintsAll + plasterAll + plasterboardAll
    + plumbingAll + wallCladdingAll + wardrobesAll;

  let projectCost = 0;
  if (projectInventory.value) {
    const {
      costThresshold,
      lowCostProjectFinalCost,
      highCostProjectFinalCost,
    } = projectInventoryCost;

    projectCost = allOtherCalc < costThresshold
      ? lowCostProjectFinalCost : highCostProjectFinalCost;
  }

  const cost = {
    projectInventory: {
      display: projectCost > 0,
      text: 'Κόστος ενσήμων Ι.Κ.Α.',
      cost: projectCost > 0 ? projectCost : 0,
    }
  };

  const all = cost.projectInventory.cost;
  const title = 'Απογραφή έργου στο ΙΚΑ';
  const sumTitle = 'Συνολικό κόστος ενσήμων Ι.Κ.Α.';

  return { title, sumTitle, cost, all };
};
