export const SET_FLOOR_PRELIMINARY_QUESTIONS = 'SET_FLOOR_PRELIMINARY_QUESTIONS';
export const SET_ROOMS_PRELIMINARY_QUESTIONS = 'SET_ROOMS_PRELIMINARY_QUESTIONS';
export const SET_SURFACE_PRELIMINARY_QUESTIONS = 'SET_SURFACE_PRELIMINARY_QUESTIONS';
export const SET_RUBBLE_REMOVAL_PRELIMINARY_QUESTIONS = 'SET_RUBBLE_REMOVAL_PRELIMINARY_QUESTIONS';
export const SET_REGION_PRELIMINARY_QUESTIONS = 'SET_REGION_PRELIMINARY_QUESTIONS';
export const SET_BUILDING_PRELIMINARY_QUESTIONS = 'SET_BUILDING_PRELIMINARY_QUESTIONS';

export const setFloorAction = (floor) => dispatch => {
  dispatch({
    type: SET_FLOOR_PRELIMINARY_QUESTIONS,
    payload: floor,
  });
};

export const setRoomsAction = (rooms) => dispatch => {
  dispatch({
    type: SET_ROOMS_PRELIMINARY_QUESTIONS,
    payload: rooms,
  });
};

export const setSurfaceAction = (surface) => dispatch => {
  dispatch({
    type: SET_SURFACE_PRELIMINARY_QUESTIONS,
    payload: surface,
  });
};

export const setRubbleRemovalAction = (rubbleRemoval) => dispatch => {
  dispatch({
    type: SET_RUBBLE_REMOVAL_PRELIMINARY_QUESTIONS,
    payload: rubbleRemoval,
  });
}

export const setRegionAction = (region) => dispatch => {
  dispatch({
    type: SET_REGION_PRELIMINARY_QUESTIONS,
    payload: region,
  });
};

export const setBuildingAction = (building) => dispatch => {
  dispatch({
    type: SET_BUILDING_PRELIMINARY_QUESTIONS,
    payload: building,
  });
};
