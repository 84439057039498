import React from 'react';

import AirCondition from './AirCondition';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Cooling = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section cooling ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Εργασίες Ψύξης'
          headerClick={ () => { headerClick(); } }
        />

        <AirCondition />
      </SimpleSegment>
    </div>
  );
};

export default Cooling;
