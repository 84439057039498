import { CONSTANT_VALUES } from '../constants';

const { frames } = CONSTANT_VALUES;

export const defaultFrames = {
  newExternalFrames: {
    value: false,
    number: 0,
    frames: [], // list of frames ()
  },
  internalDoors: {
    value: false,
    material: 'laminate',         // laminate, veneer or lacquer
    types: [], // list of door types ( could be opening, sliding, double-sliding, split, loft )
  },
  internalDoorsPainting: {
    value: false,
    number: 0,
    doorHeight: 'short-door',   // Could be short-door or tall-door
    doorType: 'simple-door',  // Could be simple-door or flatbed-door
  },
  armoredDoors: {
    value: false,
    number: 0,
  },
  framesCost: frames,
};
