import React from 'react';
import { connect } from 'react-redux';
import { setWcDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class WcDismantling extends React.Component {
  onWcDismantlingChange(value) {
    const { wc } = this.props.dismantling;
    wc.value = value;

    this.props.setWcDismantlingAction(wc);
  }

  render() {
    const { rooms } = this.props.preliminaryQuestions;
    const { wc } = this.props.dismantling;

    const className = `sub-segment ${ rooms.wc > 0 ? '' : 'hidden-ui' }`;
    
    return (
      <SimpleSegment
        style={{ display: rooms.wc > 0 ? 'block' : 'none' }}
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ className }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση WC'
        />

        <SimpleToggle
          label='Αποξήλωση WC;'
          onChange={ (value) => { this.onWcDismantlingChange(value); }}
          value={ wc.value }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWcDismantlingAction: (value) => dispatch(setWcDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WcDismantling);
