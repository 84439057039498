import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setProjectInventoryAction } from '../../../redux/actions/action-project-inventory';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { SEGMENT, HEADER } from '../../../config/constants';

class ProjectInventory extends Component {
  onProjectInventoryChange(value) {
    const { projectInventory } = this.props.projectInventory;
    projectInventory.value = value;

    this.props.setProjectInventoryAction(projectInventory);
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { projectInventory } = this.props.projectInventory;

    const { basic } = SEGMENT;
    const basicClassName = `section project-inventory ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Απογραφή έργου στο ΙΚΑ'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Απογραφή έργου στο ΙΚΑ'
            />

            <SimpleToggle
              label='Απογραφή έργου στο ΙΚΑ'
              onChange={ (value) => { this.onProjectInventoryChange(value); }}
              value={ projectInventory.value }
            />

          </SimpleSegment>
        </SimpleSegment>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setProjectInventoryAction: (value) => dispatch(setProjectInventoryAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInventory);
