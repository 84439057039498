export const floors = {
  concretePerM2: 13,
  outdoorTile: {
    tileCat1PerM2: 10,
    tileCat2PerM2: 20,
    tileCat3PerM2: 30,
    laborCostPerM2: 9,
    gluesCostPerM2: 3,
  },
  indoorFloor: {
    tileCat1CostPerM2: 10,
    tileCat2CostPerM2: 20,
    tileCat3CostPerM2: 30,
    tileOtherCostPerM2: 12,
    laminateCat1CostPerM2: 10,
    laminateCat2CostPerM2: 18,
    laminateCat3CostPerM2: 25,
    laminateOtherCostPerM2: 9,
    woodenNailedCat1CostPerM2: 12,
    woodenNailedCat2CostPerM2: 20,
    woodenNailedCat3CostPerM2: 30,
    woodenNailedOtherCostPerM2: 25,
    woodenSwimmingCat1CostPerM2: 30,
    woodenSwimmingCat2CostPerM2: 40,
    woodenSwimmingCat3CostPerM2: 50,
    woodenSwimmingOtherCostPerM2: 10,
    marbleCat1CostPerM2: 30,
    marbleCat2CostPerM2: 40,
    marbleCat3CostPerM2: 50,
    marbleOtherCostPerM2: 12,
    vinylStripsCat1CostPerM2: 15,
    vinylStripsCat2CostPerM2: 20,
    vinylStripsCat3CostPerM2: 30,
    vinylStripsOtherCostPerM2: 10,
  },
  marbleAndMosaicRubbingCostPerM2: 10,
  woodenRubbingCostPerM2: 12,
  marbleFeetRubbingCostPerM2: 3,
  marbleFeetNewCostPerM2: 14,
};
