import { CONSTANT_VALUES } from '../constants';

const { buildingPermit } = CONSTANT_VALUES;

export const defaultBuildingPermit = {
  buildingPermit: {
    value: false,
  },
  buildingPermitCost: buildingPermit,
};
