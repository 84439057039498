import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_INTERNAL_WALLS_PAINTS, SET_INTERNAL_ROOFS_PAINTS,
  SET_EXTERIOR_COLORS_PAINTS, SET_RAILING_PAINT_PAINTS,
} from '../actions/action-paints';

const {
  internalWalls, internalRoofs, exteriorColors,
  railingPaint, paintsCost,
} = defaultValues.paints;


export default (state = {
  internalWalls, internalRoofs, exteriorColors,
  railingPaint, paintsCost,
}, action) => {
  switch (action.type) {
    case SET_INTERNAL_WALLS_PAINTS:
      return copyObject(state, {
        internalWalls: action.payload,
      });
    case SET_INTERNAL_ROOFS_PAINTS:
      return copyObject(state, {
        internalRoofs: action.payload,
      });
    case SET_EXTERIOR_COLORS_PAINTS:
      return copyObject(state, {
        exteriorColors: action.payload,
      });
    case SET_RAILING_PAINT_PAINTS:
      return copyObject(state, {
        railingPaint: action.payload,
      });
    default:
      return state;
  }
}
 