import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_BUILDING_PERMIT,
} from '../actions/action-building-permit';

const { buildingPermit, buildingPermitCost } = defaultValues.buildingPermit;

export default (state = {
  buildingPermit, buildingPermitCost,
}, action) => {
  switch (action.type) {
    case SET_BUILDING_PERMIT:
      return copyObject(state, {
        buildingPermit: action.payload,
      });
    default:
      return state;
  }
}
