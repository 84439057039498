import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setMarbleAndMosaicRubbingFloorAction } from '../../../redux/actions/action-floors';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class MarbleAndMosaicFloorRubbing extends Component {
  onFloorRubbingChange(value) {
    const { marbleAndMosaicRubbing } = this.props.floors;
    marbleAndMosaicRubbing.value = value;

    this.props.setMarbleAndMosaicRubbingFloorAction(marbleAndMosaicRubbing);
  }

  onFloorRubbingSurfaceChange(event) {
    const { marbleAndMosaicRubbing } = this.props.floors;
    marbleAndMosaicRubbing.surface = event.target.value;

    this.props.setMarbleAndMosaicRubbingFloorAction(marbleAndMosaicRubbing);
  }

  render() {
    const { marbleAndMosaicRubbing } = this.props.floors;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Τρίψιμο - γυάλισμα μαρμάρου / μωσαϊκού'
        />

        <SimpleToggle
          label='Τρίψιμο - γυάλισμα'
          onChange={ (value) => { this.onFloorRubbingChange(value); }}
          value={ marbleAndMosaicRubbing.value }
        />

        { marbleAndMosaicRubbing.value &&
          <SimpleInput
            label='Εμβαδόν δαπέδου (σε τ.μ.)'
            placeholder='Εμβαδόν δαπέδου (σε τ.μ.)'
            value={ marbleAndMosaicRubbing.surface }
            onChange={ (e) => { this.onFloorRubbingSurfaceChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setMarbleAndMosaicRubbingFloorAction: (value) => dispatch(setMarbleAndMosaicRubbingFloorAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(MarbleAndMosaicFloorRubbing);
