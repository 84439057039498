export const kitchenCalc = (state) => {
  const { kitchen, kitchenCost } = state.kitchen;

  let kitchenConstructionCost = 0;
  if (kitchen.value) {
    const { doorMaterial } = kitchen;

    const length = kitchen.length * kitchenCost.kitchenTotalLengthMultiplier;
    let costPerM = 0;

    if (doorMaterial === 'veneer') {
      costPerM = kitchenCost.veneerCostPerM;
    } else if (doorMaterial === 'melamine') {
      costPerM = kitchenCost.melamineCostPerM;
    } else if (doorMaterial === 'acrylic') {
      costPerM = kitchenCost.acrylicCostPerM;
    } else if (doorMaterial === 'lacquered') {
      costPerM = kitchenCost.lacqueredCostPerM;
    }

    kitchenConstructionCost = length * costPerM;
  }

  const cost = {
    kitchenCost: {
      display: kitchenConstructionCost > 0,
      text: 'Κόστος κατασκευής κουζίνας',
      cost: kitchenConstructionCost > 0 ? kitchenConstructionCost : 0,
    },
  };

  const all = cost.kitchenCost.cost;

  const title = 'Κουζίνα';
  const sumTitle = 'Συνολικό κόστος κατασκευής και τοποθέτησης κουζίνας';

  return { title, sumTitle, cost, all };
};
