import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_CEILINGS_PLASTERBOARD, SET_WALL_CLADDING_PLASTERBOARD,
} from '../actions/action-plasterboard';

const { ceilings, wallCladding, plasterboardCost } = defaultValues.plasterboard;

export default (state = {
  ceilings, wallCladding, plasterboardCost,
}, action) => {
  switch (action.type) {
    case SET_CEILINGS_PLASTERBOARD:
      return copyObject(state, {
        ceilings: action.payload,
      });
    case SET_WALL_CLADDING_PLASTERBOARD:
      return copyObject(state, {
        wallCladding: action.payload,
      });
    default:
      return state;
  }
}
 