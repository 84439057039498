import React from 'react';
import { connect } from 'react-redux';
import SimpleSelect from '../../simple-components/SimpleSelect';
import { setRubbleRemovalAction } from '../../../redux/actions/action-preliminary-questions';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const removalOptions = [
  { text: 'Ασανσέρ', value: 'elevator' },
  { text: 'Κλιμακοστάσιο', value: 'stairs' },
  { text: 'Μηχανικό Μέσο (Ανεβατόρι)', value: 'mechanical-elevator' },
  { text: 'Χοάνη', value: 'hopper' },
  { text: 'Καλαθοφόρο όχημα', value: 'truck' },
]

class RubbleRemoval extends React.Component {
  onMethodChange(value) {
    this.props.setRubbleRemovalAction(value);
  }

  render() {
    const { floor, rubbleRemoval } = this.props.preliminaryQuestions;
    const segmentClass = `sub-segment ${floor >= 1 ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }>

        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Τρόποι Απομάκρυνσης Μπάζων'
        />

        <SimpleSelect
          label="Απομάκρυνση Μπάζων"
          placeholder="Επιθυμητός τρόπος απομάκρυνσης μπάζων"
          options={ removalOptions }
          value={ rubbleRemoval }
          onChange={ (value) => { this.onMethodChange(value) } }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setRubbleRemovalAction: (value) => dispatch(setRubbleRemovalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(RubbleRemoval);
