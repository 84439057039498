class BathWcCoating {
  constructor(
    investmentType,
    tilesInvestmentType,
    sanitaryWare,
    batteriesAndAccessories,
  ) {
    this.investmentType = investmentType; // could be tiles or forged-cement
    this.tilesInvestmentType = tilesInvestmentType; // could be tile-cat1, tile-cat2, tile-cat3

    this.bath = sanitaryWare.bath; // could be built-in-shower, porcelain-shower, bathhub
    this.washbasin = sanitaryWare.washbasin; // could be bathroom-furniture, built-in-bench, colonate-washbasin
    this.basin = sanitaryWare.basin; // could be floor-basin, hanging-basin

    this.batteriesType = batteriesAndAccessories; // could be simple, wall-mounted or nothing
  }

  calcCost(dimensions, bathroomWcCost) {
    const length = dimensions.length * 0.01;
    const height = dimensions.height * 0.01;
    const width = dimensions.width * 0.01;

    const surface = (length * width) + (2 * height * ( length + width ));

    let bathroomInvestementCost = 0;
    let sanitaryWareBathCost = 0;
    let sanitaryWareWashBasinCost = 0;
    let sanitaryWareBasinCost = 0;
    let bathroomAccessoriesCost = 0;
    let batteriesCost = 0;

    if (this.investmentType === 'tiles') {
      let catCostPerM2 = 0;
      if (this.tilesInvestmentType === 'tile-cat1') {
        catCostPerM2 = bathroomWcCost.investmentType.tile.cat1CostPerM2;
      } else if (this.tilesInvestmentType === 'tile-cat2') {
        catCostPerM2 = bathroomWcCost.investmentType.tile.cat2CostPerM2;
      } else if (this.tilesInvestmentType === 'tile-cat3') {
        catCostPerM2 = bathroomWcCost.investmentType.tile.cat3CostPerM2;
      }

      const {
        laborCostPerM2, gluesCostPerM2, preparationCost,
      } = bathroomWcCost.investmentType.tile;

      const catCost = catCostPerM2 * surface;
      const laborCost = laborCostPerM2 * surface;
      const gluesCost = gluesCostPerM2 * surface;

      bathroomInvestementCost = surface > 0
        ? preparationCost + catCost + laborCost + gluesCost : 0;

    } else if (this.investmentType === 'forged-cement') {
      const { forgedCementCostPerM2 } = bathroomWcCost.investmentType;
      bathroomInvestementCost = forgedCementCostPerM2 * surface;
    }

    const {
      builtInShowerCost, porcelainShowerCost, bathhubCost,
    } = bathroomWcCost.sanitaryWare.bath;

    if (this.bath === 'built-in-shower') {
      sanitaryWareBathCost = builtInShowerCost;
    } else if (this.bath === 'porcelain-shower') {
      sanitaryWareBathCost = porcelainShowerCost;
    } else if (this.bath === 'bathhub') {
      sanitaryWareBathCost = bathhubCost;
    }

    const {
      bathroomFurnitureCost, builtInBenchCost, colonateWashbasinCost,
    } = bathroomWcCost.sanitaryWare.washbasin;

    if (this.washbasin === 'bathroom-furniture') {
      sanitaryWareWashBasinCost = bathroomFurnitureCost;
    } else if (this.washbasin === 'built-in-bench') {
      sanitaryWareWashBasinCost = builtInBenchCost;
    } else if (this.washbasin === 'colonate-washbasin') {
      sanitaryWareWashBasinCost = colonateWashbasinCost;
    }

    const {
      floorBasinCost, hangingBasinCost,
    } = bathroomWcCost.sanitaryWare.basin;

    if (this.basin === 'floor-basin') {
      sanitaryWareBasinCost = floorBasinCost;
    } else if (this.basin === 'hanging-basin') {
      sanitaryWareBasinCost = hangingBasinCost;
    }

    if (this.batteriesType !== 'nothing') {
      const {
        bathroomAccessoryCost, batteriesType,
      } = bathroomWcCost.batteriesAndAccessories;
  
      if (this.batteriesType === 'simple') {
        bathroomAccessoriesCost = bathroomAccessoryCost;
        batteriesCost = batteriesType.simpleCost;
      } else if (this.batteriesType === 'wall-mounted') {
        bathroomAccessoriesCost = bathroomAccessoryCost;
        batteriesCost = batteriesType.wallMountedCost;
      }
    }

    const sanitaryWareCost = surface > 0
      ? sanitaryWareBathCost + sanitaryWareWashBasinCost + sanitaryWareBasinCost
      : 0;

    return bathroomInvestementCost + sanitaryWareCost + bathroomAccessoriesCost + batteriesCost;
  }
}

export default BathWcCoating;
