import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setKitchenAction } from '../../../redux/actions/action-kitchen';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { SEGMENT, HEADER } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

const kitchenDoorTypeOptions = [
  { text: 'Πορτάκια με επένδυση καπλαμά', value: 'veneer' },
  { text: 'Πορτάκια με επένδυση μελαμίνης', value: 'melamine' },
  { text: 'Ακρυλικά πορτάκια', value: 'acrylic' },
  { text: 'Λακαριστά πορτάκια', value: 'lacquered' },
];

class Kitchen extends Component {
  onKitchenChange(value) {
    const { kitchen } = this.props.kitchen;
    kitchen.value = value;

    this.props.setKitchenAction(kitchen);
  }

  onKitchenLengthChange(event) {
    const { kitchen } = this.props.kitchen;
    kitchen.length = event.target.value;

    this.props.setKitchenAction(kitchen);
  }

  onKitchenDoorTypeChange(value) {
    const { kitchen } = this.props.kitchen;
    kitchen.doorMaterial = value;

    this.props.setKitchenAction(kitchen);
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { kitchen } = this.props.kitchen;

    const { basic } = SEGMENT;
    const basicClassName = `section kitchen ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Κουζίνα'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Κατασκευή κουζίνας'
            />

            <SimpleToggle
              label='Κατασκευή κουζίνας'
              onChange={ (value) => { this.onKitchenChange(value); }}
              value={ kitchen.value }
            />

            { kitchen.value &&
              <Fragment>
                <SimpleInput
                  label='Μήκος πάγκου κουζίνας (σε μέτρα)'
                  placeholder='Μήκος πάγκου κουζίνας (σε μέτρα)'
                  value={ kitchen.length }
                  onChange={ (e) => { this.onKitchenLengthChange(e) } }
                  type="number"
                />

                <SimpleSelect
                  label="Υλικό επένδυσης πορτών κουζίνας"
                  placeholder="Υλικό επένδυσης πορτών κουζίνας"
                  options={ kitchenDoorTypeOptions }
                  value={ kitchen.doorMaterial }
                  onChange={ (value) => { this.onKitchenDoorTypeChange(value) } }
                />

                <SimpleMessage
                  icon="info"
                  header="Υποσημείωση 1"
                  content="Ο σκελετός σε κάθε περίπτωση κατασκευάζεται από ανθυγρή μελαμίνη"
                />

                <SimpleMessage
                  icon="info"
                  header="Υποσημείωση 2"
                  content="Στην τιμή δεν περιλαμβάνονται νεροχύτης, μπαταρία, ηλεκτρικές συσκευές"
                />

              </Fragment>
            }

            </SimpleSegment>
        </SimpleSegment>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setKitchenAction: (value) => dispatch(setKitchenAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Kitchen);
