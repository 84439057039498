import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_KITCHEN_DISMANTLING, SET_INTERNAL_FRAMES_DISMANTLING,
  SET_EXTERNAL_FRAMES_DISMANTLING, SET_BATH_DISMANTLING,
  SET_WC_DISMANTLING, SET_LOFT_DISMANTLING, SET_FLOOR_DISMANTLING,
  SET_WALL_DISMANTLING,
} from '../actions/action-dismantling';

const {
  rubbleRemoval, kitchen, internalFrames, externalFrames,
  bath, wc, loft, floor, wall, bucket, cleanliness,
} = defaultValues.dismantling;

export default (state = {
  rubbleRemoval, kitchen, internalFrames, externalFrames,
  bath, wc, loft, floor, wall, bucket, cleanliness,
}, action) => {
  switch (action.type) {
    case SET_KITCHEN_DISMANTLING:
      return copyObject(state, {
        kitchen: action.payload,
      });
    case SET_INTERNAL_FRAMES_DISMANTLING:
      return copyObject(state, {
        internalFrames: action.payload,
      });
    case SET_EXTERNAL_FRAMES_DISMANTLING:
      return copyObject(state, {
        externalFrames: action.payload,
      });
    case SET_BATH_DISMANTLING:
      return copyObject(state, {
        bath: action.payload,
      });
    case SET_WC_DISMANTLING:
      return copyObject(state, {
        wc: action.payload,
      });
    case SET_LOFT_DISMANTLING:
      return copyObject(state, {
        loft: action.payload,
      });
    case SET_FLOOR_DISMANTLING:
      return copyObject(state, {
        floor: action.payload,
      });
    case SET_WALL_DISMANTLING:
      return copyObject(state, {
        wall: action.payload,
      });
    default:
      return state;
  }
}
 