import React from 'react';
import { connect } from 'react-redux';
import { setKitchenPlumbingAction } from '../../../redux/actions/action-plumbing';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class NewKitchenPosition extends React.Component {
  onKitchenPlumbingChange(value) {
    const { kitchen } = this.props.plumbing;
    kitchen.newKitchen = value;

    this.props.setKitchenPlumbingAction(kitchen);
  }

  onKitchenMovePlumbingChange(value) {
    const { kitchen } = this.props.plumbing;
    kitchen.move = value;

    this.props.setKitchenPlumbingAction(kitchen);
  }

  render() {
    const { kitchen } = this.props.plumbing;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Νέα εγκατάσταση κουζίνας'
        />

        <SimpleToggle
          label='Νέα εγκατάσταση κουζίνας'
          onChange={ (value) => { this.onKitchenPlumbingChange(value); }}
          value={ kitchen.newKitchen }
        />

        <SimpleToggle
          label='Μεταφορά θέσης κουζίνας'
          onChange={ (value) => { this.onKitchenMovePlumbingChange(value); }}
          value={ kitchen.move }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setKitchenPlumbingAction: (value) => dispatch(setKitchenPlumbingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewKitchenPosition);
