import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_KITCHEN,
} from '../actions/action-kitchen';

const {
  kitchen, kitchenCost,
} = defaultValues.kitchen;

export default (state = {
  kitchen, kitchenCost,
}, action) => {
  switch (action.type) {
    case SET_KITCHEN:
      return copyObject(state, {
        kicthen: action.payload,
      });
    default:
      return state;
  }
}
