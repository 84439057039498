import { defaultPreliminaryQuestions } from './defaultValues/default-preliminary-questions';
import { defaultDismantling } from './defaultValues/default-dismantling';
import { defaultPlumbing } from './defaultValues/default-plumbing';
import { defaultHeating } from './defaultValues/default-heating';
import { defaultCooling } from './defaultValues/default-cooling';
import { defaultElectrical } from './defaultValues/default-electrical';
import { defaultMasonry } from './defaultValues/default-masonry';
import { defaultCoatings } from './defaultValues/default-coatings';
import { defaultPlasterboard } from './defaultValues/default-plasterboard';
import { defaultInsulation } from './defaultValues/default-insulation';
import { defaultBathroomWc } from './defaultValues/default-bathroom-wc';
import { defaultPlaster } from './defaultValues/default-plaster';
import { defaultFloors } from './defaultValues/default-floors';
import { defaultWallCladding } from './defaultValues/default-wall-cladding';
import { defaultFrames } from './defaultValues/default-frames';
import { defaultPaints } from './defaultValues/default-paints';
import { defaultKitchen } from './defaultValues/default-kitchen';
import { defaultWardrobes } from './defaultValues/default-wardrobes';
import { defaultProjectDesignAndManagement } from './defaultValues/default-project-design-and-management';
import { defaultProjectInventory } from './defaultValues/default-project-inventory';
import { defaultSmallScaleLicense } from './defaultValues/default-small-scale-license';
import { defaultBuildingPermit } from './defaultValues/default-building-permit';
import { defaultCustomFields } from './defaultValues/default-custom-fields';

export const defaultValues = {
  preliminaryQuestions: defaultPreliminaryQuestions,
  dismantling: defaultDismantling,
  plumbing: defaultPlumbing,
  heating: defaultHeating,
  cooling: defaultCooling,
  electrical: defaultElectrical,
  masonry: defaultMasonry,
  coatings: defaultCoatings,
  plasterboard: defaultPlasterboard,
  insulation: defaultInsulation,
  bathroomWc: defaultBathroomWc,
  plaster: defaultPlaster,
  floors: defaultFloors,
  wallCladding: defaultWallCladding,
  frames: defaultFrames,
  paints: defaultPaints,
  kitchen: defaultKitchen,
  wardrobes: defaultWardrobes,
  projectDesignAndManagement: defaultProjectDesignAndManagement,
  projectInventory: defaultProjectInventory,
  smallScaleLicense: defaultSmallScaleLicense,
  buildingPermit: defaultBuildingPermit,
  customFields: defaultCustomFields,
};
