export const plasterboardCalc = (state) => {
  const { ceilings, wallCladding, plasterboardCost } = state.plasterboard;

  const isCeilings = ceilings.value;
  const isWallCladding = wallCladding.value;

  let ceilingsCost = 0;
  let wallCladdingCost = 0;

  if (isCeilings) {
    const ceilingsSurface = ceilings.surface;
    ceilingsCost = ceilings.type === 'simple'
      ? ceilingsSurface * plasterboardCost.ceilings.simplePlasterboardPerM2
      : ceilingsSurface * plasterboardCost.ceilings.advancedPlasterboardPerM2;
  }

  if (isWallCladding) {
    wallCladdingCost = wallCladding.surface * plasterboardCost.wallCladdingPerM2;
  }

  const cost = {
    ceilingsCost: {
      display: ceilingsCost > 0,
      text: 'Κόστος κατασκευής οροφής με γυψοσανίδα',
      cost: ceilingsCost > 0 ? ceilingsCost : 0,
    },
    wallCladdingCost: {
      display: wallCladdingCost > 0,
      text: 'Κόστος κατασκευής τοιχου με γυψοσανίδα',
      cost: wallCladdingCost > 0 ? wallCladdingCost : 0,
    },
  };

  const all = cost.ceilingsCost.cost + cost.wallCladdingCost.cost;

  const title = 'Γυψοσανίδες';
  const sumTitle = 'Συνολικό κόστος εργασιών με γυψοσανίδα';

  return { title, sumTitle, cost, all };
};
