export const wallCladdingCalc = (state) => {
  const { wallCladding } = state.wallCladding;
  const {
    brickCostPerM2, vinylFilmCostPerM2, paintingCostPerM2,
  } = state.wallCladding.wallCladdingCost;

  let wallCladdingCost = 0;

  if (wallCladding.value) {
    let costPerM2 = 0;
    if (wallCladding.type === 'brick') {
      costPerM2 = brickCostPerM2;
    } else if (wallCladding.type === 'vinyl-film') {
      costPerM2 = vinylFilmCostPerM2;
    } else if (wallCladding.type === 'painting') {
      costPerM2 = paintingCostPerM2;
    }

    wallCladdingCost = costPerM2 * wallCladding.surface;
  }

  const cost = {
    wallCladdingCost: {
      display: wallCladdingCost > 0,
      text: 'Κόστος επένδυσης τοίχων με αρχιτεκτονικά υλικά',
      cost: wallCladdingCost > 0 ? wallCladdingCost: 0,
    }
  };

  const all = cost.wallCladdingCost.cost;

  const title = 'Επένδυση τοίχων με αρχιτεκτονικά υλικά';
  const sumTitle = 'Συνολικό κόστος επένδυσης τοίχων με αρχιτεκτονικά υλικά';

  return { title, sumTitle, cost, all };
};
