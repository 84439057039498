import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_SMALL_SCALE_LICENSE,
} from '../actions/action-small-scale-license';

const { smallScaleLicense, smallScaleLicenseCost } = defaultValues.smallScaleLicense;

export default (state = {
  smallScaleLicense, smallScaleLicenseCost,
}, action) => {
  switch (action.type) {
    case SET_SMALL_SCALE_LICENSE:
      return copyObject(state, {
        smallScaleLicense: action.payload,
      });
    default:
      return state;
  }
}
