import React from 'react';
import { connect } from 'react-redux';
import { setWashingMachinePlumbingAction } from '../../../redux/actions/action-plumbing';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class WashingMachineOutOfBath extends React.Component {
  onWashingMachineOutOfBathPlumbingChange(value) {
    const { washingMachine } = this.props.plumbing;
    washingMachine.outOfBath = value;

    this.props.setWashingMachinePlumbingAction(washingMachine);
  }

  onWashingMachineOutOfBathNumberChange(event) {
    const { washingMachine } = this.props.plumbing;
    washingMachine.numberOutOfBath = event.target.value;

    this.props.setWashingMachinePlumbingAction(washingMachine);
  }

  render() {
    const { washingMachine } = this.props.plumbing;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Πλυντήρια εκτός μπάνιου'
        />

        <SimpleToggle
          label='Πλυντήρια εκτός μπάνιου'
          onChange={ (value) => { this.onWashingMachineOutOfBathPlumbingChange(value); }}
          value={ washingMachine.outOfBath }
        />

        { washingMachine.outOfBath &&
          <SimpleInput
            label='Αριθμός παροχών'
            placeholder='Αριθμός παροχών'
            value={ washingMachine.numberOutOfBath }
            onChange={ (e) => { this.onWashingMachineOutOfBathNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWashingMachinePlumbingAction: (value) => dispatch(setWashingMachinePlumbingAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WashingMachineOutOfBath);
