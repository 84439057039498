import React from 'react';
import { connect } from 'react-redux';
import { setRegionAction } from '../../../redux/actions/action-preliminary-questions';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleMessage from '../../simple-components/SimpleMessage';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class Region extends React.Component {
  onRegionChange(value) {
    const region = this.props.preliminaryQuestions.region;
    region.insideCity = value;

    this.props.setRegionAction(region);
  }

  onDistanceChange(event) {
    const region = this.props.preliminaryQuestions.region;
    const { value } = event.target;

    region.distance = value;
    this.props.setRegionAction(region);
  }

  render() {
    const { region } = this.props.preliminaryQuestions;
    const { insideCity, distance, distanceThreshold } = region;
    const showDistanceMessage = !insideCity && distance >= distanceThreshold;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content="Περιοχή κατοικίας"
        />

        <div>
          <SimpleToggle
            label='Εντός αστικού ιστού'
            onChange={ (value) => { this.onRegionChange(value); }}
            value={ insideCity }
          />

          { !insideCity &&
            <div>
              <SimpleInput
                label={ 'Απόσταση από το κέντρο' }
                placeholder={ 'Απόσταση από το κέντρο της Θεσσαλονίκης' }
                value={ distance || 0 }
                onChange={ (e) => { this.onDistanceChange(e) } }
                type="number"
              />
            </div>
          }

          { showDistanceMessage &&
            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Για απόσταση μεγαλύτερη των 50 χλμ απαιτείται αυτοψία μηχανικού.
                Το τελικό κόστος αφορά σε έργα που εκτελούνται εντός της ως άνω
                αναγραφόμενης απόστασης."
            />
          }
        </div>
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setRegionAction: (value) => dispatch(setRegionAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Region);
