import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleToggle from '../../simple-components/SimpleToggle';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

class RadicalWiringRehabilitation extends Component {
  onRadicalWiringRehabilitationChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.radicalWiringRehabilitation = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;
    const needNew = houseNeeds.value === 'needNew';
    const segmentClass = `sub-segment ${needNew ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Ριζική αντικατάσταση καλωδιώσεων'
        />
          <SimpleToggle
            label='Ριζική αντικατάσταση'
            onChange={ (value) => { this.onRadicalWiringRehabilitationChange(value); }}
            value={ houseNeeds.radicalWiringRehabilitation }
          />

          { !houseNeeds.radicalWiringRehabilitation &&
            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Θα γίνει έλεγχος στους διακλαδωτές και στον υφιστάμενο
              πίνακα χωρίς κάποια οικονομική επιβάρυνση."
            />
          }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(RadicalWiringRehabilitation);
