export const smallScaleLicenseCalc = (state) => {
  const { smallScaleLicense, smallScaleLicenseCost } = state.smallScaleLicense;

  const licenseCost = smallScaleLicense.value ? smallScaleLicenseCost.cost : 0;

  const cost = {
    smallScaleLicense: {
      display: licenseCost > 0,
      text: 'Κόστος έκδοσης άδειας μικρής κλίμακας',
      cost: licenseCost > 0 ? licenseCost : 0,
    }
  };

  const all = cost.smallScaleLicense.cost;
  const title = 'Έκδοση άδειας μικρής κλίμακας';
  const sumTitle = 'Κόστος έκδοσης άδειας μικρής κλίμακας';

  return { title, sumTitle, cost, all };
};
