export const plumbing = {
  kitchen: {
    newKitchenCost: 150,
  },
  solarHeater: {
    installationBaseCost: 150,
    installationCostPerFloor: 30, // From preliminary questions
  },
  balconyFaucet: {
    installationCostPerFaucet: 50,
  },
  washingMachine: {
    outOfBathCostPerMachine: 50,
  },
};
