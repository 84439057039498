import React from 'react';
import { Segment } from 'semantic-ui-react'

const SimpleSegment = ( { children, bgColor, color, className } ) => (
  <Segment style={{ background: bgColor }} color={ color } className={className}>
    { children }
  </Segment>
)

export default SimpleSegment;
