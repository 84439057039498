import { CONSTANT_VALUES } from '../constants';

const { projectInventory } = CONSTANT_VALUES;

export const defaultProjectInventory = {
  projectInventory: {
    value: false,
  },
  projectInventoryCost: projectInventory,
};
