class Masonry {
  constructor(surface, type) {
    this.surface = surface;

    if (
      type === 'plasterboard1-1' ||
      type === 'plasterboard2-2' ||
      type === 'ytong'
    ) {
      this.type = type; // Could be plasterboard1-1, plasterboard2-2, ytong
    } else {
      this.type = 'plasterboard1-1';
    }
  }
}

export default Masonry;
