import { dismantlingCalc } from './dismantling-calc';

export const coatingsCalc = (state) => {
  const dismantlingCalcResult = dismantlingCalc(state);

  const { rooms, rubbleRemoval } = state.preliminaryQuestions;
  const { wall } = state.dismantling;
  const { houseNeeds } = state.electrical;
  const { newExternal, newInternal } = state.masonry;
  const { coatingsCost } = state.coatings;
  
  const bedroomNumber = Number(rooms.bedroom);
  const isElevator = rubbleRemoval === 'elevator';
  const isMechanicalElevator = rubbleRemoval === 'mechanical-elevator';

  const wallDismantlingNumber = wall.value ? wall.number : 0;
  const houseNewNeeds = houseNeeds.value === 'needNew';
  const newExternalSurface = newExternal.value ? newExternal.surface : 0;
  const newInternalSurface = newInternal.value && newInternal.type === 'ytong'
    ? newInternal.surface : 0;

  const externalMasonryCoatingsCost =
    newExternalSurface * coatingsCost.externalMasonryCoatingsCostPerM2;

  const internalMasonryCoatingsCost =
    newInternalSurface * coatingsCost.internalMasonryYTONGCoatingsCostPerM2;

  const wallDismantlingCoatingsCost =
    wallDismantlingNumber * coatingsCost.wallDismantlingCoatingsCostPerWall;

  let electricalWorksCoatingsCost = 0;
  if (houseNewNeeds) {
    if (bedroomNumber === 1) {
      electricalWorksCoatingsCost = coatingsCost.electricalWorksNewNeedsCoatingsCostForOneBedroom;
    } else if (bedroomNumber === 2) {
      electricalWorksCoatingsCost = coatingsCost.electricalWorksNewNeedsCoatingsCostForTwoBedrooms;
    } else if (bedroomNumber > 2) {
      electricalWorksCoatingsCost = coatingsCost.electricalWorksNewNeedsCoatingsCostForThreeOrMoreBedroom;
    }
  }

  let rubbleRemovalCoatingsCost = 0;
  if (isElevator && dismantlingCalcResult.all > 0) {
    rubbleRemovalCoatingsCost = coatingsCost.rubbleRemovalElevatorCoatingsCost;
  } else if (isMechanicalElevator && dismantlingCalcResult.all > 100) {
    rubbleRemovalCoatingsCost = coatingsCost.rubbleRemovalMechanicalElevatorCoatingsCost;
  }

  const removalMachine = rubbleRemovalCoatingsCost < 0 ? 'μηχανικού μέσου (ανεβατορίου)' : 'ανελκυστήρα';

  const cost = {
    externalMasonryCoatingsCost: {
      display: externalMasonryCoatingsCost > 0,
      text: 'Κόστος επιχρισμάτων λόγω ανάγκης ανέγερσης νέας εξωτερικής τοιχοποιίας',
      cost: externalMasonryCoatingsCost> 0 ? externalMasonryCoatingsCost : 0,
    },
    internalMasonryCoatingsCost: {
      display: internalMasonryCoatingsCost > 0,
      text: 'Κόστος επιχρισμάτων λόγω ανάγκης ανέγερσης νέας εσωτερικής τοιχοποιίας με YTONG',
      cost: internalMasonryCoatingsCost > 0 ? internalMasonryCoatingsCost : 0,
    },
    wallDismantlingCoatingsCost: {
      display: wallDismantlingCoatingsCost > 0,
      text: 'Κόστος επιχρισμάτων λόγω καθαίρεσης τοίχων',
      cost: wallDismantlingCoatingsCost > 0 ? wallDismantlingCoatingsCost : 0,
    },
    electricalWorksCoatingsCost: {
      display: electricalWorksCoatingsCost > 0,
      text: 'Κόστος επιχρισμάτων λόγω νέων ηλεκτρολογικών εργασιών',
      cost: electricalWorksCoatingsCost > 0 ? electricalWorksCoatingsCost : 0,
    },
    rubbleRemovalCoatingsCost: {
      display: rubbleRemovalCoatingsCost > 0,
      text: `Κόστος επιχρισμάτων λόγω απομάκρυνσης μπάζων με χρήση ${removalMachine}`,
      cost: rubbleRemovalCoatingsCost !== 0 ? rubbleRemovalCoatingsCost : 0,
    },
  };

  const all = cost.externalMasonryCoatingsCost.cost
    + cost.internalMasonryCoatingsCost.cost
    + cost.wallDismantlingCoatingsCost.cost
    + cost.electricalWorksCoatingsCost.cost
    + cost.rubbleRemovalCoatingsCost.cost;

  const title = 'Επιχρίσματα';
  const sumTitle = 'Σύνολο κόστους επιχρισμάτων'; 
  
  return { title, sumTitle, cost, all };
};