export const SET_NEW_EXTERNAL_MASONRY  = 'SET_NEW_EXTERNAL_MASONRY';
export const SET_NEW_INTERNAL_MASONRY  = 'SET_NEW_INTERNAL_MASONRY';

export const setNewExternalMasonryAction = (newExternal) =>
  dispatch => {
    dispatch({
      type: SET_NEW_EXTERNAL_MASONRY,
      payload: newExternal,
    });
  };

export const setNewInternalMasonryAction = (newInternal) =>
  dispatch => {
    dispatch({
      type: SET_NEW_INTERNAL_MASONRY,
      payload: newInternal,
    });
  };
