import { CONSTANT_VALUES } from '../constants';

const { kitchen } = CONSTANT_VALUES;

export const defaultKitchen = {
  kitchen: {
    value: false,
    length: 0,
    doorMaterial: 'veneer', // Could be veneer, melamine, acrylic, lacquered
  },
  kitchenCost: kitchen,
};
