import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_EXTERNAL_FRAMES, SET_INTERNAL_DOORS_FRAMES,
  SET_INTERNAL_DOORS_PAINTING_FRAMES, SET_ARMORED_DOORS_FRAMES,
} from '../actions/action-frames';

const {
  newExternalFrames, internalDoors, internalDoorsPainting,
  armoredDoors, framesCost,
} = defaultValues.frames;

export default (state = {
  newExternalFrames, internalDoors, internalDoorsPainting,
  armoredDoors, framesCost,
}, action) => {
  switch (action.type) {
    case SET_EXTERNAL_FRAMES:
      return copyObject(state, {
        newExternalFrames: action.payload,
      });
    case SET_INTERNAL_DOORS_FRAMES:
      return copyObject(state, {
        internalDoors: action.payload,
      });
    case SET_INTERNAL_DOORS_PAINTING_FRAMES:
      return copyObject(state, {
        internalDoorsPainting: action.payload,
      });
    case SET_ARMORED_DOORS_FRAMES:
      return copyObject(state, {
        armoredDoors: action.payload,
      });
    default:
      return state;
  }
}
 