import React from 'react';
import { connect } from 'react-redux';
import { setLoftDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class LoftDismantling extends React.Component {
  onLoftDismantlingChange(value) {
    const { loft } = this.props.dismantling;
    loft.value = value;

    this.props.setLoftDismantlingAction(loft);
  }

  onLoftNumberChange(event) {
    const { value } = event.target;
    const { loft } = this.props.dismantling;
    loft.number = value;

    this.props.setLoftDismantlingAction(loft);
  }

  render() {
    const { loft } = this.props.dismantling;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση Παταριού'
        />

        <SimpleToggle
          label='Αποξήλωση Παταριού'
          onChange={ (value) => { this.onLoftDismantlingChange(value); }}
          value={ loft.value }
        />

        { loft.value &&
          <SimpleInput
            label='Αριθμός παταριών'
            placeholder='Αριθμός παταριών'
            value={ loft.number }
            onChange={ (e) => { this.onLoftNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setLoftDismantlingAction: (value) => dispatch(setLoftDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoftDismantling);
