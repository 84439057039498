import React from 'react';
import { Label, Dropdown } from 'semantic-ui-react';

const SimpleMultiSelect = ( { label, options, placeholder, onChange, display, values } ) => {
  const displayInput = display || true;

  return (
    <div className="multi-select" style={{ padding: '5px', display: displayInput ? 'block' : 'none' }}>
      <Label pointing='right'> { label } </Label>
      <Dropdown
        clearable
        multiple
        search
        selection
        value={ values }
        onChange={ (_, { value }) => { onChange(value) }}
        options={ options }
        placeholder={ placeholder }
      />
    </div>
  );
}

export default SimpleMultiSelect;

  
  