import { combineReducers } from 'redux';
import app from './reducer-app';
import preliminaryQuestions from './reducer-preliminary-questions';
import dismantling from './reducer-dismantling';
import plumbing from './reducer-plumbing';
import heating from './reducer-heating';
import cooling from './reducer-cooling';
import electrical from './reducer-electrical';
import masonry from './reducer-masonry';
import plasterboard from './reducer-plasterboard';
import insulation from './reducer-insulation';
import bathroomWc from './reducer-bathroom-wc';
import plaster from './reducer-plaster';
import floors from './reducer-floors';
import wallCladding from './reducer-wall-cladding';
import frames from './reducer-frames';
import paints from './reducer-paints';
import kitchen from './reducer-kitchen';
import wardrobes from './reducer-wardrobes';
import coatings from './reducer-coatings';
import projectDesignAndManagement from './reducer-project-design-and-management';
import projectInventory from './reducer-project-inventory';
import smallScaleLicense from './reducer-small-scale-license';
import buildingPermit from './reducer-building-permit';
import customFields from './reducer-custom-fields';

export default combineReducers({
  app,
  preliminaryQuestions,
  dismantling,
  plumbing,
  heating,
  cooling,
  electrical,
  masonry,
  plasterboard,
  insulation,
  bathroomWc,
  plaster,
  floors,
  wallCladding,
  frames,
  paints,
  kitchen,
  wardrobes,
  coatings,
  projectDesignAndManagement,
  projectInventory,
  smallScaleLicense,
  buildingPermit,
  customFields,
});
