import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setRailingPaintPaintsAction } from '../../../redux/actions/action-paints';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleHeader from '../../simple-components/SimpleHeader';

class RailingPaint extends Component {
  onRailingPaintChange(value) {
    const { railingPaint } = this.props.paints;
    railingPaint.value = value;

    this.props.setRailingPaintPaintsAction(railingPaint);
  }

  onRailingPaintLengthChange(event) {
    const { railingPaint } = this.props.paints;
    railingPaint.length = event.target.value;

    this.props.setRailingPaintPaintsAction(railingPaint);
  }

  render() {
    const { railingPaint } = this.props.paints;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Βαφή κιγκλιδωμάτων'
        />

        <SimpleToggle
          label='Βαφή κιγκλιδωμάτων'
          onChange={ (value) => { this.onRailingPaintChange(value); }}
          value={ railingPaint.value }
        />

        { railingPaint.value &&
          <SimpleInput
            label='Μήκος κιγκλιδωμάτων'
            placeholder='Μήκος κιγκλιδωμάτων'
            value={ railingPaint.length }
            onChange={ (e) => { this.onRailingPaintLengthChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setRailingPaintPaintsAction: (value) => dispatch(setRailingPaintPaintsAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(RailingPaint);
