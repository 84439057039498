import React from 'react'

import OutdoorTile from './OutdoorTile';
import IndoorFloor from './IndoorFloor';
import MarbleAndMosaicFloorRubbing from './MarbleAndMosaicFloorRubbing';
import WoodenFloorRubbing from './WoodenFloorRubbing';
import MarbleFeet from './MarbleFeet';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Floors = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section floors ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Δάπεδα'
          headerClick={ () => { headerClick(); } }
        />

        <OutdoorTile />
        <IndoorFloor />
        <MarbleAndMosaicFloorRubbing />
        <WoodenFloorRubbing />
        <MarbleFeet />
      </SimpleSegment>
    </div>
  );
};

export default Floors;
