import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setMarbleFeetFloorAction } from '../../../redux/actions/action-floors';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const marbleFeetTypeOptions = [
  { text: 'Τρίψιμο λευκόφοντου μαρμάρου', value: 'rubbing' },
  { text: 'Νέα τοποθέτηση λευκόφοντου μαρμάρου', value: 'new-marble' },
];

class MarbleFeet extends Component {
  onMarbleFeetChange(value) {
    const { marbleFeet } = this.props.floors;
    marbleFeet.value = value;

    this.props.setMarbleFeetFloorAction(marbleFeet);
  }

  onMarbleFeetSurfaceChange(event) {
    const { marbleFeet } = this.props.floors;
    marbleFeet.surface = event.target.value;

    this.props.setMarbleFeetFloorAction(marbleFeet);
  }

  onMarbleFeetTypeChange(value) {
    const { marbleFeet } = this.props.floors;
    marbleFeet.type = value;

    this.props.setMarbleFeetFloorAction(marbleFeet);
  }

  render() {
    const { marbleFeet } = this.props.floors;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Μαρμαροποδιές'
        />

        <SimpleToggle
          label='Μαρμαροποδιές'
          onChange={ (value) => { this.onMarbleFeetChange(value); }}
          value={ marbleFeet.value }
        />

        { marbleFeet.value &&
          <Fragment>
            <SimpleInput
              label='Μήκος (σε τρ.μ.)'
              placeholder='Μήκος (σε τρ.μ.)'
              value={ marbleFeet.surface }
              onChange={ (e) => { this.onMarbleFeetSurfaceChange(e) } }
              type="number"
            />

            <SimpleSelect
              label="Τύπος εργασίας"
              placeholder="Τύπος εργασίας"
              options={ marbleFeetTypeOptions }
              value={ marbleFeet.type }
              onChange={ (value) => { this.onMarbleFeetTypeChange(value) } }
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setMarbleFeetFloorAction: (value) => dispatch(setMarbleFeetFloorAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(MarbleFeet);
