export const masonry = {
  newExternal: {
    costPerM2: 25
  },
  newInternal: {
    costPlasterboard11PerM2: 20,
    costPlasterboard22PerM2: 28,
    ytongSurfaceThreshold: 15,
    costYTONGLessThanOrEqualSurfaceThresholdPerM2: 30,
    costYTONGMoreThanSurfaceThresholdPerM2: 22,
  },
};
