import React from 'react'

import CurrentHeatingSource from './CurrentHeatingSource';
import NewHeatingSource from './NewHeatingSource';
import NewHeatingNetwork from './NewHeatingNetwork';
import HeatersDismantling from './HeatersDismantling';
import MovingHeaters from './MovingHeaters';
import SupplyNewHeaterPanels from './SupplyNewHeaterPanels';
import GasBoilerSupply from './GasBoilerSupply';
import OilBoilerSupply from './OilBoilerSupply';
import AirCondition from './AirCondition';
import Fireplace from './Fireplace';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Heating = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section heating ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Εργασίες Θέρμανσης'
          headerClick={ () => { headerClick(); } }
        />

        <CurrentHeatingSource />
        <NewHeatingSource />
        <NewHeatingNetwork />
        <HeatersDismantling />
        <MovingHeaters />
        <SupplyNewHeaterPanels />
        <GasBoilerSupply />
        <OilBoilerSupply />
        <AirCondition />
        <Fireplace />
      </SimpleSegment>
    </div>
  );
};

export default Heating;
