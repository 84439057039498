export const electricalCalc = (state) => {
  const { surface, rooms } = state.preliminaryQuestions;
  const { newAC } = state.cooling;
  const { kitchen } = state.plumbing;
  const { newSource, heatingCostConstants } = state.heating;

  const { houseNeeds } = state.electrical;
  const {
    radicalWiringRehabilitation, electricalPanel,
    patientNetworks, strongNetworks, electricalNetworkExtension, magneticContacts,
    securitySystems, socketInstallationType, newLamps, newLampsWiring,
    newElectricalDevices, electricalWorksCost,
  } = houseNeeds;

  const {
    kitchenSpotNumber,
    livingRoomSpotNumber,
    bedroomSpotNumber,
    kitchenLivingRoomSpotNumber,
    bathSpotNumber,
    wcSpotNumber,
    warehouseSpotNumber,
    balconySpotNumber,
    costPerSpot,
  } = electricalWorksCost.newLampsWiring;

  const socketSupplyCost = socketInstallationType === 'supply-install'
    ? electricalWorksCost.socketSupplyPerM2 * surface : 0;

  const socketInstallationCost = socketInstallationType === 'supply-install'
    || socketInstallationType === 'install'
    ? electricalWorksCost.socketInstallationPerM2 * surface : 0;

  const newLampsCost = newLamps
    ? electricalWorksCost.newLampsPerM2 * surface : 0;

  const kitchenSpots = kitchenSpotNumber * rooms.kitchen;
  const livingRoomSpots = livingRoomSpotNumber * rooms.livingRoom;
  const bedroomSpots = bedroomSpotNumber * rooms.bedroom;
  const kitchenLivingRoomSpots = kitchenLivingRoomSpotNumber * rooms.kitchenLivingRoom;
  const bathSpots = bathSpotNumber * rooms.bathroom;
  const wcSpots = wcSpotNumber * rooms.wc;
  const warehouseSpots = warehouseSpotNumber * rooms.warehouse;
  const balconySpots = balconySpotNumber * rooms.balcony;

  const spots = kitchenSpots + livingRoomSpots + bedroomSpots
    + kitchenLivingRoomSpots + bathSpots + wcSpots + warehouseSpots
    + balconySpots;

  const newLampsWiringCost = newLampsWiring
    ? costPerSpot * spots : 0;

  // Only radical
  const radical = houseNeeds.value === 'needNew' && radicalWiringRehabilitation;
  const newPanelCost = radical && electricalPanel.newPanel
    ? electricalWorksCost.newPanel : 0;

  const newPanelMovingCost = radical && electricalPanel.newPanel && electricalPanel.newPanelMoving
    ? electricalWorksCost.newPanelMoving : 0;

  const patientNetworksCost = radical && patientNetworks.value
    ? electricalWorksCost.patientNetworksPerSocket * patientNetworks.socketNumber
    : 0;

  const strongNetworksCost = radical && strongNetworks.value
    ? electricalWorksCost.strongNetworksPerSocket * strongNetworks.socketNumber
    : 0;
  
  const electricalNetworkExtensionCost = radical && strongNetworks.value && electricalNetworkExtension.value
    ? electricalWorksCost.electricalNetworkExtensionPerM2 * electricalNetworkExtension.surface
    : 0;

  const electricalWorksACCost = newAC.value
    ? electricalWorksCost.electricalWorks.newACperAC * newAC.number
    : 0;

  const electricalWorksKitchenMovingCost = kitchen.move
    ? electricalWorksCost.electricalWorks.forKitchenMoving
    : 0;

  const electricalWorksNewOilCost = newSource.value === 'oil'
    ? electricalWorksCost.electricalWorks.newOilNetwork
    : 0;

  const electricalWorksNewGasCost = newSource.value === 'gas'
    ? electricalWorksCost.electricalWorks.newGasNetwork
    : 0;

  const isConvector = newSource.value === 'convector' && newSource.srcNumber.convector > 0;
  const convectorCost = isConvector
    ? newSource.srcNumber.convector * heatingCostConstants.convectorPerHeater
    : 0
  
  let magneticContactsPreinstallationCost = 0;
  let securitySystemsInstallationCost = 0;
  if (radical && magneticContacts.value) {
    magneticContactsPreinstallationCost = electricalWorksCost.magneticContacts.preinstallation.forOneRoom;
    
    if (rooms.bedroom > 1) {
      const roomNumber = rooms.bedroom - 1;
      const { forMoreThanOneRoomPerOtherRoom } = electricalWorksCost.magneticContacts.preinstallation;
      magneticContactsPreinstallationCost += forMoreThanOneRoomPerOtherRoom * roomNumber;
    }
  }

  if (radical && securitySystems.value) {
    securitySystemsInstallationCost = electricalWorksCost.securitySystems.installation;
  }

  const newElectricalDevicesCost = radical && newElectricalDevices
    ? electricalWorksCost.newElectricalDevices : 0;

  const cost = {
    socketSupplyCost: {
      display: socketSupplyCost > 0,
      text: 'Κόστος προμήθειας νέου πριζοδιακοπτικού υλικού',
      cost: socketSupplyCost > 0 ? socketSupplyCost : 0,
    },
    socketInstallationCost: {
      display: socketInstallationCost > 0,
      text: 'Κόστος τοποθέτησης πριζοδιακοπτικού υλικού',
      cost: socketInstallationCost > 0 ? socketInstallationCost : 0,
    },
    newLampsCost: {
      display: newLampsCost > 0,
      text: 'Κόστος τοποθέτησης φωτιστικών σωμάτων',
      cost: newLampsCost > 0 ? newLampsCost : 0,
    },
    newLampsWiringCost: {
      display: newLampsWiringCost > 0,
      text: 'Κόστος καλωδίωσης φωτιστικών σωμάτων',
      cost: newLampsWiringCost > 0 ? newLampsWiringCost : 0,
    },
    newPanelCost: {
      display: newPanelCost > 0,
      text: 'Κόστος νέου πίνακα',
      cost: newPanelCost > 0 ? newPanelCost : 0,
    },
    newPanelMovingCost: {
      display: newPanelMovingCost > 0,
      text: 'Κόστος μεταφοράς νέου πίνακα',
      cost: newPanelMovingCost > 0 ? newPanelMovingCost : 0,
    },
    patientNetworksCost: {
      display: patientNetworksCost > 0,
      text: 'Κόστος παροχών ασθενών δικτύων',
      cost: patientNetworksCost > 0 ? patientNetworksCost : 0,
    },
    strongNetworksCost: {
      display: strongNetworksCost > 0,
      text: 'Κόστος παροχών ισχυρών δικτύων',
      cost: strongNetworksCost > 0 ? strongNetworksCost : 0,
    },
    electricalNetworkExtensionCost: {
      display: electricalNetworkExtensionCost > 0,
      text: 'Κόστος επέκτασης ηλεκτρολογικής εγκατάστασης',
      cost: electricalNetworkExtensionCost > 0 ? electricalNetworkExtensionCost : 0,
    },
    electricalWorksACCost: {
      display: electricalWorksACCost > 0,
      text: 'Κόστος παροχών AC',
      cost: electricalWorksACCost > 0 ? electricalWorksACCost : 0,
    },
    electricalWorksKitchenMovingCost: {
      display: electricalWorksKitchenMovingCost > 0,
      text: 'Κόστος ηλεκτρολογικών εργασιών μεταφοράς της κουζίνας',
      cost: electricalWorksKitchenMovingCost > 0 ? electricalWorksKitchenMovingCost : 0,
    },
    electricalWorksNewOilCost: {
      display: electricalWorksNewOilCost > 0,
      text: 'Κόστος ηλεκτρολογικών εργασιών παροχής λέβητα πετρελαίου',
      cost: electricalWorksNewOilCost > 0 ? electricalWorksNewOilCost : 0,
    },
    electricalWorksNewGasCost: {
      display: electricalWorksNewGasCost > 0,
      text: 'Κόστος ηλεκτρολογικών εργασιών παροχής λέβητα φυσικού αερίου',
      cost: electricalWorksNewGasCost > 0 ? electricalWorksNewGasCost : 0,
    },
    convectorCost: {
      display: convectorCost > 0,
      text: `Κόστος ηλεκτρολογικών εργασιών εγκατάστασης θερμοπομπών (${ newSource.srcNumber.convector } σώματα)`,
      cost: convectorCost > 0 ? convectorCost : 0,
    },
  magneticContactsPreinstallationCost: {
      display: magneticContactsPreinstallationCost > 0,
      text: 'Κόστος προεγκατάστασης μαγνητικών επαφών / ραντάρ / καμερών',
      cost: magneticContactsPreinstallationCost > 0
        ? magneticContactsPreinstallationCost : 0,
    },
    securitySystemsInstallationCost: {
      display: securitySystemsInstallationCost > 0,
      text: 'Κόστος εγκατάστασης συστημάτων ασφαλείας',
      cost: securitySystemsInstallationCost > 0
        ? securitySystemsInstallationCost : 0,
    },
    newElectricalDevicesCost: {
      display: newElectricalDevicesCost > 0,
      text: 'Κόστος εγκατάστασης ηλεκτρικών συσκευών',
      cost: newElectricalDevicesCost > 0 ? newElectricalDevicesCost : 0,
    },
  };

  const all = cost.socketSupplyCost.cost + cost.socketInstallationCost.cost
    + cost.newLampsCost.cost + cost.newLampsWiringCost.cost + cost.newPanelCost.cost
    + cost.newPanelMovingCost.cost + cost.patientNetworksCost.cost
    + cost.strongNetworksCost.cost
    + cost.electricalNetworkExtensionCost.cost + cost.electricalWorksACCost.cost
    + cost.electricalWorksKitchenMovingCost.cost + cost.electricalWorksNewOilCost.cost
    + cost.electricalWorksNewGasCost.cost + cost.convectorCost.cost
    + cost.magneticContactsPreinstallationCost.cost
    + cost.securitySystemsInstallationCost.cost
    + cost.newElectricalDevicesCost.cost;

  const title = 'Ηλεκτρολογικές εργασίες';
  const sumTitle = 'Σύνολο ηλεκτρολογικών εργασιών';

  return { title, sumTitle, cost, all };
};
