import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  setWcPlumbingAction, setBathWcCoatingsAction,
} from '../../../redux/actions/action-bathroom-wc';
import { setCoatings } from '../../../helpers/utils';

import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleInput from '../../simple-components/SimpleInput';
import RoomDimensions from '../../../helpers/room-dimensions';

class WcInstallation extends React.Component {
  updateBathWcCoatings() {
    const { bath, wc, bathWcCoatings } = this.props.bathroomWc;
    const { coatings, dimensions } = setCoatings(bath, wc, bathWcCoatings);

    bathWcCoatings.coatings = coatings;
    bathWcCoatings.dimensions = dimensions;
    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onNewWcPlumbingChange(value) {
    const { wc } = this.props.bathroomWc;
    wc.newWc = value;

    this.updateBathWcCoatings();
    this.props.setWcPlumbingAction(wc);
  }

  onNewWcNumberChange(event) {
    const { wc } = this.props.bathroomWc;
    const currentNumber = Number(wc.newWcNumber);
    const newNumber = Number(event.target.value);

    if (newNumber > currentNumber) {
      const diff = newNumber - currentNumber;
      for (let i = 0; i < diff; i++) {
        wc.newWcDimensions.push(new RoomDimensions(0, 0, 0));
      }
    } else {
      const diff = currentNumber - newNumber;
      for (let i = 0; i < diff; i++) {
        wc.newWcDimensions.pop();
      }
    }

    wc.newWcNumber = newNumber;

    this.updateBathWcCoatings();
    this.props.setWcPlumbingAction(wc);
  }

  onWcWorkPlumbingChange(value) {
    const { wc } = this.props.bathroomWc;
    wc.changeWc = value;

    this.props.setWcPlumbingAction(wc);
  }

  onWcDimensionsChange(value, idx, param) {
    const { wc } = this.props.bathroomWc;

    if (wc.newWcDimensions.length > idx) {
      wc.newWcDimensions[idx][param] = Number(value);
      this.props.setWcPlumbingAction(wc);
    }
  }

  render() {
    const { wc } = this.props.bathroomWc;

    const wcDimensionsItems = [];
    wc.newWcDimensions.forEach((wcDimensions, idx) => {
      wcDimensionsItems.push(
        <Fragment key={idx}>
          <h3> WC {idx + 1} </h3>
          <SimpleInput
            label='Μήκος (σε cm)'
            placeholder='Μήκος (σε cm)'
            value={ wcDimensions.length }
            onChange={ (e) => { this.onWcDimensionsChange(e.target.value, idx, 'length') } }
            type="number"
          />

          <SimpleInput
            label='Πλάτος (σε cm)'
            placeholder='Πλάτος (σε cm)'
            value={ wcDimensions.width }
            onChange={ (e) => { this.onWcDimensionsChange(e.target.value, idx, 'width') } }
            type="number"
          />

          <SimpleInput
            label='Υψος (σε cm)'
            placeholder='Υψος (σε cm)'
            value={ wcDimensions.height }
            onChange={ (e) => { this.onWcDimensionsChange(e.target.value, idx, 'height') } }
            type="number"
          />
        </Fragment>
      );
    });

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Υδραυλική εγκατάσταση WC'
        />
        
        <SimpleToggle
          label='Νέα εγκατάσταση WC'
          onChange={ (value) => { this.onNewWcPlumbingChange(value); }}
          value={ wc.newWc }
        />

        { wc.newWc &&
          <Fragment>
            <SimpleInput
              label='Αριθμός wc'
              placeholder='Αριθμός wc'
              value={ wc.newWcNumber }
              onChange={ (e) => { this.onNewWcNumberChange(e) } }
              type="number"
            />

            { wcDimensionsItems }
          </Fragment>
        }

        { !wc.newWc &&
          <SimpleToggle
            label='Μόνον αντικατάσταση είδών υγιεινής'
            onChange={ (value) => { this.onWcWorkPlumbingChange(value); }}
            value={ wc.changeWc }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWcPlumbingAction: (value) => dispatch(setWcPlumbingAction(value)),
  setBathWcCoatings: (value) => dispatch(setBathWcCoatingsAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WcInstallation);
