import { CONSTANT_VALUES } from '../constants';

const { insulation } = CONSTANT_VALUES;

export const defaultInsulation = {
  wallInsulation: {
    value: false,
    sameInsulationType: true,
    internalSurface: 0,
    externalSurface: 0,
    types: [], // Could be internal and / or external
    surface: 0,
    type: 'external', // Could be external or internal
  },
  waterproofing: {
    value: false,
    type: 'cementitious', // Could be cementitious or asphalt or epdm 
  },
  thermalInsulation: {
    value: false,
  },
  internalFloorInsulation: {
    value: false,
  },
  insulationCost: insulation,
};
