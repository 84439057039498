import { CONSTANT_VALUES } from '../constants';

const { heating } = CONSTANT_VALUES;

export const defaultHeating = {
  currentSource: {
    value: 'nothing', // Could be nothing, oil, gas, power
  },
  newSource: {
    value: 'nothing', // Could be nothing, oil, gas, ac, convector
    newNetwork: {
      value: false,
      type: 'inFloor', // Could be inFloor or outOfFloor
    },
    srcNumber: {
      oil: 0,
      gas: 0,
      ac: 0,
      convector: 0,
    },
  },
  heatersDismantling: {
    value: false,
  },
  heatersMoving: {
    value: false,
    number: 0,
    type: 'inFloor', // Could be inFloor or outOfFloor
  },
  newHeaters: {
    value: false,
    number: 0,
  },
  newBoiler: {
    gas: false,
    oil: false,
  },
  newFireplace: {
    value: false,
    type: 'simple', // Could be simple or energy
  },
  heatingCostConstants: heating,
};
