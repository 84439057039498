import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setRoomsAction } from '../../../redux/actions/action-preliminary-questions';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleTextArea from '../../simple-components/SimpleTextArea';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class Rooms extends Component {
  onRoomChange(event, room) {
    const { value } = event.target;
    const rooms = this.props.preliminaryQuestions.rooms;
    rooms[room] = value;

    this.props.setRoomsAction(rooms);
  }

  render() {
    const { rooms } = this.props.preliminaryQuestions;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">

        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αριθμός δωματίων κατοικίας'
        />

        <SimpleInput 
          label="Κουζίνες"
          placeholder="Αριθμός κουζινών"
          value={ rooms.kitchen }
          onChange={ (e) => { this.onRoomChange(e, 'kitchen') } }
          type="number"
        />

        <SimpleInput 
          label="Σαλόνια"
          placeholder="Αριθμός σαλονιών"
          value={ rooms.livingRoom }
          onChange={ (e) => { this.onRoomChange(e, 'livingRoom') } }
          type="number"
        />

        <SimpleInput 
          label="Υπνοδωμάτια"
          placeholder="Αριθμός Υπνοδωμάτιων"
          value={ rooms.bedroom }
          onChange={ (e) => { this.onRoomChange(e, 'bedroom') } }
          type="number"
        />

        <SimpleInput 
          label="Σαλονοκουζίνες"
          placeholder="Αριθμός σαλονοκουζίνων"
          value={ rooms.kitchenLivingRoom }
          onChange={ (e) => { this.onRoomChange(e, 'kitchenLivingRoom') } }
          type="number"
        />

        <SimpleInput 
          label="Μπάνια"
          placeholder="Αριθμός μπάνιων"
          value={ rooms.bathroom }
          onChange={ (e) => { this.onRoomChange(e, 'bathroom') } }
          type="number"
        />

        <SimpleInput 
          label="WC"
          placeholder="Αριθμός WC"
          value={ rooms.wc }
          onChange={ (e) => { this.onRoomChange(e, 'wc') } }
          type="number"
        />

        <SimpleInput 
          label="Αποθήκες"
          placeholder="Αριθμός Αποθήκών"
          value={ rooms.warehouse }
          onChange={ (e) => { this.onRoomChange(e, 'warehouse') } }
          type="number"
        />

        <SimpleInput 
          label="Μπαλκόνια"
          placeholder="Αριθμός Μπαλκονιών"
          value={ rooms.balcony }
          onChange={ (e) => { this.onRoomChange(e, 'balcony') } }
          type="number"
        />

        <SimpleTextArea
          label=''
          placeholder="Λοιπές Πληροφορίες"
          value={ rooms.info }
          onChange={ (e) => { this.onRoomChange(e, 'info') } }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setRoomsAction: (room, value) => dispatch(setRoomsAction(room, value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Rooms);
