export const coolingCalc = (state) => {
  const { newSource } = state.heating;
  const { newAC, coolingCost } = state.cooling;
  const { value, number, type } = newAC;

  const isAC = newSource.value === 'ac' || value;

  let costPerAC = 0;
  if (type === 'install') {
    costPerAC = coolingCost.newAC.acInstallPerAC;
  } else if (type === 'preinstall') {
    costPerAC = coolingCost.newAC.acPreinstallPerAC;
  } else if (type === 'preinstall-install') {
    costPerAC = coolingCost.newAC.acInstallAndPreinstallPerAC;
  } else if (type === 'maintenance') {
    costPerAC = coolingCost.newAC.acMaintenancePerAC;
  }

  const newACCost = isAC ? number * costPerAC : 0;

  const cost = {
    newACCost: {
      display: newACCost > 0,
      text: `Κόστος κλιματιστικών μονάδων (${ number } μονάδες)`,
      cost: newACCost > 0 ? newACCost : 0,
    },
  };

  const all = cost.newACCost.cost;

  const title = 'Εργασίες ψύξης';
  const sumTitle = 'Σύνολο εργασιών ψύξης';

  return { title, sumTitle, cost, all };
};
