import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Frame from '../../../helpers/frame';
import { setExternalFramesAction } from '../../../redux/actions/action-frames';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

const frameColorOptions = [
  { text: 'Λευκό', value: 'white' },
  { text: 'Χρώμα ral', value: 'ral' },
  { text: 'Απομίμηση ξύλου', value: 'efecto-madera' },
];

const frameMaterialOptions = [
  { text: 'Pvc', value: 'pvc' },
  { text: 'Αλουμίνιο', value: 'aluminium' },
];

class NewExternalFrames extends Component {
  onExternalFramesChange(value) {
    const { newExternalFrames } = this.props.frames;
    newExternalFrames.value = value;

    this.props.setExternalFramesAction(newExternalFrames);
  }

  onExternalFramesNumberChange(event) {
    const { newExternalFrames } = this.props.frames;
    const currentNumber = Number(newExternalFrames.number);
    const newNumber = Number(event.target.value);

    if (newNumber > currentNumber) {
      const diff = newNumber - currentNumber;
      for (let i = 0; i < diff; i++) {
        newExternalFrames.frames.push(new Frame(0, 0, 'white', 'pvc'));
      }
    } else {
      const diff = currentNumber - newNumber;
      for (let i = 0; i < diff; i++) {
        newExternalFrames.frames.pop();
      }
    }

    newExternalFrames.number = newNumber;
    this.props.setExternalFramesAction(newExternalFrames);
  }

  onExternalFrameChange(value, idx, param) {
    const { newExternalFrames } = this.props.frames;

    if (newExternalFrames.frames.length > idx) {
      newExternalFrames.frames[idx][param] = value;
      this.props.setExternalFramesAction(newExternalFrames);
    }
  }

  render() {
    const { newExternalFrames } = this.props.frames;

    const frameItems = [];
    newExternalFrames.frames.forEach((frame, idx) => {
      frameItems.push(
        <Fragment key={idx}>
          <h3> Κούφωμα {idx + 1} </h3>
          <SimpleInput
            label='Πλάτος (σε cm)'
            placeholder='Πλάτος (σε cm)'
            value={ frame.width }
            onChange={ (e) => { this.onExternalFrameChange(e.target.value, idx, 'width') } }
            type="number"
          />

          <SimpleInput
            label='Υψος (σε cm)'
            placeholder='Υψος (σε cm)'
            value={ frame.height }
            onChange={ (e) => { this.onExternalFrameChange(e.target.value, idx, 'height') } }
            type="number"
          />

          <SimpleSelect
            label="Χρώμα"
            placeholder="Χρώμα"
            options={ frameColorOptions }
            value={ frame.color }
            onChange={ (value) => { this.onExternalFrameChange(value, idx, 'color') } }
          />

          <SimpleSelect
            label="Υλικό"
            placeholder="Υλικό"
            options={ frameMaterialOptions }
            value={ frame.material }
            onChange={ (value) => { this.onExternalFrameChange(value, idx, 'material') } }
          />
        </Fragment>
      );
    })

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Τοποθέτηση νέων εξωτερικών κουφωμάτων'
        />

        <SimpleToggle
          label='Τοποθέτηση κουφωμάτων'
          onChange={ (value) => { this.onExternalFramesChange(value); }}
          value={ newExternalFrames.value }
        />

        { newExternalFrames.value &&
          <Fragment>
            <SimpleInput
              label='Αριθμός κουφωμάτων'
              placeholder='Αριθμός κουφωμάτων'
              value={ newExternalFrames.number }
              onChange={ (e) => { this.onExternalFramesNumberChange(e) } }
              type="number"
            />

            { frameItems }

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Στην τιμή συμπεριλαμβάνεται ρολό με φυλαράκι αλουμινίου
              πολυουρεθάνης και νέο μαρμάρινο κατωκάσι. Έχει γίνει η παραδοχή,
              ότι οι διαστάσεις των ανοιγμάτων παραμένουν ίδιες."
            />

          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setExternalFramesAction: (value) => dispatch(setExternalFramesAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewExternalFrames);
