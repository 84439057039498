export const heating = {
  oil: {
    newNetwork: {
      inFloorPerHeater: 150,
      outOfFloorPerHeater: 220,
    },
    dismantling: 50,
    moving: {
      inFloorPerMovingHeater: 60,
      outOfFloorPerMovingHeater: 110,
    },
    newHeatersPerNumber: 80,
    newBoiler: 1600,
  },
  gas: {
    newNetwork: {
      inFloorPerHeater: 150,
      outOfFloorPerHeater: 220,
    },
    dismantling: 50,
    moving: {
      inFloorPerMovingHeater: 60,
      outOfFloorPerMovingHeater: 110,
    },
    newHeatersPerNumber: 80,
    newBoiler: 1000,
  },
  convectorPerHeater: 70,
  fireplace: {
    simple: 1000,
    energy: 2000,
  },
};
