import React, { Fragment } from 'react';
import { connect } from 'react-redux';

import { setBathWcCoatingsAction } from '../../../redux/actions/action-bathroom-wc';
import { setCoatings } from '../../../helpers/utils';

import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import { SEGMENT } from '../../../config/constants';

const investmentTypeOptions = [
  { text: 'Πλακίδια', value: 'tiles' },
  { text: 'Πατητή τσιμεντοκονία', value: 'forged-cement' },
];

const tilesInvestmentTypeOptions = [
  { text: 'Πλακίδια (10 euro/m2)', value: 'tile-cat1' },
  { text: 'Πλακίδια (20 euro/m2)', value: 'tile-cat2' },
  { text: 'Πλακίδια (30 euro/m2)', value: 'tile-cat3' },
];

const bathOptions = [
  { text: 'Κτιστή ντουζιέρα', value: 'built-in-shower' },
  { text: 'Πορσελάνινη ντουζιέρα', value: 'porcelain-shower' },
  { text: 'Μπανιέρα', value: 'bathhub' },
];

const washbasinOptions = [
  { text: 'Έπιπλο μπάνιου', value: 'bathroom-furniture' },
  { text: 'Κτιστός πάγκος με επικαθήμενο/ένθετο νιπτήτα', value: 'built-in-bench' },
  { text: 'Κολονάτος/κρεμαστός νιπτήρας', value: 'colonate-washbasin' },
];

const basinOptions = [
  { text: 'Επιδαπέδια λεκάνη', value: 'floor-basin' },
  { text: 'Κρεμαστή λεκάνη/επίτοιχο καζανάκι', value: 'hanging-basin' },
];

const batteriesTypeOptions = [
  { text: '', value: 'nothing' },
  { text: 'Απλό σετ μπαταριών', value: 'simple' },
  { text: 'Σετ επίτοιχων μπαταριών', value: 'wall-mounted' },
];

class BathWcCoatings extends React.Component {
  updateBathWcCoatings() {
    const { bath, wc, bathWcCoatings } = this.props.bathroomWc;
    const { coatings, dimensions } = setCoatings(bath, wc, bathWcCoatings);

    bathWcCoatings.coatings = coatings;
    bathWcCoatings.dimensions = dimensions;
    this.props.setBathWcCoatings(bathWcCoatings);
  }

  componentWillMount() {
    this.updateBathWcCoatings();
  }

  onBathWcCoatingsChange(value) {
    const { bathWcCoatings } = this.props.bathroomWc;
    bathWcCoatings.value = value;

    this.updateBathWcCoatings();
    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onBathNumberChange(event) {
    const { bathWcCoatings } = this.props.bathroomWc;
    bathWcCoatings.bathNumber = event.target.value;

    this.updateBathWcCoatings();
    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onDimensionChange(event, dimension, idx) {
    const { bathWcCoatings } = this.props.bathroomWc;

    if (dimension === 'length') {
      bathWcCoatings.dimensions[idx].length = Number(event.target.value);
    } else if (dimension === 'width') {
      bathWcCoatings.dimensions[idx].width = Number(event.target.value);
    } else if (dimension === 'height') {
      bathWcCoatings.dimensions[idx].height = Number(event.target.value);
    }

    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onInvestmentTypeChange(value, idx) {
    const { bathWcCoatings } = this.props.bathroomWc;
    bathWcCoatings.coatings[idx].investmentType = value;

    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onTilesInvestmentTypeChange(value, idx) {
    const { bathWcCoatings } = this.props.bathroomWc;
    bathWcCoatings.coatings[idx].tilesInvestmentType = value;

    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onSanitaryWareChange(value, type, idx) {
    const { bathWcCoatings } = this.props.bathroomWc;
    if (type === 'bath') {
      bathWcCoatings.coatings[idx].bath = value;
    } else if (type === 'washbasin') {
      bathWcCoatings.coatings[idx].washbasin = value;
    } else if (type === 'basin') {
      bathWcCoatings.coatings[idx].basin = value;
    }

    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onBatteriesAndAccessoriesTypesChange(value, idx) {
    const { bathWcCoatings } = this.props.bathroomWc;
    bathWcCoatings.coatings[idx].batteriesType = value;

    this.props.setBathWcCoatings(bathWcCoatings);
  }

  render() {
    const { bath, wc, bathWcCoatings } = this.props.bathroomWc;
    const coatingsFlag = bathWcCoatings.value;
    const bathNumber = bath.newBath ? bath.newBathNumber : 0;
    const wcNumber = wc.newWc ? wc.newWcNumber : 0;
    const hasBathWc = bathNumber !== 0 || wcNumber !== 0;
    const coatingsWithoutBathWc = coatingsFlag && !hasBathWc;

    let bathWcNum = 0;
    if (coatingsFlag) {
      bathWcNum = !coatingsWithoutBathWc ? bathNumber + wcNumber : bathWcCoatings.bathNumber;
    }

    const coatingsView = bathWcCoatings.coatings.map((coating, idx) => {
      let title = `Μπάνιο ${idx + 1}`;
      if (hasBathWc) {
        title = idx < bathNumber ? 'Μπάνιο' : 'Wc';
        title += idx < bathNumber ? ` ${idx + 1}` : ` ${idx - bathNumber + 1}`;
      }

      return (
        <Fragment key={idx}>
          <h3> { title } </h3>
          { !hasBathWc &&
            <Fragment>
              <SimpleInput
                label='Μήκος (σε cm)'
                placeholder='Μήκος (σε cm)'
                value={ bathWcCoatings.length }
                onChange={ (e) => { this.onDimensionChange(e, 'length', idx) } }
                type="number"
              />

              <SimpleInput
                label='Πλάτος (σε cm)'
                placeholder='Πλάτος (σε cm)'
                value={ bathWcCoatings.width }
                onChange={ (e) => { this.onDimensionChange(e, 'width', idx) } }
                type="number"
              />

              <SimpleInput
                label='Ύψος (σε cm)'
                placeholder='Ύψος (σε cm)'
                value={ bathWcCoatings.height }
                onChange={ (e) => { this.onDimensionChange(e, 'height', idx) } }
                type="number"
              />
            </Fragment>
          }

          <SimpleSelect
            label="Είδος επένδυσης"
            placeholder="Είδος επένδυσης"
            options={ investmentTypeOptions }
            value={ coating.investmentType }
            onChange={ (value) => { this.onInvestmentTypeChange(value, idx) } }
          />

          <SimpleSelect
            label="Είδος πλακιδίων"
            placeholder="Είδος πλακιδίων"
            options={ tilesInvestmentTypeOptions }
            value={ coating.tilesInvestmentType }
            onChange={ (value) => { this.onTilesInvestmentTypeChange(value, idx) } }
          />

          <SimpleSelect
            label="Επιλογή μπανιέρας"
            placeholder="Επιλογή μπανιέρας"
            options={ bathOptions }
            value={ coating.bath }
            onChange={ (value) => { this.onSanitaryWareChange(value, 'bath', idx) } }
          />

          <SimpleSelect
            label="Επιλογή νιπτήρα"
            placeholder="Επιλογή νιπτήρα"
            options={ washbasinOptions }
            value={ coating.washbasin }
            onChange={ (value) => { this.onSanitaryWareChange(value, 'washbasin', idx) } }
          />

          <SimpleSelect
            label="Επιλογή λεκάνης"
            placeholder="Επιλογή λεκάνης"
            options={ basinOptions }
            value={ coating.basin }
            onChange={ (value) => { this.onSanitaryWareChange(value, 'basin', idx) } }
          />

          <SimpleSelect
            label="Τύπος μπαταρίας"
            placeholder="Τύπος μπαταρίας"
            options={ batteriesTypeOptions }
            value={ coating.batteriesType }
            onChange={ (value) => { this.onBatteriesAndAccessoriesTypesChange(value, idx) } }
          />
        </Fragment>
      );
    });

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Επενδύσεις δαπέδων / τοίχων μπάνιων / WC'
        />
        
        <SimpleToggle
          label='Επενδύσεις δαπέδων / τοίχων μπάνιων / WC'
          onChange={ (value) => { this.onBathWcCoatingsChange(value); }}
          value={ bathWcCoatings.value }
        />

        { coatingsWithoutBathWc &&
          <Fragment>

            <SimpleInput
              label='Αριθμός μπάνιων'
              placeholder='Αριθμός μπάνιων'
              value={ bathWcCoatings.bathNumber }
              onChange={ (e) => { this.onBathNumberChange(e) } }
              type="number"
            />

          </Fragment>
        }

        { coatingsView }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setBathWcCoatings: (value) => dispatch(setBathWcCoatingsAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(BathWcCoatings);
