import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setThermalInsulationAction } from '../../../redux/actions/action-insulation';
import SimpleToggle from '../../simple-components/SimpleToggle';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class ThermalInsulation extends Component {
  onThermalInsulationChange(value) {
    const { thermalInsulation } = this.props.insulation;
    thermalInsulation.value = value;

    this.props.setThermalInsulationAction(thermalInsulation);
  }

  render() {
    const { thermalInsulation } = this.props.insulation;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Μόνωση δώματος (θερμομόνωση)'
        />

        <SimpleToggle
          label='Θερμομόνωση'
          onChange={ (value) => { this.onThermalInsulationChange(value); }}
          value={ thermalInsulation.value }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setThermalInsulationAction: (value) => dispatch(setThermalInsulationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ThermalInsulation);
