import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInternalDoorsPaintingFramesAction } from '../../../redux/actions/action-frames';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

const heightOptions = [
  { text: 'Χαμηλότερη των 210cm', value: 'short-door' },
  { text: 'Ψηλότερη των 210cm', value: 'tall-door' },
];

const doorTypeOptions = [
  { text: 'Απλή', value: 'simple-door' },
  { text: 'Ταμπλαδωτή', value: 'flatbed-door' },
];

class InternalDoorsPainting extends Component {
  onPaintingDoorsChange(value) {
    const { internalDoorsPainting } = this.props.frames;
    internalDoorsPainting.value = value;

    this.props.setInternalDoorsPaintingFramesAction(internalDoorsPainting);
  }

  onPaintingDoorsNumberChange(event) {
    const { internalDoorsPainting } = this.props.frames;
    internalDoorsPainting.number = event.target.value;

    this.props.setInternalDoorsPaintingFramesAction(internalDoorsPainting);
  }

  doorHeightChange(value) {
    const { internalDoorsPainting } = this.props.frames;
    internalDoorsPainting.doorHeight = value;

    this.props.setInternalDoorsPaintingFramesAction(internalDoorsPainting);
  }

  doorTypeChange(value) {
    const { internalDoorsPainting } = this.props.frames;
    internalDoorsPainting.doorType = value;

    this.props.setInternalDoorsPaintingFramesAction(internalDoorsPainting);
  }

  render() {
    const { internalDoors, internalDoorsPainting } = this.props.frames;
    const segmentClass = `sub-segment ${!internalDoors.value ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className={ segmentClass }>
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Βαφή εσωτερικών πορτών'
        />

        <SimpleToggle
          label='Βαφή εσωτερικών πορτών'
          onChange={ (value) => { this.onPaintingDoorsChange(value); }}
          value={ internalDoorsPainting.value }
        />

        { internalDoorsPainting.value &&
          <SimpleInput
            label='Πλήθος πορτών για βαφή'
            placeholder='Πλήθος πορτών για βαφή'
            value={ internalDoorsPainting.number }
            onChange={ (e) => { this.onPaintingDoorsNumberChange(e) } }
            type="number"
          />
        }

        { internalDoorsPainting.value && internalDoorsPainting.number > 0 &&
          <Fragment>
            <SimpleSelect
              label="Ύψος πορτών"
              placeholder="Ύψος πορτών"
              options={ heightOptions }
              value={ internalDoorsPainting.doorHeight }
              onChange={ (value) => { this.doorHeightChange(value) } }
            />

            <SimpleSelect
              label="Είδος πορτών"
              placeholder="Είδος πορτών"
              options={ doorTypeOptions }
              value={ internalDoorsPainting.doorType }
              onChange={ (value) => { this.doorTypeChange(value) } }
            />

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Στην τιμή δε συμπεριλαμβάνονται αντικατάσταση κάσας, περβαζιών,
                κλειδαριάς και χερουλιών."
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setInternalDoorsPaintingFramesAction: (value) => dispatch(setInternalDoorsPaintingFramesAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalDoorsPainting);
