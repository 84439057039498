import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInternalWallsPaintsAction } from '../../../redux/actions/action-paints';
import SimpleToggle from '../../simple-components/SimpleToggle';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

class InternalWalls extends Component {
  onInternalWallsPaintChange(value) {
    const { internalWalls } = this.props.paints;
    internalWalls.value = value;

    this.props.setInternalWallsPaintsAction(internalWalls);
  }

  onInternalWallsSpatulatoryChange(value) {
    const { internalWalls } = this.props.paints;
    internalWalls.spatulatory = value;

    this.props.setInternalWallsPaintsAction(internalWalls);
  }

  render() {
    const { internalWalls } = this.props.paints;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Βαφή εσωτερικών τοίχων'
        />

        <SimpleToggle
          label='Βαφή εσωτερικών τοίχων'
          onChange={ (value) => { this.onInternalWallsPaintChange(value); }}
          value={ internalWalls.value }
        />

        { internalWalls.value &&
          <Fragment>
            <SimpleToggle
              label='Σπατουλαριστοί τοίχοι'
              onChange={ (value) => { this.onInternalWallsSpatulatoryChange(value); }}
              value={ internalWalls.spatulatory }
            />

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Η κοστολόγηση αφορά βαφή με πλαστικό χρώμα."
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setInternalWallsPaintsAction: (value) => dispatch(setInternalWallsPaintsAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalWalls);
