import { CONSTANT_VALUES } from '../constants';

const { dismantling } = CONSTANT_VALUES;

export const defaultDismantling = {
  rubbleRemoval: {
    dismantlingCost: dismantling.rubbleRemovalCost,
  },
  kitchen: {
    value: false,
    type: 'furniture', // Could be furniture or built
    dismantlingCost: dismantling.kitchenDismantlingCost,
  },
  internalFrames: {
    value: false,
    moreThanThreshold: false,
    threshold: dismantling.internalFrames.threshold,
    dismantlingCost: dismantling.internalFrames.cost,
  },
  externalFrames: {
    value: false,
    moreThanThreshold: false,
    threshold: dismantling.externalFrames.threshold,
    dismantlingCost: dismantling.externalFrames.cost,
  },
  bath: {
    value: false,
    dismantlingCostPerBath: dismantling.bath.eachBath.cost,
    dismantlingBucketPerBath: dismantling.bath.eachBath.bucket,
  },
  wc: {
    value: false,
    dismantlingCostPerWc: dismantling.wc.eachWc.cost,
    dismantlingBucketPerWc: dismantling.wc.eachWc.bucket,
  },
  loft: {
    value: false,
    number: 0,
    dismantlingCostPerLoft: dismantling.loft.eachLoft.cost,
    dismantlingBucketPerLoft: dismantling.loft.eachLoft.bucket,
  },
  floor: {
    value: false,
    types: [], // wooden, mosaic, tile, marble
    surface: {
      wooden: 0,
      mosaic: 0,
      tile: 0,
      marble: 0,
    },
    dismantlingBucketPerM2: dismantling.floor.perM2.bucket,
    dismantlingCostPerM2: {
      wooden: dismantling.floor.perM2.cost.wooden,
      mosaic: dismantling.floor.perM2.cost.mosaic,
      tile: dismantling.floor.perM2.cost.tile,
      marble: dismantling.floor.perM2.cost.marble,
    },
  },
  wall: {
    value: false,
    number: 0,
    dismantlingCostPerWall: dismantling.wall.eachWall.cost,
    dismantlingBucketPerWall: dismantling.wall.eachWall.bucket,
  },
  bucket: {
    costPerBucket: 90,
    bucketStartingNumber: 1, // Always add 1 bucket to the sum of buckets
  },
  cleanliness: {
    fixedCost: 150,
  },
};
