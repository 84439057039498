import { CONSTANT_VALUES } from '../constants';

const { plumbing } = CONSTANT_VALUES;

export const defaultPlumbing = {
  kitchen: {
    newKitchen: false,
    newKitchenCost: plumbing.kitchen.newKitchenCost,
    move: false,
    moveCost: plumbing.kitchen.moveCost,
  },
  solarHeater: {
    installation: false,
    installationBaseCost: plumbing.solarHeater.installationBaseCost,
    installationCostPerFloor: plumbing.solarHeater.installationCostPerFloor,
  },
  balconyFaucet: {
    installation: false,
    number: 0,
    installationCostPerFaucet: plumbing.balconyFaucet.installationCostPerFaucet,
  },
  washingMachine: {
    outOfBath: false,
    numberOutOfBath: 0,
    outOfBathCostPerMachine: plumbing.washingMachine.outOfBathCostPerMachine,
  },
};
