export const SET_KITCHEN_PLUMBING = 'SET_KITCHEN_PLUMBING';
export const SET_SOLAR_HEATER_PLUMBING = 'SET_SOLAR_HEATER_PLUMBING';
export const SET_BALCONY_FAUCET_PLUMBING = 'SET_BALCONY_FAUCET_PLUMBING';
export const SET_WASHING_MACHINE_PLUMBING = 'SET_WASHING_MACHINE_PLUMBING';

export const setKitchenPlumbingAction = (kitchen) =>
  dispatch => {
    dispatch({
      type: SET_KITCHEN_PLUMBING,
      payload: kitchen,
    });
  };

export const setSolarHeaterPlumbingAction = (solarHeater) =>
  dispatch => {
    dispatch({
      type: SET_SOLAR_HEATER_PLUMBING,
      payload: solarHeater,
    });
  };

export const setBalconyFaucetPlumbingAction = (balconyFaucet) =>
  dispatch => {
    dispatch({
      type: SET_BALCONY_FAUCET_PLUMBING,
      payload: balconyFaucet,
    });
  };

export const setWashingMachinePlumbingAction = (washingMachine) =>
  dispatch => {
    dispatch({
      type: SET_WASHING_MACHINE_PLUMBING,
      payload: washingMachine,
    });
  };
