import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setWallCladdingAction } from '../../../redux/actions/action-wall-cladding';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { SEGMENT, HEADER } from '../../../config/constants';

const wallCladdingTypeOptions = [
  { text: 'Επένδυση με τουβλάκι', value: 'brick' },
  { text: 'Επένδυση με ταπετσαρία/βινυλική μεμβράνη', value: 'vinyl-film' },
  { text: 'Εφαρμογή τεχνοτροπίας βαφής', value: 'painting' },
];

class WallCladding extends Component {
  onWallCladdingChange(value) {
    const { wallCladding } = this.props.wallCladding;
    wallCladding.value = value;

    this.props.setWallCladdingAction(wallCladding);
  }

  onWallCladdingSurfaceChange(event) {
    const { wallCladding } = this.props.wallCladding;
    wallCladding.surface = event.target.value;

    this.props.setWallCladdingAction(wallCladding);
  }

  onWallCladdingTypeChange(value) {
    const { wallCladding } = this.props.wallCladding;
    wallCladding.type = value;

    this.props.setWallCladdingAction(wallCladding);
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { wallCladding } = this.props.wallCladding;

    const { basic } = SEGMENT;
    const basicClassName = `section wall-cladding ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Εργασίες επένδυσης τοίχων με αρχιτεκτονικά υλικά'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Επένδυση τοίχων με αρχιτεκτονικά υλικά'
            />

            <SimpleToggle
              label='Επένδυση τοίχων'
              onChange={ (value) => { this.onWallCladdingChange(value); }}
              value={ wallCladding.value }
            />

            { wallCladding.value &&
              <Fragment>
                <SimpleInput
                  label='Εμβαδόν τοίχου (σε τ.μ.)'
                  placeholder='Εμβαδόν τοίχου (σε τ.μ.)'
                  value={ wallCladding.surface }
                  onChange={ (e) => { this.onWallCladdingSurfaceChange(e) } }
                  type="number"
                />

                <SimpleSelect
                  label="Τύπος επένδυσης"
                  placeholder="Τύπος επένδυσης"
                  options={ wallCladdingTypeOptions }
                  value={ wallCladding.type }
                  onChange={ (value) => { this.onWallCladdingTypeChange(value) } }
                />
              </Fragment>
            }

            </SimpleSegment>
        </SimpleSegment>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWallCladdingAction: (value) => dispatch(setWallCladdingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WallCladding);
