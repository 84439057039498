export const heatingCalc = (state) => {
  const {
    newSource, heatersDismantling, heatersMoving, newHeaters,
    newBoiler, newFireplace, heatingCostConstants,
  } = state.heating;

  const isOil = newSource.value === 'oil' && newSource.srcNumber.oil > 0;
  const isGas = newSource.value === 'gas' && newSource.srcNumber.gas > 0;

  let oilNewNetworkCost = 0;
  if (isOil && newSource.newNetwork.value) {
    oilNewNetworkCost = newSource.newNetwork.type === 'inFloor'
      ? newSource.srcNumber.oil * heatingCostConstants.oil.newNetwork.inFloorPerHeater
      : newSource.srcNumber.oil * heatingCostConstants.oil.newNetwork.outOfFloorPerHeater;
  }

  let gasNewNetworkCost = 0;
  if (isGas && newSource.newNetwork.value) {
    gasNewNetworkCost = newSource.newNetwork.type === 'inFloor'
      ? newSource.srcNumber.gas * heatingCostConstants.gas.newNetwork.inFloorPerHeater
      : newSource.srcNumber.gas * heatingCostConstants.gas.newNetwork.outOfFloorPerHeater;
  }

  const oilDismantlingCost = isOil && heatersDismantling.value
    ? heatingCostConstants.oil.dismantling : 0;

  const gasDismantlingCost = isGas && heatersDismantling.value
    ? heatingCostConstants.gas.dismantling : 0;

  let oilHeatersMovingCost = 0;
  if (newSource.value === 'oil' && heatersMoving.value) {
    oilHeatersMovingCost = heatersMoving.type === 'inFloor'
      ? Number(heatersMoving.number) * Number(heatingCostConstants.oil.moving.inFloorPerMovingHeater)
      : Number(heatersMoving.number) * Number(heatingCostConstants.oil.moving.outOfFloorPerMovingHeater);
  }

  let gasHeatersMovingCost = 0;
  if (newSource.value === 'gas' && heatersMoving.value) {
    gasHeatersMovingCost = heatersMoving.type === 'inFloor'
      ? Number(heatersMoving.number) * Number(heatingCostConstants.gas.moving.inFloorPerMovingHeater)
      : Number(heatersMoving.number) * Number(heatingCostConstants.gas.moving.outOfFloorPerMovingHeater);
  }

  const oilNewHeatersCost = isOil && newHeaters.value
    ? newHeaters.number * heatingCostConstants.oil.newHeatersPerNumber
    : 0;

  const gasNewHeatersCost = isGas && newHeaters.value
    ? newHeaters.number * heatingCostConstants.gas.newHeatersPerNumber
    : 0;

  const oilBoilerCost = newSource.value === 'oil' && newBoiler.oil
    ? heatingCostConstants.oil.newBoiler : 0;

  const gasBoilerCost = newSource.value === 'gas' && newBoiler.gas
    ? heatingCostConstants.gas.newBoiler : 0;

  let fireplaceCost = 0;
  if (newFireplace.value) {
    fireplaceCost = newFireplace.type === 'simple'
      ? heatingCostConstants.fireplace.simple
      : heatingCostConstants.fireplace.energy;
  }

  const newNetworkTypeText = newSource.newNetwork.type === 'inFloor'
    ? 'ενδοδαπέδια σωλήνωση (τουμπόραμα/ppr)' : 'σωλήνωση εκτός δαπέδου (χαλκοσωλήνα)';

  const movingTypeText = heatersMoving.type === 'inFloor'
    ? 'ενδοδαπέδια σωλήνωση (τουμπόραμα/ppr)' : 'σωλήνωση εκτός δαπέδου (χαλκοσωλήνα)';

  const fireplaceTypeText = newFireplace.type === 'simple' ? 'απλού' : 'ενεργειακού';

  const cost = {
    oilNewNetworkCost: {
      display: oilNewNetworkCost > 0,
      text: `Κόστος νέου δικτύου θέρμανσης πετρελαίου (${ newSource.srcNumber.oil } σώματα - ${ newNetworkTypeText })`,
      cost: oilNewNetworkCost > 0 ? oilNewNetworkCost : 0,
    },
    gasNewNetworkCost: {
      display: gasNewNetworkCost > 0,
      text: `Κόστος νέου δικτύου θέρμανσης φυσικού αερίου (${ newSource.srcNumber.gas } σώματα - ${ newNetworkTypeText })`,
      cost: gasNewNetworkCost > 0 ? gasNewNetworkCost : 0,
    },
    oilDismantlingCost: {
      display: oilDismantlingCost > 0,
      text: 'Κόστος αποξήλωσης θερμαντικών σωμάτων',
      cost: oilDismantlingCost > 0 ? oilDismantlingCost : 0,
    },
    gasDismantlingCost: {
      display: gasDismantlingCost > 0,
      text: 'Κόστος αποξήλωσης θερμαντικών σωμάτων',
      cost: gasDismantlingCost > 0 ? gasDismantlingCost : 0,
    },
    oilHeatersMovingCost: {
      display: oilHeatersMovingCost > 0,
      text: `Κόστος μεταφοράς υφιστάμενων σωμάτων (${ heatersMoving.number } σώματα - ${ movingTypeText })`,
      cost: oilHeatersMovingCost > 0 ? oilHeatersMovingCost : 0,
    },
    gasHeatersMovingCost: {
      display: gasHeatersMovingCost > 0,
      text: `Κόστος μεταφοράς υφιστάμενων σωμάτων (${ heatersMoving.number } σώματα - ${ movingTypeText })`,
      cost: gasHeatersMovingCost > 0 ? gasHeatersMovingCost : 0,
    },
    oilNewHeatersCost: {
      display: oilNewHeatersCost > 0,
      text: `Κόστος προμήθειας νέων θερμαντικών σωμάτων (${ newHeaters.number } σώματα)`,
      cost: oilNewHeatersCost > 0 ? oilNewHeatersCost : 0,
    },
    gasNewHeatersCost: {

      display: gasNewHeatersCost > 0,
      text: `Κόστος προμήθειας νέων θερμαντικών σωμάτων (${ newHeaters.number } σώματα)`,
      cost: gasNewHeatersCost > 0 ? gasNewHeatersCost : 0,
    },
    oilBoilerCost: {
      display: oilBoilerCost > 0,
      text: 'Κόστος προμήθειας νέου λέβητα πετρελαίου',
      cost: oilBoilerCost > 0 ? oilBoilerCost : 0,
    },
    gasBoilerCost: {
      display: gasBoilerCost > 0,
      text: 'Κόστος προμήθειας νέου λέβητα συμπήκνωσης φυσικού αερίου',
      cost: gasBoilerCost > 0 ? gasBoilerCost : 0,
    },
    fireplaceCost: {
      display: fireplaceCost > 0,
      text: `Κόστος ${ fireplaceTypeText } τζακιού`,
      cost: fireplaceCost > 0 ? fireplaceCost : 0,
    },
  };

  const all = cost.oilNewNetworkCost.cost
    + cost.gasNewNetworkCost.cost + cost.oilDismantlingCost.cost
    + cost.gasDismantlingCost.cost + cost.oilHeatersMovingCost.cost
    + cost.gasHeatersMovingCost.cost + cost.oilNewHeatersCost.cost
    + cost.gasNewHeatersCost.cost + cost.oilBoilerCost.cost
    + cost.gasBoilerCost.cost + cost.fireplaceCost.cost;

  const title = 'Εργασίες θέρμανσης';
  const sumTitle = 'Σύνολο εργασιών θέρμανσης';

  return { title, sumTitle, cost, all };
};
