import React from 'react';
import { Label, Radio } from 'semantic-ui-react';

const SimpleToggle = ( { label, value, onChange, display }) => {
  const displayToggler = display || true;

  return (
    <div style={{ padding: '5px', display: displayToggler ? 'block' : 'none' }}>
      <Label pointing='right'> { label } </Label>
      <Radio
        style={{ position: 'relative', top: '5px' }}
        toggle
        onChange={ (_, { checked }) => { onChange(checked) } }
        checked={ value }
      />
    </div>
  );
}

export default SimpleToggle;
