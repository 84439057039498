import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNewHeatersAction } from '../../../redux/actions/action-heating';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class SupplyNewHeaterPanels extends Component {
  onNewHeatersChange(value) {
    const { newHeaters } = this.props.heating;
    newHeaters.value = value;

    this.props.setNewHeatersAction(newHeaters);
  }

  onNewHeatersNumberChange(event) {
    const { newHeaters } = this.props.heating;
    newHeaters.number = event.target.value;

    this.props.setNewHeatersAction(newHeaters);
  }

  render() {
    const { newSource, newHeaters } = this.props.heating;
    const oilOrGas = newSource.value === 'oil' || newSource.value === 'gas';
    const segmentClass = `sub-segment ${oilOrGas ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Προμήθεια νέων θερμαντικών σωμάτων (panel)'
        />

        <SimpleToggle
          label='Προμήθεια νέων σωμάτων'
          onChange={ (value) => { this.onNewHeatersChange(value); }}
          value={ newHeaters.value }
        />

        { newHeaters.value &&
          <SimpleInput
            label='Αριθμός νέων σωμάτων'
            placeholder='Αριθμός νέων σωμάτων'
            value={ newHeaters.number }
            onChange={ (e) => { this.onNewHeatersNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewHeatersAction: (value) => dispatch(setNewHeatersAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SupplyNewHeaterPanels);
