export const plasterCalc = (state) => {
  const { plaster, plasterCost } = state.plaster;
  const { livingRoom, bedroom, corridor, kitchen } = plaster.rooms;

  const {
    livingroomCostPerRoom, bedroomCostPerRoom,
    corridorCostPerRoom, kitchenCostPerRoom,
  } = plasterCost;

  const isLivingroom = plaster.value && livingRoom.value;
  const isBedroom = plaster.value && bedroom.value;
  const isCorridor = plaster.value && corridor.value;
  const isKitchen = plaster.value && kitchen.value;

  const livingroomCost = isLivingroom
    ? livingroomCostPerRoom * livingRoom.number : 0;

  const bedroomCost = isBedroom
    ? bedroomCostPerRoom * bedroom.number : 0;

  const corridorCost = isCorridor
    ? corridorCostPerRoom * corridor.number : 0;

  const kitchenCost = isKitchen
    ? kitchenCostPerRoom * kitchen.number : 0;

  
  const cost = {
    livingroomCost: {
      display: livingroomCost > 0,
      text: 'Κόστος από εργασίες με γύψινα στο σαλόνι',
      cost: livingroomCost > 0 ? livingroomCost : 0,
    },
    bedroomCost: {
      display: bedroomCost > 0,
      text: 'Κόστος από εργασίες με γύψινα στα υπνοδωμάτια',
      cost: bedroomCost > 0 ? bedroomCost : 0,
    },
    corridorCost: {
      display: corridorCost > 0,
      text: 'Κόστος από εργασίες με γύψινα στο διάδρομο',
      cost: corridorCost > 0 ? corridorCost : 0,
    },
    kitchenCost: {
      display: kitchenCost > 0,
      text: 'Κόστος από εργασίες με γύψινα στην κουζίνα',
      cost: kitchenCost > 0 ? kitchenCost : 0,
    },
  };

  const all = cost.livingroomCost.cost
    + cost.bedroomCost.cost
    + cost.corridorCost.cost
    + cost.kitchenCost.cost;

  const title = 'Γύψινα';
  const sumTitle = 'Συνολικό κόστος εργασιών με γύψινα';

  return { title, sumTitle, cost, all };
};
