import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setCustomFieldsAction } from '../../../redux/actions/action-custom-fields';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleTextArea from '../../simple-components/SimpleTextArea';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { SEGMENT, HEADER } from '../../../config/constants';
import CustomField from '../../../helpers/custom-field';

class CustomFields extends Component {
  onCustomFieldChange(value) {
    const { customFields } = this.props.customFields;
    customFields.value = value;

    this.props.setCustomFieldsAction(customFields);
  }

  onCustomFieldNumberChange(event) {
    const { customFields } = this.props.customFields;
    const currentNumber = Number(customFields.customFieldsNumber);
    const newNumber = Number(event.target.value);

    if (newNumber > currentNumber) {
      const diff = newNumber - currentNumber;
      for (let i = 0; i < diff; i++) {
        customFields.fields.push(new CustomField('', 0));
      }
    } else {
      const diff = currentNumber - newNumber;
      for (let i = 0; i < diff; i++) {
        customFields.fields.pop();
      }
    }

    customFields.customFieldsNumber = newNumber;
    this.props.setCustomFieldsAction(customFields);
  }

  onCustomFieldsFieldChange(value, idx, param) {
    const { customFields } = this.props.customFields;

    if (customFields.fields.length > idx) {
      const val = param === 'text' ? value : Number(value);
      customFields.fields[idx][param] = val;
      this.props.setCustomFieldsAction(customFields);
    }
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { customFields } = this.props.customFields;

    const { basic } = SEGMENT;
    const basicClassName = `section project-inventory ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

    const customFieldsItems = [];
    customFields.fields.forEach((field, idx) => {
      customFieldsItems.push(
        <Fragment key={idx}>
          <h3> Εργασία {idx + 1} </h3>
          <SimpleTextArea
            label=''
            placeholder="Είδος εργασίας"
            value={ field.text }
            onChange={ (e) => { this.onCustomFieldsFieldChange(e.target.value, idx, 'text') } }
          />

          <SimpleInput
            label='Κόστος'
            placeholder='Κόστος'
            value={ field.cost }
            onChange={ (e) => { this.onCustomFieldsFieldChange(e.target.value, idx, 'cost') } }
            type="number"
          />
        </Fragment>
      );
    });

    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Άλλες εργασίες'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Άλλες εργασίες'
            />

            <SimpleToggle
              label='Έχετε άλλες εργασίες'
              onChange={ (value) => { this.onCustomFieldChange(value); }}
              value={ customFields.value }
            />

            { customFields.value &&
              <Fragment>
                <SimpleInput
                  label='Αριθμός custom εργασιών'
                  placeholder='Αριθμός custom εργασιών'
                  value={ customFields.customFieldsNumber }
                  onChange={ (e) => { this.onCustomFieldNumberChange(e) } }
                  type="number"
                />
    
                { customFieldsItems }
              </Fragment>
            }

          </SimpleSegment>
        </SimpleSegment>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setCustomFieldsAction: (value) => dispatch(setCustomFieldsAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
