export const bathroomWcCalc = (state) => {
  const { rooms } = state.preliminaryQuestions;
  const {
    bath, wc, bathWcCoatings, bathroomWcCost,
  } = state.bathroomWc;

  const { newBathCostPerBath, changeBathCostPerBath } = bathroomWcCost.bath;
  const { newWcCostPerWc, changeWcCostPerWc } = bathroomWcCost.wc;

  const newBathCost = bath.newBath ? newBathCostPerBath * bath.newBathNumber : 0;
  const changeBathCost = bath.changeBath ? changeBathCostPerBath * rooms.bathroom : 0;

  const newWcCost = wc.newWc ? newWcCostPerWc * wc.newWcNumber : 0;
  const changeWcCost = wc.changeWc ? changeWcCostPerWc * rooms.wc : 0;

  let coatingsCost = 0;
  if (bathWcCoatings.value) {
    const newBathLength = bath.newBath ? bath.newBathNumber : 0;
    const newWcLength = wc.newWc ? wc.newWcNumber : 0;

    const bathWcLength = newBathLength + newWcLength;
    if (bathWcLength > 0) {
      const dimensions = [];
      bath.newBathsDimensions.forEach((bath) => { dimensions.push(bath); });
      wc.newWcDimensions.forEach((wc) => { dimensions.push(wc); })

      if (dimensions.length === bathWcCoatings.coatings.length) {
        bathWcCoatings.coatings.forEach((coating, idx) => {
          const cost = coating.calcCost(dimensions[idx], bathroomWcCost);
          coatingsCost += cost;
        });
      }
    } else {
      const { dimensions } = bathWcCoatings;

      if (dimensions.length === bathWcCoatings.coatings.length) {
        bathWcCoatings.coatings.forEach((coating, idx) => {
          const cost = coating.calcCost(dimensions[idx], bathroomWcCost);
          coatingsCost += cost;
        });
      }
    }
  }

  const cost = {
    newBathCost: {
      display: newBathCost > 0,
      text: 'Κόστος νέων υδραυλικών εγκαταστάσεων μπάνιου',
      cost: newBathCost > 0 ? newBathCost : 0,
    },
    changeBathCost: {
      display: changeBathCost > 0,
      text: 'Τοποθέτηση νέων είδών υγιεινής μπάνιου',
      cost: changeBathCost > 0 ? changeBathCost : 0,
    },
    newWcCost: {
      display: newWcCost > 0,
      text: 'Κόστος νέων υδραυλικών εγκαταστάσεων wc',
      cost: newWcCost > 0 ? newWcCost : 0,
    },
    changeWcCost: {
      display: changeWcCost > 0,
      text: 'Τοποθέτηση νέων είδων υγιεινής wc',
      cost: changeWcCost > 0 ? changeWcCost : 0,
    },
    coatingsCost: {
      display: coatingsCost > 0,
      text: 'Κόστος επένδυσης δαπέδων / τοίχων μπάνιων / WC',
      cost: coatingsCost > 0 ? coatingsCost : 0,
    },
  };

  const all =
    cost.newBathCost.cost
    + cost.changeBathCost.cost
    + cost.newWcCost.cost
    + cost.changeWcCost.cost
    + cost.coatingsCost.cost;

  const title = 'Μπάνιο / WC';
  const sumTitle = 'Σύνολο εργασιών μπάνιου';

  return { title, sumTitle, cost, all };
};
