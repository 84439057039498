import React, { Component } from 'react';

import { connect } from 'react-redux';

import AllData from './sections/0-alldata/AllData';
import Summary from './sections/0-summary/Summary';
import AdminSummary from './sections/0-summary/AdminSummary';

import PreliminaryQuestions from './sections/1-preliminary-questions/PreliminaryQuestions';
import Dismantling from './sections/2-dismantling/Dismantling';
import Plumbing from './sections/3-plumbing/Plumbing';
import Heating from './sections/4-heating/Heating';
import Cooling from './sections/5-cooling/Cooling';
import Electrical from './sections/6-electrical/Electrical';
import Masonry from './sections/7-masonry/Masonry';
import Plasterboard from './sections/9-plasterboard/Plasterboard';
import Insulation from './sections/10-insulation/Insulation';
import BathroomWc from './sections/11-bathroom-wc/BathroomWc';
import Plaster from './sections/12-plaster/Plaster';
import Floors from './sections/13-floors/Floors';
import WallCladding from './sections/14-wall-cladding/WallCladding';
import Frames from './sections/15-frames/Frames';
import Paints from './sections/16-paints/Paints';
import Kitchen from './sections/17-kitchen/Kitchen';
import Wardrobes from './sections/18-wardrobes/Wardrobes';
import ProjectDesignAndManagement from './sections/19-project-design-and-management/ProjectDesignAndManagement';
import ProjectInventory from './sections/20-project-inventory/ProjectInventory';
import SmallScaleLicense from './sections/21-small-scale-license/SmallScaleLicense';
import BuildingPermit from './sections/22-building-permit/BuildingPermit';
import CustomFields from './sections/23-custom-fields/CustomFields';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appState: 'show-calculator', // Could be show-calculator or show-results
      currentIdx: 1,
    };

    this.eventListeners = this.eventListeners.bind(this);
    this.eventListeners();
  }

  eventListeners() {
    document.body.addEventListener('show-calculator', (e) => {
      e.stopPropagation();
      this.setState({ appState: 'show-calculator' });
    });

    document.body.addEventListener('show-results', (e) => {
      e.stopPropagation();
      this.setState({ appState: 'show-results' });
    });
  }

  onHeaderClick(idx) {
    const newIdx = this.state.currentIdx === idx ? 0 : idx;
    this.setState({ currentIdx: newIdx });
  }

  render() {
    const isCurrent = (idx) => this.state.currentIdx === idx;

    const showBuildingPermit = !this.props.smallScaleLicense.smallScaleLicense.value;

    return (
      <div className="app-container">
        <div
          style={{ display: this.state.appState === 'show-results' ? 'none' : 'none' }}
        >
          <AllData />
        </div>
        <div
          style={{ display: this.state.appState === 'show-results' ? 'none' : 'none' }}
        >
          <AdminSummary />
        </div>

        <div
          style={{ display: this.state.appState === 'show-results' ? 'none' : 'none' }}
        >
          <Summary />
        </div>

        <div
          style={{ display: this.state.appState === 'show-calculator' ? 'block' : 'none' }}
        >
          <PreliminaryQuestions
            isCurrent={ isCurrent(1) }
            headerClick={() => { this.onHeaderClick(1); }}
          />
          
          <Dismantling
            isCurrent={ isCurrent(2) }
            headerClick={() => { this.onHeaderClick(2); }}
          />

          <Plumbing
            isCurrent={ isCurrent(3) }
            headerClick={() => { this.onHeaderClick(3); }}
          />
          
          <Heating
            isCurrent={ isCurrent(4) }
            headerClick={() => { this.onHeaderClick(4); }}
          />

          <Cooling
            isCurrent={ isCurrent(5) }
            headerClick={() => { this.onHeaderClick(5); }}
          />

          <Electrical
            isCurrent={ isCurrent(6) }
            headerClick={() => { this.onHeaderClick(6); }}
          />

          <Masonry
            isCurrent={ isCurrent(7) }
            headerClick={() => { this.onHeaderClick(7); }}
          />

          <Plasterboard
            isCurrent={ isCurrent(9) }
            headerClick={() => { this.onHeaderClick(9); }}
          />

          <Insulation
            isCurrent={ isCurrent(10) }
            headerClick={() => { this.onHeaderClick(10); }}
          />

          <BathroomWc
            isCurrent={ isCurrent(11) }
            headerClick={() => { this.onHeaderClick(11); }}
          />

          <Plaster
            isCurrent={ isCurrent(12) }
            headerClick={() => { this.onHeaderClick(12); }}
          />

          <Floors
            isCurrent={ isCurrent(13) }
            headerClick={() => { this.onHeaderClick(13); }}
          />

          <WallCladding
            isCurrent={ isCurrent(14) }
            headerClick={() => { this.onHeaderClick(14); }}
          />

          <Frames
            isCurrent={ isCurrent(15) }
            headerClick={() => { this.onHeaderClick(15); }}
          />

          <Paints
            isCurrent={ isCurrent(16) }
            headerClick={() => { this.onHeaderClick(16); }}
          />

          <Kitchen
            isCurrent={ isCurrent(17) }
            headerClick={() => { this.onHeaderClick(17); }}
          />

          <Wardrobes
            isCurrent={ isCurrent(18) }
            headerClick={() => { this.onHeaderClick(18); }}
          />

          <CustomFields
            isCurrent={ isCurrent(23) }
            headerClick={() => { this.onHeaderClick(23); }}
          />

          <ProjectDesignAndManagement
            isCurrent={ isCurrent(19) }
            headerClick={() => { this.onHeaderClick(19); }}
          />

          <ProjectInventory
            isCurrent={ isCurrent(20) }
            headerClick={() => { this.onHeaderClick(20); }}
          />

          <SmallScaleLicense
            isCurrent={ isCurrent(21) }
            headerClick={() => { this.onHeaderClick(21); }}
          />

          { showBuildingPermit &&
            <BuildingPermit
              isCurrent={ isCurrent(22) }
              headerClick={() => { this.onHeaderClick(22); }}
            />
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(App);
