import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class PatientNetworks extends Component {
  onPatientNetworksChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.patientNetworks.value = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  onPatientNetworksNumberChange(event) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.patientNetworks.socketNumber = event.target.value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;
    const { radicalWiringRehabilitation } = houseNeeds;
    const segmentClass = `sub-segment ${radicalWiringRehabilitation ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Νέα καλωδίωση ασθενών δικτύων'
        />
        <SimpleToggle
          label='Νέα καλωδίωση ασθενών δικτύων'
          onChange={ (value) => { this.onPatientNetworksChange(value); }}
          value={ houseNeeds.patientNetworks.value }
        />

        { houseNeeds.patientNetworks.value &&
          <SimpleInput
            label='Αριθμός νέων πρίζων για TV/OTE/δίκτυο ethernet'
            placeholder='Αριθμός νέων πρίζων'
            value={ houseNeeds.patientNetworks.socketNumber }
            onChange={ (e) => { this.onPatientNetworksNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientNetworks);
