export const plumbingCalc = (state) => {
  const { floor } = state.preliminaryQuestions;
  const {
    kitchen, solarHeater, balconyFaucet, washingMachine,
  } = state.plumbing;

  const newKitchenCost = kitchen.newKitchen ? kitchen.newKitchenCost : 0;

  const solarHeaterBaseCost = solarHeater.installation
    ? solarHeater.installationBaseCost : 0;

  const solarHeaterFloorCost = solarHeater.installation
    ? solarHeater.installationCostPerFloor * floor : 0;

  const solarHeaterCost = solarHeaterBaseCost + solarHeaterFloorCost;

  const balconyFaucetCost = balconyFaucet.installation
    ? balconyFaucet.installationCostPerFaucet * balconyFaucet.number : 0;

  const washingMachineCost = washingMachine.outOfBath
    ? washingMachine.outOfBathCostPerMachine * washingMachine.numberOutOfBath
    : 0;

  const cost = {
    newKitchenCost: {
      display: newKitchenCost > 0,
      text: 'Κόστος νέας υδραυλικής εγκατάστασης κουζίνας',
      cost: newKitchenCost > 0 ? newKitchenCost : 0,
    },
    solarHeaterCost: {
      display: solarHeaterCost > 0,
      text: 'Κόστος εγκατάστασης ηλιακού θερμοσίφωνα',
      cost: solarHeaterCost > 0 ? solarHeaterCost : 0,
    },
    balconyFaucetCost: {
      display: balconyFaucetCost > 0,
      text: 'Κόστος εγκατάστασης βρυσών στο μπαλκόνι',
      cost: balconyFaucetCost > 0 ? balconyFaucetCost : 0,
    },
    washingMachineCost: {
      display: washingMachineCost > 0,
      text: 'Κόστος εγκατάστασης πλυντηρίων εκτός μπάνιου',
      cost: washingMachineCost > 0 ? washingMachineCost : 0,
    },
  };

  const all = cost.newKitchenCost.cost + cost.solarHeaterCost.cost
    + cost.balconyFaucetCost.cost + cost.washingMachineCost.cost;

  const title = 'Υδραυλικές εργασίες';
  const sumTitle = 'Σύνολο υδραυλικών εργασιών';

  return { title, sumTitle, cost, all };
};
