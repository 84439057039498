import React from 'react';
import { Label, TextArea } from 'semantic-ui-react';

const SimpleTextArea = ( { label, placeholder, onChange, display } ) => {
  const displayInput = display || true;

  return (
    <div style={{ padding: '5px', display: displayInput ? 'block' : 'none' }}>
      { label !== '' &&
        <Label pointing='right'> { label } </Label>
      }
      <TextArea
        placeholder={ placeholder }
        style={{ minHeight: 70, minWidth: 344, borderRadius: 4, padding: 8 }}
        onInput={ (e) => { onChange(e) } }
      />
    </div>
  );
}

export default SimpleTextArea;


