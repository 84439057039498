import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleToggle from '../../simple-components/SimpleToggle';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class ElectricalPanel extends Component {
  onElectricalPanelChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.electricalPanel.newPanel = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  onElectricalPanelMovingChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.electricalPanel.newPanelMoving = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;
    const { radicalWiringRehabilitation } = houseNeeds;
    const segmentClass = `sub-segment ${radicalWiringRehabilitation ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Ηλεκτρολογικός Πίνακας'
        />
          <SimpleToggle
            label='Νέος Πίνακας'
            onChange={ (value) => { this.onElectricalPanelChange(value); }}
            value={ houseNeeds.electricalPanel.newPanel }
          />

          { houseNeeds.electricalPanel.newPanel &&
            <SimpleToggle
              label='Μεταφορά Νέου Πίνακα'
              onChange={ (value) => { this.onElectricalPanelMovingChange(value); }}
              value={ houseNeeds.electricalPanel.newPanelMoving }
            />
          }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ElectricalPanel);
