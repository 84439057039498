import { CONSTANT_VALUES } from '../constants';

const { electrical } = CONSTANT_VALUES;

export const defaultElectrical = {
  houseNeeds: {
    value: 'dontNeedNew', // Could be dontNeedNew or needNew
    radicalWiringRehabilitation: false,
    electricalPanel: {
      newPanel: false,
      newPanelMoving: false,
    },
    patientNetworks: {
      value: false,
      socketNumber: 0,
    },
    strongNetworks: {
      value: false,
      socketNumber: 0,
    },
    electricalNetworkExtension: {
      value: false,
      surface: 0,
    },
    magneticContacts: {
      value: false,
    },
    securitySystems: {
      value: false,
    },
    socketInstallationType: 'nothing', // Could be nothing or supply-install or install 
    newLamps: false,
    newLampsWiring: false,
    newElectricalDevices: false,
    electricalWorksCost: electrical.houseNeedsCost,
  },
};
