import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_HOUSE_NEEDS_ELECTRICAL,
} from '../actions/action-electrical';

const { houseNeeds } = defaultValues.electrical;

export default (state = {
  houseNeeds,
}, action) => {
  switch (action.type) {
    case SET_HOUSE_NEEDS_ELECTRICAL:
      return copyObject(state, {
        houseNeeds: action.payload,
      });
    default:
      return state;
  }
}
