import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  setBathWcCoatingsAction,
  setBathPlumbingAction,
} from '../../../redux/actions/action-bathroom-wc';
import { setCoatings } from '../../../helpers/utils';

import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleInput from '../../simple-components/SimpleInput';
import RoomDimensions from '../../../helpers/room-dimensions';

class BathInstallation extends React.Component {
  updateBathWcCoatings() {
    const { bath, wc, bathWcCoatings } = this.props.bathroomWc;
    const { coatings, dimensions } = setCoatings(bath, wc, bathWcCoatings);

    bathWcCoatings.coatings = coatings;
    bathWcCoatings.dimensions = dimensions;
    this.props.setBathWcCoatings(bathWcCoatings);
  }

  onNewBathPlumbingChange(value) {
    const { bath } = this.props.bathroomWc;
    bath.newBath = value;

    this.updateBathWcCoatings();
    this.props.setBathPlumbingAction(bath);
  }

  onNewBathNumberChange(event) {
    const { bath } = this.props.bathroomWc;
    const currentNumber = Number(bath.newBathNumber);
    const newNumber = Number(event.target.value);

    if (newNumber > currentNumber) {
      const diff = newNumber - currentNumber;
      for (let i = 0; i < diff; i++) {
        bath.newBathsDimensions.push(new RoomDimensions(0, 0, 0));
      }
    } else {
      const diff = currentNumber - newNumber;
      for (let i = 0; i < diff; i++) {
        bath.newBathsDimensions.pop();
      }
    }

    bath.newBathNumber = newNumber;
    this.updateBathWcCoatings();
    this.props.setBathPlumbingAction(bath);
  }

  onBathWorkPlumbingChange(value) {
    const { bath } = this.props.bathroomWc;
    bath.changeBath = value;

    this.props.setBathPlumbingAction(bath);
  }

  onBathroomDimensionsChange(value, idx, param) {
    const { bath } = this.props.bathroomWc;

    if (bath.newBathsDimensions.length > idx) {
      bath.newBathsDimensions[idx][param] = Number(value);
      this.props.setBathPlumbingAction(bath);
    }
  }

  render() {
    const { bath } = this.props.bathroomWc;

    const bathroomDimensionsItems = [];
    bath.newBathsDimensions.forEach((bathDimensions, idx) => {
      bathroomDimensionsItems.push(
        <Fragment key={idx}>
          <h3> Μπάνιο {idx + 1} </h3>
          <SimpleInput
            label='Μήκος (σε cm)'
            placeholder='Μήκος (σε cm)'
            value={ bathDimensions.length }
            onChange={ (e) => { this.onBathroomDimensionsChange(e.target.value, idx, 'length') } }
            type="number"
          />

          <SimpleInput
            label='Πλάτος (σε cm)'
            placeholder='Πλάτος (σε cm)'
            value={ bathDimensions.width }
            onChange={ (e) => { this.onBathroomDimensionsChange(e.target.value, idx, 'width') } }
            type="number"
          />

          <SimpleInput
            label='Υψος (σε cm)'
            placeholder='Υψος (σε cm)'
            value={ bathDimensions.height }
            onChange={ (e) => { this.onBathroomDimensionsChange(e.target.value, idx, 'height') } }
            type="number"
          />
        </Fragment>
      );
    });

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Υδραυλική εγκατάσταση μπάνιου'
        />
        
        <SimpleToggle
          label='Nέα εγκατάσταση μπάνιου'
          onChange={ (value) => { this.onNewBathPlumbingChange(value); }}
          value={ bath.newBath }
        />

        { bath.newBath &&
          <Fragment>
            <SimpleInput
              label='Αριθμός μπάνιων'
              placeholder='Αριθμός μπάνιων'
              value={ bath.newBathNumber }
              onChange={ (e) => { this.onNewBathNumberChange(e) } }
              type="number"
            />

            { bathroomDimensionsItems }
          </Fragment>
        }

        { !bath.newBath &&
          <SimpleToggle
            label='Μόνον αντικατάσταση ειδών υγιεινής'
            onChange={ (value) => { this.onBathWorkPlumbingChange(value); }}
            value={ bath.changeBath }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setBathPlumbingAction: (value) => dispatch(setBathPlumbingAction(value)),
  setBathWcCoatings: (value) => dispatch(setBathWcCoatingsAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BathInstallation);
