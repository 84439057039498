import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNewSourceHeatingAction } from '../../../redux/actions/action-heating';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const heatingOptions = [
  { text: 'Τίποτα', value: 'nothing' },
  { text: 'Πετρέλαιο', value: 'oil' },
  { text: 'Φυσικό Αέριο', value: 'gas' },
  { text: 'A / C', value: 'ac' },
  { text: 'Θερμοπομπός', value: 'convector' },
];

class NewHeatingSource extends Component {
  onNewSourceHeatingChange(value) {
    const { newSource } = this.props.heating;
    const selectedValue = heatingOptions.find(option => option.value === value);

    if (selectedValue !== undefined && selectedValue !== null) {
      newSource.value = selectedValue.value;
      this.props.setNewSourceHeatingAction(newSource);
    }
  }

  onSrcNumberChange(event, srcType) {
    const { newSource } = this.props.heating;
    const srcNumber = event.target.value;

    newSource.srcNumber[srcType] = srcNumber;
    this.props.setNewSourceHeatingAction(newSource);
  }

  render() {
    const { newSource } = this.props.heating;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Νέα πηγή θέρμανσης'
        />

        <SimpleSelect
          label="Νέα πηγή θέρμανσης"
          placeholder="Νέα πηγή θέρμανσης"
          options={ heatingOptions }
          value={ newSource.value }
          onChange={ (value) => { this.onNewSourceHeatingChange(value) } }
        />

        { newSource.value === 'convector' &&
          <SimpleInput
            label='Αριθμός Σωμάτων (θερμοπομπών)'
            placeholder='Αριθμός Σωμάτων (θερμοπομπών)'
            value={ newSource.srcNumber.convector }
            onChange={ (e) => { this.onSrcNumberChange(e, 'convector') } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewSourceHeatingAction: (value) => dispatch(setNewSourceHeatingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewHeatingSource);
