import { CONSTANT_VALUES } from '../constants';

const { projectDesignAndManagement } = CONSTANT_VALUES;

export const defaultProjectDesignAndManagement = {
  projectDesignAndManagement: {
    value: false,
  },
  projectDesignAndManagementCost: projectDesignAndManagement,
};
