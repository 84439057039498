import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setCeilingsPlasterboardAction } from '../../../redux/actions/action-plasterboard';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const ceilingTypeOptions = [
  { text: 'Απλή οροφή γυψοσανίδας', value: 'simple' },
  { text: 'Αρχιτεκτονική λύση', value: 'advanced' },
];

class Ceilings extends Component {
  onCeilingChange(value) {
    const { ceilings } = this.props.plasterboard;
    ceilings.value = value;

    this.props.setCeilingsPlasterboardAction(ceilings);
  }

  onCeilingSurfaceChange(event) {
    const { ceilings } = this.props.plasterboard;
    ceilings.surface = event.target.value;

    this.props.setCeilingsPlasterboardAction(ceilings);
  }

  onCeilingTypeChange(value) {
    const { ceilings } = this.props.plasterboard;
    ceilings.type = value;

    this.props.setCeilingsPlasterboardAction(ceilings);
  }

  render() {
    const { ceilings } = this.props.plasterboard;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Οροφές με γυψοσανίδες'
        />

        <SimpleToggle
          label='Νέα οροφή'
          onChange={ (value) => { this.onCeilingChange(value); }}
          value={ ceilings.value }
        />

        { ceilings.value &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν οροφής (σε τ.μ.)'
              placeholder='Εμβαδόν οροφής (σε τ.μ.)'
              value={ ceilings.surface }
              onChange={ (e) => { this.onCeilingSurfaceChange(e) } }
              type="number"
            />

            <SimpleSelect
              label="Τύπος οροφής"
              placeholder="Τύπος οροφής"
              options={ ceilingTypeOptions }
              value={ ceilings.type }
              onChange={ (value) => { this.onCeilingTypeChange(value) } }
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setCeilingsPlasterboardAction: (value) => dispatch(setCeilingsPlasterboardAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Ceilings);
