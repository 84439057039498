import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { plasterCalc } from '../../../calcs/plaster-calc';
import { getSummaryTrs } from '../../../helpers/react-utils';

class PlasterSummary extends React.Component {

  render() {
    const { title, sumTitle, cost, all } = plasterCalc(this.props);
    const trs = getSummaryTrs(title, sumTitle, cost, all, this.props.showOnlySum);

    return (
      <Fragment>{ trs }</Fragment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(PlasterSummary);
