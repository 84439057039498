import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_PROJECT_INVENTORY,
} from '../actions/action-project-inventory';

const { projectInventory, projectInventoryCost } = defaultValues.projectInventory;

export default (state = {
  projectInventory, projectInventoryCost,
}, action) => {
  switch (action.type) {
    case SET_PROJECT_INVENTORY:
      return copyObject(state, {
        projectInventory: action.payload,
      });
    default:
      return state;
  }
}
