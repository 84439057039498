import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_CURRENT_SOURCE_HEATING, SET_NEW_SOURCE_HEATING,
  SET_HEATERS_DISMANTLING_HEATING, SET_HEATERS_MOVING_HEATING,
  SET_NEW_HEATERS_HEATING, SET_NEW_BOILER_HEATING,
  SET_NEW_FIREPLACE_HEATING,
} from '../actions/action-heating';

const {
  currentSource, newSource, heatersDismantling, heatersMoving,
  newHeaters, newBoiler, newFireplace, heatingCostConstants,
} = defaultValues.heating;

export default (state = {
  currentSource, newSource, heatersDismantling, heatersMoving,
  newHeaters, newBoiler, newFireplace, heatingCostConstants,
}, action) => {
  switch (action.type) {
    case SET_CURRENT_SOURCE_HEATING:
      return copyObject(state, {
        currentSource: action.payload,
      });
    case SET_NEW_SOURCE_HEATING:
      return copyObject(state, {
        newSource: action.payload,
      });
    case SET_HEATERS_DISMANTLING_HEATING:
      return copyObject(state, {
        heatersDismantling: action.payload,
      });
    case SET_HEATERS_MOVING_HEATING:
      return copyObject(state, {
        heatersMoving: action.payload,
      });
    case SET_NEW_HEATERS_HEATING:
      return copyObject(state, {
        newHeaters: action.payload,
      });
    case SET_NEW_BOILER_HEATING:
      return copyObject(state, {
        newBoiler: action.payload,
      });
    case SET_NEW_FIREPLACE_HEATING:
      return copyObject(state, {
        newFireplace: action.payload,
      });
    default:
      return state;
  }
}
