import { CONSTANT_VALUES } from '../constants';

const { region } = CONSTANT_VALUES.preliminaryQuestions;

export const defaultPreliminaryQuestions = {
  floor: 2,
  rooms: {
    kitchen: 1,
    livingRoom: 1,
    bedroom: 1,
    kitchenLivingRoom: 0,
    bathroom: 1,
    wc: 0,
    warehouse: 1,
    balcony: 0,
    corridor: 1,
    info: '',
  },
  surface: 50,
  rubbleRemoval: 'elevator', // Could be elevator, stairs, mechanical-elevator, hopper, truck
  region: {
    insideCity: true,
    distance: null,
    distanceThreshold: region.distanceThreshold,
  },
  building: {
    floorNumber: 2,
  },
};
