import React from 'react'

import KitchenDismantling from './KitchenDismantling';
import InternalFramesDismantling from './InternalFramesDismantling';
import ExternalFramesDismantling from './ExternalFramesDismantling';
import BathDismantling from './BathDismantling';
import WcDismantling from './WcDismantling';
import LoftDismantling from './LoftDismantling';
import FloorDismantling from './FloorDismantling';
import WallDismantling from './WallDismantling';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { HEADER, SEGMENT } from '../../../config/constants';

const Dismantling = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section dismantling ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Αποξηλώσεις / Καθαιρέσεις'
          headerClick={ () => { headerClick(); } }
        />

        <KitchenDismantling />
        <InternalFramesDismantling />
        <ExternalFramesDismantling />
        <BathDismantling />
        <WcDismantling />
        <LoftDismantling />
        <FloorDismantling />
        <WallDismantling />
      </SimpleSegment>      
    </div>
  );
};

export default Dismantling;
