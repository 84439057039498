export const SET_EXTERNAL_FRAMES = 'SET_EXTERNAL_FRAMES';
export const SET_INTERNAL_DOORS_FRAMES = 'SET_INTERNAL_DOORS_FRAMES';
export const SET_INTERNAL_DOORS_PAINTING_FRAMES = 'SET_INTERNAL_DOORS_PAINTING_FRAMES';
export const SET_ARMORED_DOORS_FRAMES = 'SET_ARMORED_DOORS_FRAMES';

export const setExternalFramesAction = (newExternalFrames) =>
  dispatch => {
    dispatch({
      type: SET_EXTERNAL_FRAMES,
      payload: newExternalFrames,
    });
  };

export const setInternalDoorsFramesAction = (newInternalDoors) =>
  dispatch => {
    dispatch({
      type: SET_INTERNAL_DOORS_FRAMES,
      payload: newInternalDoors,
    });
  };

export const setInternalDoorsPaintingFramesAction = (internalDoorsPainting) =>
  dispatch => {
    dispatch({
      type: SET_INTERNAL_DOORS_PAINTING_FRAMES,
      payload: internalDoorsPainting,
    });
  };

export const setArmoredDoorsFramesAction = (armoredDoors) =>
  dispatch => {
    dispatch({
      type: SET_ARMORED_DOORS_FRAMES,
      payload: armoredDoors,
    });
  };