import React from 'react'

import WallInsulation from './WallInsulation';
import Waterproofing from './Waterproofing';
import ThermalInsulation from './ThermalInsulation';
import InternalFloorInsulation from './InternalFloorInsulation';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Insulation = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section insulation ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Μονώσεις'
          headerClick={ () => { headerClick(); } }
        />

        <WallInsulation />
        <Waterproofing />
        <ThermalInsulation />
        <InternalFloorInsulation />
      </SimpleSegment>
    </div>
  );
};

export default Insulation;
