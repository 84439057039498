import React from 'react'
import { Label, Input } from 'semantic-ui-react'

const SimpleInput = ({ label, value, onChange, placeholder, display, type }) => {
  const displayInput = display || true;
  const inputType = type || 'text';

  const inputValue = inputType === 'number' && value === '' ? 0 : Number(value) || 0;

  const cleanLeadingZero = (el) => {
    let value = String(el.value).replace(/^0+/, '');
    if (String(value).includes('.') && String(value).indexOf('.') === 0) {
      value = '0' + value;
    }

    el.value = value;
  }

  const inputChange = (e) => {
    cleanLeadingZero(e.target);
    onChange(e);
  };

  return (
    <div style={{ padding: "5px", display: displayInput ? "block" : "none" }}>
      <Label pointing="right"> { label } </Label>
      <Input
        placeholder={ placeholder || '' }
        value={ inputValue }
        onChange={ (e) => { inputChange(e) } }
        type={ inputType }
        min='0'
        step="any"
      />
    </div>
  );
};

export default SimpleInput;
