import React from 'react';
import { roundNumber } from '../helpers/utils';

export const getSummaryTrs = (title, sumTitle, cost, all, showOnlySum) => {
  const showSumAlways = true;
  const tdNumber = 2;

  const trs = [];
  if (!showOnlySum) {
    trs.push((
      <tr
        key={ title }
        style={{
          display: all > 0 ? 'table-row' : 'none',
          background: '#e8e8e8',
          fontSize: '1.1em',
          borderTop: '2px solid #e8af30',
          fontWeight: 'bold',
        }}>
        <td
          colSpan={ tdNumber }
          style={{
            textAlign: 'left',
            padding: '8px',
          }}>
          { title }
        </td>
      </tr>
    ));
  }

  let displayCounter = 0;
  if (!showOnlySum) {
    for (let [key, value] of Object.entries(cost)) {
      if (value.display) {
        displayCounter += 1;
      }
  
      trs.push((
        <tr
          key={ key }
          style={{
            display: value.display ? 'table-row' : 'none',
            background: 'rgb(251, 251, 251)',
          }}>
          <td
            style={{
              textAlign: 'left',
              padding: '8px',
            }}
          > { value.text } </td>
          <td
            style={{
              textAlign: 'center',
              padding: '8px',
            }}
          > { roundNumber(value.cost) } € </td>
        </tr>
      ));
    }
  }

  if (showOnlySum || showSumAlways || displayCounter > 1) {
    const fontWeight = showOnlySum ? 'normal' : 'bold';
    const borderTop = showOnlySum ? '0' : '1px solid black';

    trs.push((
      <tr
        key={ sumTitle }
        style={{
          display: all > 0 ? 'table-row' : 'none',
          borderTop: borderTop,
          fontWeight: fontWeight,
        }}>
        <td
          colSpan={ tdNumber - 1 }
          style={{
            textAlign: 'left',
            padding: '8px',
          }}>
          { sumTitle }
        </td>
        <td
          style={{
            textAlign: 'center',
            padding: '8px',
          }}
        >
          { roundNumber(all) } €
        </td>
      </tr>
    ));
  }

  return trs;
};


export const getSummaryTrsWithoutSum = (cost) => {
  const trs = [];
  for (let [key, value] of Object.entries(cost)) {
    trs.push((
      <tr
        key={ key }
        style={{
          display: value.display ? 'table-row' : 'none',
          background: 'rgb(251, 251, 251)',
        }}>
        <td
          style={{
            textAlign: 'left',
            padding: '8px',
          }}
        > { value.text } </td>
        <td
          style={{
            textAlign: 'center',
            padding: '8px',
          }}
        > { roundNumber(value.cost) } € </td>
      </tr>
    ));
  }

  return trs;
};

export const allDataSimpleRow = (text, value) => {
  return (
    <tr style={{
      display: 'table-row',
      background: 'rgb(251, 251, 251)'
    }}>
      <td style={{
        textAlign: 'left',
        padding: '8px'
      }}>
        { text }
      </td>
      <td style={{
        textAlign: 'center',
        padding: '8px'
      }}>
        { value }
      </td>
    </tr>
  );
};

export const allDataHeadRow = (text) => {
  return (
    <tr style={{
      display: 'table-row',
      background: 'rgb(251, 251, 251)',
      borderTop: '1px solid black',
      borderBottom: '1px solid black',
      fontWeight: 'bold',
    }}>
      <td style={{
        textAlign: 'left',
        padding: '8px'
      }} colSpan="2"> { text } </td>
    </tr>
  );
};
