import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setNewInternalMasonryAction } from '../../../redux/actions/action-masonry';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import Masonry from '../../../helpers/masonry';

const masonryTypeOptions = [
  { text: 'Σύστημα τοιχοποιίας με γυψοσανίδα 1+1', value: 'plasterboard1-1' },
  { text: 'Σύστημα τοιχοποιίας με γυψοσανίδα 2+2', value: 'plasterboard2-2' },
  { text: 'Τοιχοποιία με συμβατικό τούβλο ή YTOΝG πάχους 10cm', value: 'ytong' },
];

class InternalMasonry extends Component {
  onNewMasonryChange(value) {
    const { newInternal } = this.props.masonry;
    newInternal.value = value;

    this.props.setNewInternalMasonryAction(newInternal);
  }

  onNewInternalNumberChange(event) {
    const { newInternal } = this.props.masonry;
    const currentNumber = Number(newInternal.number);
    const newNumber = Number(event.target.value);

    if (newNumber > currentNumber) {
      const diff = newNumber - currentNumber;
      for (let i = 0; i < diff; i++) {
        newInternal.internalMasonries.push(new Masonry(0, 'plasterboard1-1'));
      }
    } else {
      const diff = currentNumber - newNumber;
      for (let i = 0; i < diff; i++) {
        newInternal.internalMasonries.pop();
      }
    }

    newInternal.number = newNumber;
    this.props.setNewInternalMasonryAction(newInternal);
  }

  onNewMasonrySurfaceChange(event, idx) {
    const { newInternal } = this.props.masonry;

    if (newInternal.internalMasonries.length > idx) {
      newInternal.internalMasonries[idx].surface = event.target.value;
      this.props.setNewInternalMasonryAction(newInternal);
    }
  }

  onNewMasonryTypeChange(value, idx) {
    const { newInternal } = this.props.masonry;

    if (newInternal.internalMasonries.length > idx) {
      newInternal.internalMasonries[idx].type = value;
      this.props.setNewInternalMasonryAction(newInternal);
    }
  }

  render() {
    const { newInternal } = this.props.masonry;

    const internalMasonries = [];
    newInternal.internalMasonries.forEach((masonry, idx) => {
      internalMasonries.push(
        <Fragment key={idx}>
          <h3> Εσωτερική τοιχοποιία {idx + 1} </h3>
          <SimpleInput
            label='Εμβαδόν τοιχοποιίας (σε τ.μ.)'
            placeholder='Εμβαδόν τοιχοποιίας (σε τ.μ.)'
            value={ masonry.surface }
            onChange={ (e) => { this.onNewMasonrySurfaceChange(e, idx) } }
            type="number"
          />

          <SimpleSelect
            label="Είδος τοιχοποιίας"
            placeholder="Είδος τοιχοποιίας"
            options={ masonryTypeOptions }
            value={ masonry.type }
            onChange={ (value) => { this.onNewMasonryTypeChange(value, idx) } }
          />
        </Fragment>
      );
    });

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Ανέγερση νέας εσωτερικής τοιχοποιίας'
        />

        <SimpleToggle
          label='Νέα εσωτερική τοιχοποιία'
          onChange={ (value) => { this.onNewMasonryChange(value); }}
          value={ newInternal.value }
        />

        { newInternal.value &&
          <Fragment>
            <SimpleInput
              label='Αριθμός τοίχων'
              placeholder='Αριθμός τοίχων'
              value={ newInternal.number }
              onChange={ (e) => { this.onNewInternalNumberChange(e) } }
              type="number"
            />

            { internalMasonries }
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewInternalMasonryAction: (value) => dispatch(setNewInternalMasonryAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalMasonry);
