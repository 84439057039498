import React from 'react';
import { connect } from 'react-redux';
import { setKitchenDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const dismantlingOptions = [
  { text: 'Έπιπλο', value: 'furniture' },
  { text: 'Κτιστή', value: 'built' },
];

class KitchenDismantling extends React.Component {
  onKitchenDismantlingChange(value) {
    const { kitchen } = this.props.dismantling;
    kitchen.value = value;

    this.props.setKitchenDismantlingAction(kitchen);
  }

  onKitchenTypeChange(value) {
    const { kitchen } = this.props.dismantling;
    kitchen.type = value;

    this.props.setKitchenDismantlingAction(kitchen);
  }
  
  render() {
    const { kitchen } = this.props.dismantling;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση Κουζίνας'
        />
        
        <SimpleToggle
          label='Αποξήλωση Κουζίνας'
          onChange={ (value) => { this.onKitchenDismantlingChange(value); }}
          value={ kitchen.value }
        />

        { kitchen.value &&
          <SimpleSelect
            label="Τύπος Κουζίνας"
            placeholder="Τύπος Κουζίνας"
            options={ dismantlingOptions }
            value={ kitchen.type || '' }
            onChange={ (value) => { this.onKitchenTypeChange(value) } }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setKitchenDismantlingAction: (value) => dispatch(setKitchenDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(KitchenDismantling);
