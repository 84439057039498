import React from 'react'

import BathInstallation from './BathInstallation';
import WcInstallation from './WcInstallation';
import BathWcCoatings from './BathWcCoatings';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const BathroomWc = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section bathroom-wc ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Μπάνιο / WC'
          headerClick={ () => { headerClick(); } }
        />

        <BathInstallation />
        <WcInstallation />
        <BathWcCoatings />
      </SimpleSegment>
    </div>
  );
};

export default BathroomWc;
