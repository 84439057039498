import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { allDataHeadRow, allDataSimpleRow } from '../../../helpers/react-utils';

class PreliminaryQuestionsAllData extends React.Component {
  render() {
    const result = this.props.preliminaryQuestions;

    let floor = '2ος όροφος';
    if (result.floor === 0) {
      floor = 'Ισόγειο';
    } else if (result.floor === 0.5) {
      floor = 'Ημι-όροφος';
    } else if (result.floor === 1) {
      floor = '1ος όροφος';
    } else if (result.floor === 2) {
      floor = '2ος όροφος';
    } else if (result.floor === 3) {
      floor = '3ος όροφος';
    } else if (result.floor === 4) {
      floor = '4ος όροφος';
    } else if (result.floor === 5) {
      floor = '5ος όροφος';
    } else if (result.floor === 6) {
      floor = '6ος όροφος';
    } else if (result.floor === 7) {
      floor = '7ος όροφος και πάνω';
    }

    let rubbleRemoval = 'Ασανσέρ';
    if (result.rubbleRemoval === 'elevator') {
      rubbleRemoval = 'Ασανσέρ';
    } else if (result.rubbleRemoval === 'stairs') {
      rubbleRemoval = 'Κλιμακοστάσιο';
    } else if (result.rubbleRemoval === 'mechanical-elevator') {
      rubbleRemoval = 'Μηχανικό μέσο (ανεβατόρι)';
    } else if (result.rubbleRemoval === 'hopper') {
      rubbleRemoval = 'Χοάνη';
    } else if (result.rubbleRemoval === 'truck') {
      rubbleRemoval = 'Καλαθοφόρο όχημα';
    }

    return (
      <Fragment>
        { allDataHeadRow('Προκαταρτικές Ερωτήσεις') }
        { allDataSimpleRow('Όροφος', floor) }
        { allDataSimpleRow('Κουζίνες', result.rooms.kitchen) }
        { allDataSimpleRow('Σαλόνια', result.rooms.livingRoom) }
        { allDataSimpleRow('Υπνοδωμάτια', result.rooms.bedroom) }
        { allDataSimpleRow('Σαλοκουζίνες', result.rooms.kitchenLivingRoom) }
        { allDataSimpleRow('Μπάνια', result.rooms.bathroom) }
        { allDataSimpleRow('WC', result.rooms.wc) }
        { allDataSimpleRow('Αποθήκες', result.rooms.warehouse) }
        { allDataSimpleRow('Μπαλκόνια', result.rooms.balcony) }
        { allDataSimpleRow('Λοιπές πληροφορίες', result.rooms.info) }
        { allDataSimpleRow('Επιφάνεια κατοικίας', result.surface) }
        { allDataSimpleRow('Τρόπος απομάκρυνσης μπάζων', rubbleRemoval) }
        { result.region.insideCity && allDataSimpleRow('Εντός αστικού ιστού', 'Ναι') }
        {
          !result.region.insideCity &&
          allDataSimpleRow('Απόσταση από το κέντρο', result.region.distance || 0)
        }
      </Fragment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(PreliminaryQuestionsAllData);
