import { CONSTANT_VALUES } from '../constants';

const { wallCladding } = CONSTANT_VALUES;

export const defaultWallCladding = {
  wallCladding: {
    value: false,
    surface: 0,
    type: 'brick', // Could be brick, vinyl-film, painting
  },
  wallCladdingCost: wallCladding,
};
