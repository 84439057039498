import React from 'react';
import { connect } from 'react-redux';
import SimpleInput from '../../simple-components/SimpleInput';
import { setSurfaceAction } from '../../../redux/actions/action-preliminary-questions';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class Surface extends React.Component {
  onChange(event) {
    this.props.setSurfaceAction(event.target.value);
  }

  render() {
    const { surface } = this.props.preliminaryQuestions;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Εμβαδόν Κατοικίας'
        />

        <SimpleInput
          label="Επιφάνεια κατοικίας (σε τ.μ)"
          placeholder="Συνολικά τ.μ. σπιτιού"
          value={ surface }
          onChange={ (e) => { this.onChange(e) } }
          type="number"
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setSurfaceAction: (value) => dispatch(setSurfaceAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Surface);
