import React from 'react';
import { connect } from 'react-redux';
import { setExternalFramesDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class ExternalFramesDismantling extends React.Component {
  onExternalFramesChange(value) {
    const { externalFrames } = this.props.dismantling;
    externalFrames.value = value;

    this.props.setExternalFramesDismantlingAction(externalFrames);
  }

  onMoreThanThresholdChange(value) {
    const { externalFrames } = this.props.dismantling;
    externalFrames.moreThanThreshold = value;

    this.props.setExternalFramesDismantlingAction(externalFrames);
  }

  render() {
    const { externalFrames } = this.props.dismantling;
    const moreThanMessage = `Περισσότερα από ${ externalFrames.threshold } τεμάχια`;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση Εξωτερικών Κουφωμάτων'
        />

        <SimpleToggle
          label='Αποξήλωση Εξωτερικών Κουφωμάτων'
          onChange={ (value) => { this.onExternalFramesChange(value); }}
          value={ externalFrames.value }
        />

        { externalFrames.value &&
          <SimpleToggle
            label={ moreThanMessage }
            onChange={ (value) => { this.onMoreThanThresholdChange(value); }}
            value={ externalFrames.moreThanThreshold }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setExternalFramesDismantlingAction: (value) => dispatch(setExternalFramesDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalFramesDismantling);
