export const insulation = {
  wallInsulation: {
    externalCostPerM2: 40,
    internalCostPerM2: 25,
  },
  waterproofing: {
    cementitiousCostPerM2: 3,
    asphaltCostPerM2: 10,
    epdmCostPerM2: 15,
    calcedSurfaceCoefficient: 1.15,
  },
  thermalInsulationPerM2: 25,
  internalFloorInsulationPerM2: 25,
};
