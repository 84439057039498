import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_OUTDOOR_TILE_FLOOR, SET_INDOOR_FLOOR,
  SET_MARBLE_AND_MOSAIC_RUBBING_FLOOR, SET_WOODEN_RUBBING_FLOOR,
  SET_MARBLE_FEET_FLOOR,
} from '../actions/action-floors';

const {
  outdoorTile, indoorFloor, marbleAndMosaicRubbing,
  woodenRubbing, marbleFeet, floorsCost,
} = defaultValues.floors;

export default (state = {
  outdoorTile, indoorFloor, marbleAndMosaicRubbing,
  woodenRubbing, marbleFeet, floorsCost,
}, action) => {
  switch (action.type) {
    case SET_OUTDOOR_TILE_FLOOR:
      return copyObject(state, {
        outdoorTile: action.payload,
      });
    case SET_INDOOR_FLOOR:
      return copyObject(state, {
        indoorFloor: action.payload,
      });
    case SET_MARBLE_AND_MOSAIC_RUBBING_FLOOR:
      return copyObject(state, {
        marbleAndMosaicRubbing: action.payload,
      });
    case SET_WOODEN_RUBBING_FLOOR:
      return copyObject(state, {
        woodenRubbing: action.payload,
      });
    case SET_MARBLE_FEET_FLOOR:
      return copyObject(state, {
        marbleFeet: action.payload,
      });
    default:
      return state;
  }
}
 