export const SET_OUTDOOR_TILE_FLOOR = 'SET_OUTDOOR_TILE_FLOOR';
export const SET_INDOOR_FLOOR = 'SET_INDOOR_FLOOR';
export const SET_MARBLE_AND_MOSAIC_RUBBING_FLOOR = 'SET_MARBLE_AND_MOSAIC_RUBBING_FLOOR';
export const SET_WOODEN_RUBBING_FLOOR = 'SET_WOODEN_RUBBING_FLOOR';
export const SET_MARBLE_FEET_FLOOR = 'SET_MARBLE_FEET_FLOOR';

export const setOutdoorTileFloorAction = (outdoorTile) =>
  dispatch => {
    dispatch({
      type: SET_OUTDOOR_TILE_FLOOR,
      payload: outdoorTile,
    });
  };

export const setIndoorFloorAction = (indoorFloor) =>
  dispatch => {
    dispatch({
      type: SET_INDOOR_FLOOR,
      payload: indoorFloor,
    });
  };

export const setMarbleAndMosaicRubbingFloorAction = (marbleAndMosaicRubbing) =>
  dispatch => {
    dispatch({
      type: SET_MARBLE_AND_MOSAIC_RUBBING_FLOOR,
      payload: marbleAndMosaicRubbing,
    });
  };

export const setWoodenRubbingFloorAction = (woodenRubbing) =>
  dispatch => {
    dispatch({
      type: SET_WOODEN_RUBBING_FLOOR,
      payload: woodenRubbing,
    });
  };

export const setMarbleFeetFloorAction = (marbleFeet) =>
  dispatch => {
    dispatch({
      type: SET_MARBLE_FEET_FLOOR,
      payload: marbleFeet,
    });
  };
