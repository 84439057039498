import React from 'react';
import { connect } from 'react-redux';
import { setBathDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class BathDismantling extends React.Component {
  onBathDismantlingChange(value) {
    const { bath } = this.props.dismantling;
    bath.value = value;

    this.props.setBathDismantlingAction(bath);
  }

  render() {
    const { bath } = this.props.dismantling;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση Μπάνιου'
        />

        <SimpleToggle
          label='Αποξήλωση Μπάνιου'
          onChange={ (value) => { this.onBathDismantlingChange(value); }}
          value={ bath.value }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setBathDismantlingAction: (value) => dispatch(setBathDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(BathDismantling);
