export const dismantling = {
  rubbleRemovalCost: {
    elevator: 0,
    stairs: 150,
    mechanicalElevator: 100,
    hopper: 150,
    truck: 300,
  },
  kitchenDismantlingCost: {
    furniture: 50,
    built: 120,
  },
  internalFrames: {
    threshold: 10,
    cost: {
      lessThanThreshold: 50,
      moreThanThreshold: 100,
    }
  },
  externalFrames: {
    threshold: 10,
    cost: {
      lessThanThreshold: 70,
      moreThanThreshold: 150,
    }
  },
  bath: {
    eachBath: {
      cost: 200,
      bucket: 0.3,
    },
  },
  wc: {
    eachWc: {
      cost: 120,
      bucket: 0.2,
    },
  },
  loft: {
    eachLoft: {
      cost: 120,
      bucket: 0.2,
    },
  },
  floor: {
    perM2: {
      bucket: 0.02,
      cost: {
        wooden: 5,
        mosaic: 7,
        tile: 3.5,
        marble: 3.5,
      },
    },
  },
  wall: {
    eachWall: {
      cost: 200,
      bucket: 0.4,
    },
  },
};
