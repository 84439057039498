import React from 'react';

import Floor from './Floor';
import Rooms from './Rooms';
import Surface from './Surface';
import RubbleRemoval from './RubbleRemoval';
import Region from './Region';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { HEADER, SEGMENT } from '../../../config/constants';

const PreliminaryQuestions = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section preliminary-questions ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Προκαταρτικές Ερωτήσεις'
          headerClick={ () => { headerClick(); } }
        />

        <Floor />
        <Rooms />
        <Surface />
        <RubbleRemoval />
        <Region />
      </SimpleSegment>
    </div>
  );
};

export default PreliminaryQuestions;
