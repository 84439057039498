import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setWallInsulationAction } from '../../../redux/actions/action-insulation';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMultiSelect from '../../simple-components/SimpleMultiSelect';

const wallInsulationTypeOptions = [
  { text: 'Σύστημα εξωτερικής θερμομόνωσης με EPS πάχους 9cm', value: 'external' },
  { text: 'Εσωτερική θερμομόνωση (γυψοσανίδα / ορυκτοβάμβακα πάχους 7cm)', value: 'internal' },
];

class WallInsulation extends Component {
  onWallInsulationChange(value) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.value = value;

    this.props.setWallInsulationAction(wallInsulation);
  }

  onWallInsulationSameTypeChange(value) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.sameInsulationType = value;

    this.props.setWallInsulationAction(wallInsulation);
  }

  onWallInsulationTypesChange(value) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.types = value;

    if (value.includes('internal')) {
      wallInsulation.internalSurface = 0;
    }

    if (value.includes('external')) {
      wallInsulation.externalSurface = 0;
    }

    this.props.setWallInsulationAction(wallInsulation);
  }

  onWallInsulationSurfaceChange(event) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.surface = event.target.value;

    this.props.setWallInsulationAction(wallInsulation);
  }

  onWallInsulationInternalSurfaceChange(event) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.internalSurface = event.target.value;

    this.props.setWallInsulationAction(wallInsulation);
  }

  onWallInsulationExternalSurfaceChange(event) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.externalSurface = event.target.value;

    this.props.setWallInsulationAction(wallInsulation);
  }

  onWallInsulationTypeChange(value) {
    const { wallInsulation } = this.props.insulation;
    wallInsulation.type = value;

    this.props.setWallInsulationAction(wallInsulation);
  }

  render() {
    const { wallInsulation } = this.props.insulation;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Θερμομονώσεις τοίχων'
        />

        <SimpleToggle
          label='Μόνωση τοίχων'
          onChange={ (value) => { this.onWallInsulationChange(value); }}
          value={ wallInsulation.value }
        />

        { wallInsulation.value &&
          <SimpleToggle
            label='Ίδιος τρόπος θερμομόνωσης στο σύνολο των τοίχων'
            onChange={ (value) => { this.onWallInsulationSameTypeChange(value); }}
            value={ wallInsulation.sameInsulationType }
          />
        }

        { wallInsulation.value && !wallInsulation.sameInsulationType &&
          <SimpleMultiSelect
            label="Τύπος μόνωσης"
            placeholder="Τύπος μόνωσης"
            options={ wallInsulationTypeOptions }
            values={ wallInsulation.types }
            onChange={ (values) => { this.onWallInsulationTypesChange(values) } }
          />
        }

        { wallInsulation.value && !wallInsulation.sameInsulationType && wallInsulation.types.includes('internal') &&
          <SimpleInput
            label='Εμβαδόν εσωτερικής μόνωσης (σε τ.μ.)'
            placeholder='Εμβαδόν εσωτερικής μόνωσης (σε τ.μ.)'
            value={ wallInsulation.internalSurface }
            onChange={ (e) => { this.onWallInsulationInternalSurfaceChange(e) } }
            type="number"
          />
        }

        { wallInsulation.value && !wallInsulation.sameInsulationType && wallInsulation.types.includes('external') &&
          <SimpleInput
            label='Εμβαδόν εξωτερικής μόνωσης (σε τ.μ.)'
            placeholder='Εμβαδόν εξωτερικής μόνωσης (σε τ.μ.)'
            value={ wallInsulation.externalSurface }
            onChange={ (e) => { this.onWallInsulationExternalSurfaceChange(e) } }
            type="number"
          />
        }

        { wallInsulation.value && wallInsulation.sameInsulationType &&
          <Fragment>
            <SimpleSelect
              label="Τύπος μόνωσης"
              placeholder="Τύπος μόνωσης"
              options={ wallInsulationTypeOptions }
              value={ wallInsulation.type }
              onChange={ (value) => { this.onWallInsulationTypeChange(value) } }
            />

            <SimpleInput
              label='Εμβαδόν μόνωσης (σε τ.μ.)'
              placeholder='Εμβαδόν μόνωσης (σε τ.μ.)'
              value={ wallInsulation.surface }
              onChange={ (e) => { this.onWallInsulationSurfaceChange(e) } }
              type="number"
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWallInsulationAction: (value) => dispatch(setWallInsulationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WallInsulation);
