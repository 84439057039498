export const SET_INTERNAL_WALLS_PAINTS = 'SET_INTERNAL_WALLS_PAINTS';
export const SET_INTERNAL_ROOFS_PAINTS = 'SET_INTERNAL_ROOFS_PAINTS';
export const SET_EXTERIOR_COLORS_PAINTS = 'SET_EXTERIOR_COLORS_PAINTS';
export const SET_RAILING_PAINT_PAINTS = 'SET_RAILING_PAINT_PAINTS';

export const setInternalWallsPaintsAction = (internalWallsPaints) =>
  dispatch => {
    dispatch({
      type: SET_INTERNAL_WALLS_PAINTS,
      payload: internalWallsPaints,
    });
  };

export const setInternalRoofsPaintsAction = (internalRoofs) =>
  dispatch => {
    dispatch({
      type: SET_INTERNAL_ROOFS_PAINTS,
      payload: internalRoofs,
    });
  };

export const setExteriorColorsPaintsAction = (exteriorColors) =>
  dispatch => {
    dispatch({
      type: SET_EXTERIOR_COLORS_PAINTS,
      payload: exteriorColors,
    });
  };

export const setRailingPaintPaintsAction = (railingPaint) =>
  dispatch => {
    dispatch({
      type: SET_RAILING_PAINT_PAINTS,
      payload: railingPaint,
    });
  };
