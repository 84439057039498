import { CONSTANT_VALUES } from '../constants';

const { plaster } = CONSTANT_VALUES;

export const defaultPlaster = {
  plaster: {
    value: false,
    rooms: {
      livingRoom: {
        value: false,
        number: 1,
      },
      bedroom: {
        value: false,
        number: 1,
      },
      corridor: {
        value: false,
        number: 1,
      },
      kitchen: {
        value: false,
        number: 1,
      },
    }
  },
  plasterCost: plaster,
};
