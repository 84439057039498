export const framesCalc = (state) => {
  const {
    newExternalFrames, internalDoors, internalDoorsPainting,
    armoredDoors, framesCost,
  } = state.frames;
  
  let externalFramesCost = 0;
  let internalDoorsCost = 0;
  let internalDoorsPaintingCost = 0;
  let armoredDoorsCost = 0;

  if (newExternalFrames.value) {
    newExternalFrames.frames.forEach((frame) => {
      externalFramesCost += frame.calcCost(framesCost);
    }); 
  }

  if (internalDoors.value) {
    const {
      openingLaminateCostPerDoor,
      openingVeneerCostPerDoor,
      openingLacquerCostPerDoor,
      slidingLaminateCostPerDoor,
      slidingVeneerCostPerDoor,
      slidingLacquerCostPerDoor,
      doubleSlidingLaminateCostPerDoor,
      doubleSlidingVeneerCostPerDoor,
      doubleSlidingLacquerCostPerDoor,
      splitLaminateCostPerDoor,
      splitVeneerCostPerDoor,
      splitLacquerCostPerDoor,
      loftLaminateCostPerDoor,
      loftVeneerCostPerDoor,
      loftLacquerCostPerDoor,
    } = framesCost.internalDoors;

    internalDoors.types.forEach((doorType) => {
      const { type, number } = doorType;
      let costPerDoor = 0;
      
      if (internalDoors.material === 'laminate') {
        if (type === 'opening') {
          costPerDoor = openingLaminateCostPerDoor;
        } else if (type === 'sliding') {
          costPerDoor = slidingLaminateCostPerDoor;
        } else if (type === 'double-sliding') {
          costPerDoor = doubleSlidingLaminateCostPerDoor;
        } else if (type === 'split') {
          costPerDoor = splitLaminateCostPerDoor;
        } else if (type === 'loft') {
          costPerDoor = loftLaminateCostPerDoor;
        }
      }

      if (internalDoors.material === 'veneer') {
        if (type === 'opening') {
          costPerDoor = openingVeneerCostPerDoor;
        } else if (type === 'sliding') {
          costPerDoor = slidingVeneerCostPerDoor;
        } else if (type === 'double-sliding') {
          costPerDoor = doubleSlidingVeneerCostPerDoor;
        } else if (type === 'split') {
          costPerDoor = splitVeneerCostPerDoor;
        } else if (type === 'loft') {
          costPerDoor = loftVeneerCostPerDoor;
        }
      }

      if (internalDoors.material === 'lacquer') {
        if (type === 'opening') {
          costPerDoor = openingLacquerCostPerDoor;
        } else if (type === 'sliding') {
          costPerDoor = slidingLacquerCostPerDoor;
        } else if (type === 'double-sliding') {
          costPerDoor = doubleSlidingLacquerCostPerDoor;
        } else if (type === 'split') {
          costPerDoor = splitLacquerCostPerDoor;
        } else if (type === 'loft') {
          costPerDoor = loftLacquerCostPerDoor;
        }
      }
      
      internalDoorsCost += costPerDoor * number;
    });
  }

  if (internalDoorsPainting.value) {
    const {
      shortSimpleCostPerDoor,
      tallSimpleCostPerDoor,
      shortFlatbedCostPerDoor,
      tallFlatbedCostPerDoor,
    } = framesCost.internalDoorsPaintingCost;

    const { number, doorHeight, doorType } = internalDoorsPainting;

    let costPerDoor = 0;
    if (doorHeight === 'short-door' && doorType === 'simple-door') {
      costPerDoor = shortSimpleCostPerDoor;
    } else if (doorHeight === 'tall-door' && doorType === 'simple-door') {
      costPerDoor = tallSimpleCostPerDoor;
    } else if (doorHeight === 'short-door' && doorType === 'flatbed-door') {
      costPerDoor = shortFlatbedCostPerDoor;
    } else if (doorHeight === 'tall-door' && doorType === 'flatbed-door') {
      costPerDoor = tallFlatbedCostPerDoor;
    }

    internalDoorsPaintingCost = costPerDoor * number;
  }

  if (armoredDoors.value) {
    const { armoredDoorsCostPerDoor } = framesCost;
    const { number } = armoredDoors;

    armoredDoorsCost = armoredDoorsCostPerDoor * number;
  }

  const cost = {
    externalFramesCost: {
      display: externalFramesCost > 0,
      text: 'Κόστος τοποθέτησης νέων εξωτερικών κουφωμάτων',
      cost: externalFramesCost > 0 ? externalFramesCost : 0,
    },
    internalDoorsCost: {
      display: internalDoorsCost > 0,
      text: 'Κόστος τοποθέτησης νέων εσωτερικών πορτών',
      cost: internalDoorsCost > 0 ? internalDoorsCost : 0,

    },
    internalDoorsPaintingCost: {
      display: internalDoorsPaintingCost > 0,
      text: 'Κόστος βαφής εσωτερικών πορτών',
      cost: internalDoorsPaintingCost > 0 ? internalDoorsPaintingCost : 0,

    },
    armoredDoorsCost: {
      display: armoredDoorsCost > 0,
      text: 'Κόστος θωρακισμένων πορτών',
      cost: armoredDoorsCost > 0 ? armoredDoorsCost : 0,
    },
  };

  const all = cost.externalFramesCost.cost
    + cost.internalDoorsCost.cost
    + cost.internalDoorsPaintingCost.cost
    + cost.armoredDoorsCost.cost;

  const { frames } = newExternalFrames;

  // Calc sumTitleColorMaterial
  let sumTitleColorMaterial = '';
  if (frames.length > 0) {
    const calcColorText = (color) => {
      if (color === 'white') {
        return 'λευκού χρώματος'
      } else if (color === 'ral') {
        return 'σε χρώμα ral';
      } else if (color === 'efecto-madera') {
        return 'σε απομίμηση ξύλου';
      }

      return 'λευκού χρώματος';
    };

    const calcMaterialText = (material) => {
      if (material === 'pvc') {
        return 'PVC';
      } else if (material === 'aluminium') {
        return 'αλουμινίου';
      }

      return 'PVC';
    }

    let sameColorMaterial = true;
    const color = frames[0].color;
    const material = frames[0].material;

    frames.forEach((frame) => {
      if (frame.color !== color || frame.material !== material) {
        sameColorMaterial = false;
      }
    });

    if (sameColorMaterial) {
      const colorText = calcColorText(color);
      const materialText = calcMaterialText(material);

      sumTitleColorMaterial = ` ${materialText} ${colorText}`;
    }
  }

  const title = 'Κουφώματα';
  const sumTitle = 'Συνολικό κόστος κατασκευής και τοποθέτησης εξωτερικών κουφωμάτων' + sumTitleColorMaterial;

  return { title, sumTitle, cost, all };
}