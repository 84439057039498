import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setNewAirConditionAction } from '../../../redux/actions/action-cooling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

const acTypeOptions = [
  { text: 'Εγκατάσταση', value: 'install' },
  { text: 'Προεγκατάσταση', value: 'preinstall' },
  { text: 'Προεγκατάσταση και Εγκατάσταση', value: 'preinstall-install' },
  { text: 'Συντήρηση', value: 'maintenance' },
];

class AirCondition extends Component {
  onNewACChange(value) {
    const { newAC } = this.props.cooling;
    newAC.value = value;

    this.props.setNewAirConditionAction(newAC);
  }

  onNewACNumberChange(event) {
    const { newAC } = this.props.cooling;
    newAC.number = event.target.value;

    this.props.setNewAirConditionAction(newAC);
  }

  onNewACTypeChange(value) {
    const { newAC } = this.props.cooling;
    newAC.type = value;

    this.props.setNewAirConditionAction(newAC);
  }

  render() {
    const { newSource } = this.props.heating;
    const { newAC } = this.props.cooling;

    const newSourceAC = newSource.value === 'ac';

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className="sub-segment"
      >
        
        { newSourceAC &&
          <SimpleMessage
            icon="info"
            header="Πληροφορίες"
            content="Τα δεδομένα έχουν ήδη καταχωρηθεί στην κατηγορία 'Εργασίες θέρμανσης'"
          />
        }

        { !newSourceAC &&
          <Fragment>
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Νέο air condition'
            />

            <SimpleToggle
              label='Νέο air condition'
              onChange={ (value) => { this.onNewACChange(value); }}
              value={ newAC.value }
            />

            { newAC.value &&
              <Fragment>
                <SimpleInput
                  label='Αριθμός μηχανημάτων'
                  placeholder='Αριθμός μηχανημάτων'
                  value={ newAC.number }
                  onChange={ (e) => { this.onNewACNumberChange(e) } }
                  type="number"
                />

                <SimpleSelect
                  label="Τρόπος εγκατάστασης"
                  placeholder="Τρόπος εγκατάστασης"
                  options={ acTypeOptions }
                  value={ newAC.type }
                  onChange={ (value) => { this.onNewACTypeChange(value) } }
                />

                <SimpleMessage
                  icon="info"
                  header="Πληροφορίες"
                  content="Στην τιμή δεν θα περιλαμβάνεται η προμήθεια
                    του κλιματιστικού μηχανήματος"
                />
              </Fragment>
            }

            </Fragment>
          }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewAirConditionAction: (value) => dispatch(setNewAirConditionAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(AirCondition);
