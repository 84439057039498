import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { dismantlingCalc } from '../../../calcs/dismantling-calc';
import { getSummaryTrs } from '../../../helpers/react-utils';

class DismantlingSummary extends React.Component {
  render() {
    const { title, sumTitle, cost, all } = dismantlingCalc(this.props);
    const trs = getSummaryTrs(title, sumTitle, cost, all, this.props.showOnlySum);

    return (
      <Fragment>{ trs }</Fragment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(DismantlingSummary);
