import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setWoodenRubbingFloorAction } from '../../../redux/actions/action-floors';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class WoodenFloorRubbing extends Component {
  onFloorRubbingChange(value) {
    const { woodenRubbing } = this.props.floors;
    woodenRubbing.value = value;

    this.props.setWoodenRubbingFloorAction(woodenRubbing);
  }

  onFloorRubbingSurfaceChange(event) {
    const { woodenRubbing } = this.props.floors;
    woodenRubbing.surface = event.target.value;

    this.props.setWoodenRubbingFloorAction(woodenRubbing);
  }

  render() {
    const { woodenRubbing } = this.props.floors;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Τρίψιμο - γυάλισμα ξύλινου δαπέδου'
        />

        <SimpleToggle
          label='Τρίψιμο - γυάλισμα'
          onChange={ (value) => { this.onFloorRubbingChange(value); }}
          value={ woodenRubbing.value }
        />

        { woodenRubbing.value &&
          <SimpleInput
            label='Εμβαδόν δαπέδου (σε τ.μ.)'
            placeholder='Εμβαδόν δαπέδου (σε τ.μ.)'
            value={ woodenRubbing.surface }
            onChange={ (e) => { this.onFloorRubbingSurfaceChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWoodenRubbingFloorAction: (value) => dispatch(setWoodenRubbingFloorAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WoodenFloorRubbing);
