import React from 'react'

import BathWcInstallation from './BathWcInstallation';
import NewKitchenPosition from './NewKitchenPosition';
import SolarHeaterInstallation from './SolarHeaterInstallation';
import BalconyFaucet from './BalconyFaucet';
import WashingMachineOutOfBath from './WashingMachineOutOfBath';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT, HEADER } from '../../../config/constants';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Plumbing = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section plumbing ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Υδραυλικές Εργασίες'
          headerClick={ () => { headerClick(); } }
        />

        <BathWcInstallation />
        <NewKitchenPosition />
        <SolarHeaterInstallation />
        <BalconyFaucet />
        <WashingMachineOutOfBath />
      </SimpleSegment>
    </div>
  );
};

export default Plumbing;
