import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_PROJECT_DESIGN_AND_MANAGEMENT,
} from '../actions/action-project-design-and-management';

const { projectDesignAndManagement, projectDesignAndManagementCost } = defaultValues.projectDesignAndManagement;

export default (state = {
  projectDesignAndManagement, projectDesignAndManagementCost,
}, action) => {
  switch (action.type) {
    case SET_PROJECT_DESIGN_AND_MANAGEMENT:
      return copyObject(state, {
        projectDesignAndManagement: action.payload,
      });
    default:
      return state;
  }
}
