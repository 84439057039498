import { CONSTANT_VALUES } from '../constants';

const { cooling } = CONSTANT_VALUES;

export const defaultCooling = {
  newAC: {
    value: false,
    type: 'install', // Could be: install, preinstall, preinstall-install, maintenance
    number: 0,
  },
  coolingCost: cooling,
};
