import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setFloorAction } from '../../../redux/actions/action-preliminary-questions';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const floorOptions = [
  { text: 'Ισόγειο', value: 0 },
  { text: 'Ημι-όροφος', value: 0.5 },
  { text: '1ος Όροφος', value: 1 },
  { text: '2ος Όροφος', value: 2 },
  { text: '3ος Όροφος', value: 3 },
  { text: '4ος Όροφος', value: 4 },
  { text: '5ος Όροφος', value: 5 },
  { text: '6ος Όροφος', value: 6 },
  { text: '7ος Όροφος και πάνω', value: 7 },
];

class Floor extends Component {
  onFloorChange(value) {
    this.props.setFloorAction(value);
  }

  render() {
    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">

        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Όροφος Κατοικίας'
        />
        
        <SimpleSelect
          label="Όροφος"
          placeholder="Όροφος"
          options={ floorOptions }
          value={ this.props.preliminaryQuestions.floor || 0 }
          onChange={ (value) => { this.onFloorChange(value) } }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setFloorAction: (value) => dispatch(setFloorAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Floor);
