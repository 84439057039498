import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { setFloorDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleMultiSelect from '../../simple-components/SimpleMultiSelect';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleMessage from '../../simple-components/SimpleMessage';
import { SEGMENT } from '../../../config/constants';

const floorTypeOptions = [
  { text: 'Ξύλινο', value: 'wooden' },
  { text: 'Μωσαϊκό', value: 'mosaic' },
  { text: 'Πλακάκι', value: 'tile' },
  { text: 'Μάρμαρο', value: 'marble' },
];

class FloorDismantling extends React.Component {
  onFloorDismantlingChange(value) {
    const { floor } = this.props.dismantling;
    floor.value = value;

    this.props.setFloorDismantlingAction(floor);
  }

  onFloorTypeChange(floorTypes) {
    const { floor } = this.props.dismantling;
    floor.types = floorTypes;

    this.props.setFloorDismantlingAction(floor);
  }

  onFloorSurfaceChange(event, floorType) {
    const surface = event.target.value;
    const { floor } = this.props.dismantling;
    floor.surface[floorType] = surface;

    this.props.setFloorDismantlingAction(floor);
  }

  render() {
    const { floor } = this.props.dismantling;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση Δαπέδων'
        />
        
        <SimpleToggle
          label='Αποξήλωση Δαπέδων'
          onChange={ (value) => { this.onFloorDismantlingChange(value); }}
          value={ floor.value }
        />

        { floor.value &&
          <SimpleMultiSelect
            label="Τύπος Δαπέδου"
            placeholder="Τύπος Δαπέδου"
            options={ floorTypeOptions }
            values={ floor.types }
            onChange={ (values) => { this.onFloorTypeChange(values) } }
          />
        }

        { floor.value && floor.types.includes('wooden') &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν Ξύλινων Δαπέδων'
              placeholder='Εμβαδόν Ξύλινων Δαπέδων (σε τ.μ.)'
              value={ floor.surface.wooden }
              onChange={ (e) => { this.onFloorSurfaceChange(e, 'wooden') } }
              type="number"
            />

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Είναι υποχρεωτικη η έγχυση γκρο μπετόν στα αντίστοιχα
                m2 για την ισοσταθμία των χώρων"
            />
          </Fragment>
        }

        { floor.value && floor.types.includes('mosaic') &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν Δαπέδων με Μωσαϊκό'
              placeholder='Εμβαδόν Δαπέδων με Μωσαϊκό (σε τ.μ.)'
              value={ floor.surface.mosaic }
              onChange={ (e) => { this.onFloorSurfaceChange(e, 'mosaic') } }
              type="number"
            />

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Είναι υποχρεωτικη η έγχυση γκρο μπετόν στα αντίστοιχα
                m2 για την ισοσταθμία των χώρων"
            />
          </Fragment>
        }

        { floor.value && floor.types.includes('tile') &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν Δαπέδων με Πλακάκι'
              placeholder='Εμβαδόν Δαπέδων με Πλακάκι (σε τ.μ.)'
              value={ floor.surface.tile }
              onChange={ (e) => { this.onFloorSurfaceChange(e, 'tile') } }
              type="number"
            />

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Σε περίπτωση που απαιτηθεί η έγχυση αυτοεπιπεδούμενου υλικού,
                το κόστος θα αυξηθεί κατά 5,00 €/μ2"
            />
          </Fragment>
        }

        { floor.value && floor.types.includes('marble') &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν Μαρμάρινων Δαπέδων'
              placeholder='Εμβαδόν Μαρμάρινων Δαπέδων (σε τ.μ.)'
              value={ floor.surface.marble }
              onChange={ (e) => { this.onFloorSurfaceChange(e, 'marble') } }
              type="number"
            />

            <SimpleMessage
              icon="info"
              header="Πληροφορίες"
              content="Σε περίπτωση που απαιτηθεί η έγχυση αυτοεπιπεδούμενου υλικού,
                το κόστος θα αυξηθεί κατά 5,00 €/μ2"
            />
          </Fragment>
        }

      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setFloorDismantlingAction: (value) => dispatch(setFloorDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(FloorDismantling);
