import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInternalRoofsPaintsAction } from '../../../redux/actions/action-paints';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const paintMaterialOptions = [
  { text: 'Πλαστικό χρώμα', value: 'plastic' },
  { text: 'Υδρόχρωμα', value: 'watercolor' },
];

class InternalRoofs extends Component {
  onInternalRoofsPaintChange(value) {
    const { internalRoofs } = this.props.paints;
    internalRoofs.value = value;

    this.props.setInternalRoofsPaintsAction(internalRoofs);
  }

  onInternalRoofsSpatulatoryChange(value) {
    const { internalRoofs } = this.props.paints;
    internalRoofs.spatulatory = value;

    this.props.setInternalRoofsPaintsAction(internalRoofs);
  }

  onInternalRoofsPaintMaterialChange(value) {
    const { internalRoofs } = this.props.paints;
    internalRoofs.paintMaterial = value;

    this.props.setInternalRoofsPaintsAction(internalRoofs);
  }

  render() {
    const { internalRoofs } = this.props.paints;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Βαφή εσωτερικών οροφών'
        />

        <SimpleToggle
          label='Βαφή εσωτερικών οροφών'
          onChange={ (value) => { this.onInternalRoofsPaintChange(value); }}
          value={ internalRoofs.value }
        />

        { internalRoofs.value &&
          <Fragment>
            <SimpleToggle
              label='Σπατουλαριστές οροφές'
              onChange={ (value) => { this.onInternalRoofsSpatulatoryChange(value); }}
              value={ internalRoofs.spatulatory }
            />

            <SimpleSelect
              label="Υλικό βαφής"
              placeholder="Υλικό βαφής"
              options={ paintMaterialOptions }
              value={ internalRoofs.paintMaterial }
              onChange={ (value) => { this.onInternalRoofsPaintMaterialChange(value) } }
            />

          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setInternalRoofsPaintsAction: (value) => dispatch(setInternalRoofsPaintsAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalRoofs);
