export const paints = {
  wallSurfaces: {
    surfacePerBedroom: 40,
    surfacePerKitchen: 40,
    surfacePerLivingroom: 75,
    surfacePerCorridor: 30,
  },
  internalWalls: {
    spatulatoryCostPerM2: 8,
    simpleCostPerM2: 5,
  },
  internalRoofs: {
    simpleAndPlasticCostPerM2: 5,
    spatulatoryAndPlasticCostPerM2: 9,
    simpleAndWatercolorCostPerM2: 2,
  },
  exteriorColorsCostPerM2: 4,
  railingPaintCostPerM: 10,
};
