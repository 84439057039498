import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_CUSTOM_FIELDS,
} from '../actions/action-custom-fields';

const { customFields } = defaultValues.customFields;

export default (state = { customFields }, action) => {
  switch (action.type) {
    case SET_CUSTOM_FIELDS:
      return copyObject(state, {
        customFields: action.payload,
      });
    default:
      return state;
  }
}
