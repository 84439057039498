export const electrical = {
  houseNeedsCost: {
    socketSupplyPerM2: 4,
    socketInstallationPerM2: 1,
    newLampsPerM2: 1.5,
    newLampsWiring: {
      kitchenSpotNumber: 2,
      livingRoomSpotNumber: 3,
      bedroomSpotNumber: 1,
      kitchenLivingRoomSpotNumber: 4,
      bathSpotNumber: 2,
      wcSpotNumber: 2,
      warehouseSpotNumber: 1,
      balconySpotNumber: 1,
      costPerSpot: 30,
    },
    newPanel: 200,
    newPanelMoving: 150,
    patientNetworksPerSocket: 35,
    strongNetworksPerSocket: 35,
    electricalNetworkExtensionPerM2: 10,
    electricalWorks: {
      newACperAC: 70,
      forKitchenMoving: 400,
      newOilNetwork: 120,
      newGasNetwork: 120,
    },
    magneticContacts: {
      preinstallation: {
        forOneRoom: 200,
        forMoreThanOneRoomPerOtherRoom: 50,
      },
    },
    securitySystems: {
      installation: 500,
    },
    newElectricalDevices: 80,
  },
};
