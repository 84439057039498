import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNewFireplaceAction } from '../../../redux/actions/action-heating';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const fireplaceTypeOptions = [
  { text: 'Απλό', value: 'simple' },
  { text: 'Ενεργειακό', value: 'energy' },
];

class Fireplace extends Component {
  onNewFireplaceChange(value) {
    const { newFireplace } = this.props.heating;
    newFireplace.value = value;

    this.props.setNewFireplaceAction(newFireplace);
  }

  onNewFireplaceTypeChange(value) {
    const { newFireplace } = this.props.heating;
    newFireplace.type = value;

    this.props.setNewFireplaceAction(newFireplace);
  }

  render() {
    const { newFireplace } = this.props.heating;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Νέο τζάκι'
        />

        <SimpleToggle
          label='Νέο τζάκι'
          onChange={ (value) => { this.onNewFireplaceChange(value); }}
          value={ newFireplace.value }
        />

        { newFireplace.value &&
          <SimpleSelect
            label="Τύπος τζακιού"
            placeholder="Τύπος τζακιού"
            options={ fireplaceTypeOptions }
            value={ newFireplace.type }
            onChange={ (value) => { this.onNewFireplaceTypeChange(value) } }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewFireplaceAction: (value) => dispatch(setNewFireplaceAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Fireplace);
