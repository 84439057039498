import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

const installationOptions = [
  { text: '', value: 'nothing' },
  { text: 'Προμήθεια και Τοποθέτηση', value: 'supply-install' },
  { text: 'Μόνο τοποθέτηση', value: 'install' },
];

class SocketSwitcherMaterial extends Component {
  onInstallationChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.socketInstallationType = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Πριζοδιακοπτικό υλικό'
        />
        <SimpleSelect
          label="Τοποθέτηση νέου υλικού"
          placeholder="Τοποθέτηση νέου υλικού"
          options={ installationOptions }
          value={ houseNeeds.socketInstallationType }
          onChange={ (value) => { this.onInstallationChange(value) } }
        />

        <SimpleMessage
          icon="info"
          header="Πληροφορίες"
          content="Στην τιμή περιλαμβάνεται πριζοδιακοπτικό υλικό λευκού χρώματος
          της εταιρείας Legrand/σειρά Valena ή της εταιρείας Schneider
          Electric/σειρά Sedna."
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SocketSwitcherMaterial);
