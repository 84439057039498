import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setInternalFloorInsulationAction } from '../../../redux/actions/action-insulation';
import SimpleToggle from '../../simple-components/SimpleToggle';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class InternalFloorInsulation extends Component {
  onInternalFloorInsulationChange(value) {
    const { internalFloorInsulation } = this.props.insulation;
    internalFloorInsulation.value = value;

    this.props.setInternalFloorInsulationAction(internalFloorInsulation);
  }

  render() {
    const { internalFloorInsulation, thermalInsulation } = this.props.insulation;

    const className = `sub-segment ${ !thermalInsulation.value ? '' : 'hidden-ui' }`;

    return (
      <SimpleSegment
        style={{ display: !thermalInsulation.value ? 'block' : 'none' }}
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ className }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Εσωτερική θερμομόνωση οροφών'
        />

        <SimpleToggle
          label='Εσωτερική θερμομόνωση'
          onChange={ (value) => { this.onInternalFloorInsulationChange(value); }}
          value={ internalFloorInsulation.value }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setInternalFloorInsulationAction: (value) => dispatch(setInternalFloorInsulationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalFloorInsulation);
