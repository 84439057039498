import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setOutdoorTileFloorAction } from '../../../redux/actions/action-floors';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const outdoorTileTypeOptions = [
  { text: 'Πλακίδια (10 euro / m2)', value: 'tile-cat1' },
  { text: 'Πλακίδια (20 euro / m2)', value: 'tile-cat2' },
  { text: 'Πλακίδια (30 euro / m2)', value: 'tile-cat3' },
];

class OutdoorTile extends Component {
  onOutdoorTileChange(value) {
    const { outdoorTile } = this.props.floors;
    outdoorTile.value = value;

    this.props.setOutdoorTileFloorAction(outdoorTile);
  }

  onOutdoorTileSurfaceChange(event) {
    const { outdoorTile } = this.props.floors;
    outdoorTile.surface = event.target.value;

    this.props.setOutdoorTileFloorAction(outdoorTile);
  }

  onOutdoorTileTypeChange(value) {
    const { outdoorTile } = this.props.floors;
    outdoorTile.type = value;

    this.props.setOutdoorTileFloorAction(outdoorTile);
  }

  render() {
    const { outdoorTile } = this.props.floors;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Πλακίδιο εξωτερικών χώρων'
        />

        <SimpleToggle
          label='Πλακίδιο εξωτερικών χώρων'
          onChange={ (value) => { this.onOutdoorTileChange(value); }}
          value={ outdoorTile.value }
        />

        { outdoorTile.value &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν δαπέδου (σε τ.μ.)'
              placeholder='Εμβαδόν δαπέδου (σε τ.μ.)'
              value={ outdoorTile.surface }
              onChange={ (e) => { this.onOutdoorTileSurfaceChange(e) } }
              type="number"
            />

            <SimpleSelect
              label="Τύπος δαπέδου"
              placeholder="Τύπος δαπέδου"
              options={ outdoorTileTypeOptions }
              value={ outdoorTile.type }
              onChange={ (value) => { this.onOutdoorTileTypeChange(value) } }
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setOutdoorTileFloorAction: (value) => dispatch(setOutdoorTileFloorAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(OutdoorTile);
