export const customFieldsCalc = (state) => {
  const { value, customFieldsNumber, fields } = state.customFields.customFields;

  const cost = {};
  let all = 0;

  if (value && customFieldsNumber > 0) {
    for(let i = 0; i < customFieldsNumber; i++) {
      const currentCost = fields[i].cost > 0 ? fields[i].cost : 0;

      cost[`customField${i}`] = {
        display: currentCost > 0,
        text: fields[i].text,
        cost: currentCost,
      };

      all += currentCost;
    }
  }

  const title = 'Κόστος άλλων εργασιών';
  const sumTitle = 'Συνολικό κόστος άλλων εργασιών';

  return { title, sumTitle, cost, all };
};
