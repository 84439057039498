import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setPlasterAction } from '../../../redux/actions/action-plaster';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import { SEGMENT, HEADER } from '../../../config/constants';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';

class Plaster extends Component {
  onPlasterChange(value) {
    const { plaster } = this.props.plaster;
    plaster.value = value;

    this.props.setPlasterAction(plaster);
  }

  onPlasterRoomChange(value, room) {
    const { plaster } = this.props.plaster;
    plaster.rooms[room].value = value;

    this.props.setPlasterAction(plaster);
  } 

  onPlasterRoomNumberChange(event, room) {
    const { plaster } = this.props.plaster;
    plaster.rooms[room].number = event.target.value;

    this.props.setPlasterAction(plaster);
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { plaster } = this.props.plaster;

    const { basic } = SEGMENT;
    const basicClassName = `section plaster ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;
  
    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Γύψινα'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Εργασίες με γύψινα'
            />

            <SimpleToggle
              label='Εργασίες με γύψινα'
              onChange={ (value) => { this.onPlasterChange(value); }}
              value={ plaster.value }
            />

            { plaster.value &&
              <Fragment>
                <SimpleHeader
                  headerType='h4'
                  dividing={true}
                  content='Σε ποιούς χώρους:'
                />
                
                <SimpleToggle
                  label='Σαλόνι'
                  onChange={ (value) => { this.onPlasterRoomChange(value, 'livingRoom'); }}
                  value={ plaster.rooms.livingRoom.value }
                />

                <SimpleToggle
                  label='Δωμάτιο'
                  onChange={ (value) => { this.onPlasterRoomChange(value, 'bedroom'); }}
                  value={ plaster.rooms.bedroom.value }
                />

                { plaster.rooms.bedroom.value &&
                  <SimpleInput
                    label='Αριθμός δωματίων'
                    placeholder='Αριθμός δωματίων'
                    value={ plaster.rooms.bedroom.number }
                    onChange={ (e) => { this.onPlasterRoomNumberChange(e, 'bedroom') } }
                    type="number"
                  />
                }

                <SimpleToggle
                  label='Διάδρομος'
                  onChange={ (value) => { this.onPlasterRoomChange(value, 'corridor'); }}
                  value={ plaster.rooms.corridor.value }
                />

                <SimpleToggle
                  label='Κουζίνα'
                  onChange={ (value) => { this.onPlasterRoomChange(value, 'kitchen'); }}
                  value={ plaster.rooms.kitchen.value }
                />

              </Fragment>
            }

          </SimpleSegment>
        </SimpleSegment>
      </div>
      
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setPlasterAction: (value) => dispatch(setPlasterAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Plaster);
