import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

const SimpleHeader = ({ headerType, icon, content, dividing, headerClick }) => {
  const onClick = headerClick
    ? (ev) => {
      const scrollFunctionExists = eval("typeof scrollFunction === 'function'");

      ev.target.scrollIntoView();
      window.scrollBy(0, -35);
      headerClick();

      // A function to change the scrolling from EXTERNAL SYSTEM
      if (scrollFunctionExists) {
        window.scrollFunction();
      }
    } : () => {};

  return (
    <Header as={ headerType } dividing={ dividing === true }>
      { icon &&
        <Icon name={ icon } style={{ fontSize: '0.8em' }} onClick={ onClick } />
      }
      <Header.Content onClick={ onClick }>
        { content }
      </Header.Content>
    </Header>
  );
}

export default SimpleHeader;
