import { CONSTANT_VALUES } from '../constants';

const { masonry } = CONSTANT_VALUES;

export const defaultMasonry = {
  newExternal: {
    value: false,
    surface: 0,
  },
  newInternal: {
    value: false,
    number: 0,
    internalMasonries: [], // masonry list
  },
  masonryCost: masonry,
};
