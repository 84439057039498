import React from 'react'

import ExternalMasonry from './ExternalMasonry';
import InternalMasonry from './InternalMasonry';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Masonry = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section masonry ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Νέες τοιχοποιίες'
          headerClick={ () => { headerClick(); } }
        />

        <ExternalMasonry />
        <InternalMasonry />
      </SimpleSegment>
    </div>
  );
};

export default Masonry;
