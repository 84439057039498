export const wardrobesCalc = (state) => {
  const { wardrobes, wardrobesCost } = state.wardrobes;

  let cost = {};
  let all = 0;

  if (wardrobes.value && wardrobes.number > 0 && wardrobes.wardrobes.length > 0) {
    const costArray = wardrobes.wardrobes.map((wardrobe, idx) => {
      const surface = wardrobe.width * 0.01 * wardrobe.height * 0.01;
      const currentWardrobeCost = surface * wardrobesCost.costPerM2;

      return {
        display: currentWardrobeCost > 0,
        text: `Κόστος κατασκευής ${idx + 1}ης ντουλάπας`,
        cost: currentWardrobeCost > 0 ? currentWardrobeCost : 0,
      }
    });

    all = costArray.map(c => c.cost).reduce((accumulator, currentValue) => accumulator + currentValue);
    cost = Object.assign({}, costArray);
  } else {
    cost.wardrobesCost0 = {
      display: 0,
      text: 'Κόστος κατασκευής ντουλάπας',
      cost: 0,
    }
  }

  const title = 'Ντουλάπες';
  const sumTitle = 'Συνολικό κόστος κατασκευής ντουλάπας';

  return { title, sumTitle, cost, all };
};
