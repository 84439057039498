export const bathroomWc = {
  bath: {
    newBathCostPerBath: 700,
    changeBathCostPerBath: 200,
  },
  wc: {
    newWcCostPerWc: 350,
    changeWcCostPerWc: 100,
  },
  investmentType: {
    tile: {
      cat1CostPerM2: 10,
      cat2CostPerM2: 20,
      cat3CostPerM2: 30,
      laborCostPerM2: 14,
      gluesCostPerM2: 3,
      preparationCost: 100,
    },
    forgedCementCostPerM2: 40,
  },
  sanitaryWare: {
    bath: {
      builtInShowerCost: 100,
      porcelainShowerCost: 150,
      bathhubCost: 200,
    },
    washbasin: {
      bathroomFurnitureCost: 350,
      builtInBenchCost: 400,
      colonateWashbasinCost: 100,
    },
    basin: {
      floorBasinCost: 150,
      hangingBasinCost: 350,
    },
  },
  batteriesAndAccessories: {
    bathroomAccessoryCost: 50,
    batteriesType: {
      simpleCost: 100,
      wallMountedCost: 300,
    },
  },
};
