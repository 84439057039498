import { CONSTANT_VALUES } from '../constants';

const { wardrobes } = CONSTANT_VALUES;

export const defaultWardrobes = {
  wardrobes: {
    value: false,
    number: 0,
    wardrobes: [], // wardrobe list
  },
  wardrobesCost: wardrobes,
};
