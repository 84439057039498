class Frame {
  constructor(width, height, color, material) {
    this.width = width;
    this.height = height;
    this.color = color; // Could be white, ral, efecto-madera
    this.material = material; // Could be pvc, aluminium
  }

  calcSurface() {
    return this.width * 0.01 * this.height * 0.01;
  }

  calcCost(framesCost) {
    const {
      whitePVCCostPerM2,
      ralPVCCostPerM2,
      efectoMaderaPVCCostPerM2,
      whiteAluminiumnCostPerM2,
      ralAluminiumnCostPerM2,
      efectoMaderaAluminiumnCostPerM2,
    } = framesCost.newExternalFrames;

    let costPerM2 = 0;
    const surface = this.calcSurface();

    if (this.color === 'white') {
      if (this.material === 'pvc') {
        costPerM2 = whitePVCCostPerM2;
      } else if (this.material === 'aluminium') {
        costPerM2 = whiteAluminiumnCostPerM2;
      }
    } else if (this.color === 'ral') {
      if (this.material === 'pvc') {
        costPerM2 = ralPVCCostPerM2;
      } else if (this.material === 'aluminium') {
        costPerM2 = ralAluminiumnCostPerM2;
      }
    } else if (this.color === 'efecto-madera') {
      if (this.material === 'pvc') {
        costPerM2 = efectoMaderaPVCCostPerM2;
      } else if (this.material === 'aluminium') {
        costPerM2 = efectoMaderaAluminiumnCostPerM2;
      }
    }

    return costPerM2 * surface;
  }
}

export default Frame;
