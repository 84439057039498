import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setExteriorColorsPaintsAction } from '../../../redux/actions/action-paints';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleHeader from '../../simple-components/SimpleHeader';

class ExteriorColors extends Component {
  onExteriorColorsChange(value) {
    const { exteriorColors } = this.props.paints;
    exteriorColors.value = value;

    this.props.setExteriorColorsPaintsAction(exteriorColors);
  }

  onExteriorColorsSurfaceChange(event) {
    const { exteriorColors } = this.props.paints;
    exteriorColors.surface = event.target.value;

    this.props.setExteriorColorsPaintsAction(exteriorColors);
  }

  render() {
    const { exteriorColors } = this.props.paints;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Εξωτερικοί χρωματισμοί'
        />

        <SimpleToggle
          label='Εξωτερικοί χρωματισμοί'
          onChange={ (value) => { this.onExteriorColorsChange(value); }}
          value={ exteriorColors.value }
        />

        { exteriorColors.value &&
          <SimpleInput
            label='Εμβαδόν εξωτερικών χρωματισμών (σε τ.μ.)'
            placeholder='Εμβαδόν εξωτερικών χρωματισμών (σε τ.μ.)'
            value={ exteriorColors.surface }
            onChange={ (e) => { this.onExteriorColorsSurfaceChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setExteriorColorsPaintsAction: (value) => dispatch(setExteriorColorsPaintsAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ExteriorColors);
