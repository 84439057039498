import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setWallCladdingPlasterboardAction } from '../../../redux/actions/action-plasterboard';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class WallCladding extends Component {
  onWallCladdingChange(value) {
    const { wallCladding } = this.props.plasterboard;
    wallCladding.value = value;

    this.props.setWallCladdingPlasterboardAction(wallCladding);
  }

  onWallCladdingSurfaceChange(event) {
    const { wallCladding } = this.props.plasterboard;
    wallCladding.surface = event.target.value;

    this.props.setWallCladdingPlasterboardAction(wallCladding);
  }

  render() {
    const { wallCladding } = this.props.plasterboard;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Επένδυση τοίχων με γυψοσανίδες'
        />

        <SimpleToggle
          label='Επένδυση τοίχων'
          onChange={ (value) => { this.onWallCladdingChange(value); }}
          value={ wallCladding.value }
        />

        { wallCladding.value &&
          <Fragment>
            <SimpleInput
              label='Εμβαδόν τοίχου (σε τ.μ.)'
              placeholder='Εμβαδόν τοίχου (σε τ.μ.)'
              value={ wallCladding.surface }
              onChange={ (e) => { this.onWallCladdingSurfaceChange(e) } }
              type="number"
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWallCladdingPlasterboardAction: (value) => dispatch(setWallCladdingPlasterboardAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WallCladding);
