import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setWaterproofingInsulationAction } from '../../../redux/actions/action-insulation';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const waterproofingTypeOptions = [
  { text: 'Επαλειφόμενο τσιμεντοειδές', value: 'cementitious' },
  { text: 'Ασφαλτόπανο', value: 'asphalt' },
  { text: 'Συνθετικό EPDM', value: 'epdm' },
];

class Waterproofing extends Component {
  onWaterproofingChange(value) {
    const { waterproofing } = this.props.insulation;
    waterproofing.value = value;

    this.props.setWaterproofingInsulationAction(waterproofing);
  }

  onWaterproofingTypeChange(value) {
    const { waterproofing } = this.props.insulation;
    waterproofing.type = value;

    this.props.setWaterproofingInsulationAction(waterproofing);
  }

  render() {
    const { waterproofing } = this.props.insulation;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Μόνωση δώματος (υγρομόνωση)'
        />

        <SimpleToggle
          label='Υγρομόνωση'
          onChange={ (value) => { this.onWaterproofingChange(value); }}
          value={ waterproofing.value }
        />

        { waterproofing.value &&
          <SimpleSelect
            label="Τύπος υγρομόνωσης"
            placeholder="Τύπος υγρομόνωσης"
            options={ waterproofingTypeOptions }
            value={ waterproofing.type }
            onChange={ (value) => { this.onWaterproofingTypeChange(value) } }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWaterproofingInsulationAction: (value) => dispatch(setWaterproofingInsulationAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Waterproofing);
