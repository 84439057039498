export const coatings = {
  externalMasonryCoatingsCostPerM2: 15,
  internalMasonryYTONGCoatingsCostPerM2: 15,
  wallDismantlingCoatingsCostPerWall: 70,
  electricalWorksNewNeedsCoatingsCostForOneBedroom: 275,
  electricalWorksNewNeedsCoatingsCostForTwoBedrooms: 330,
  electricalWorksNewNeedsCoatingsCostForThreeOrMoreBedroom: 400,
  rubbleRemovalElevatorCoatingsCost: 0,
  rubbleRemovalMechanicalElevatorCoatingsCost: -100,
};
