export const SET_CEILINGS_PLASTERBOARD  = 'SET_CEILINGS_PLASTERBOARD';
export const SET_WALL_CLADDING_PLASTERBOARD  = 'SET_WALL_CLADDING_PLASTERBOARD';

export const setCeilingsPlasterboardAction = (ceilings) =>
  dispatch => {
    dispatch({
      type: SET_CEILINGS_PLASTERBOARD,
      payload: ceilings,
    });
  };

export const setWallCladdingPlasterboardAction = (wallCladding) =>
  dispatch => {
    dispatch({
      type: SET_WALL_CLADDING_PLASTERBOARD,
      payload: wallCladding,
    });
  };
