import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setWardrobesAction } from '../../../redux/actions/action-wardrobes';
import Wardrobe from '../../../helpers/wardrobe';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { SEGMENT, HEADER } from '../../../config/constants';
import SimpleMessage from '../../simple-components/SimpleMessage';

class Wardrobes extends Component {
  onWardrobesChange(value) {
    const { wardrobes } = this.props.wardrobes;
    wardrobes.value = value;

    this.props.setWardrobesAction(wardrobes);
  }

  onWardrobesNumberChange(event) {
    const { wardrobes } = this.props.wardrobes;
    const currentNumber = Number(wardrobes.number);
    const newNumber = Number(event.target.value);

    if (newNumber > currentNumber) {
      const diff = newNumber - currentNumber;
      for (let i = 0; i < diff; i++) {
        wardrobes.wardrobes.push(new Wardrobe(0, 0));
      }
    } else {
      const diff = currentNumber - newNumber;
      for (let i = 0; i < diff; i++) {
        wardrobes.wardrobes.pop();
      }
    }

    wardrobes.number = newNumber;
    this.props.setWardrobesAction(wardrobes);
  }

  onWardrobeDimensionsChange(value, idx, param) {
    const { wardrobes } = this.props.wardrobes;

    if (wardrobes.wardrobes.length > idx) {
      wardrobes.wardrobes[idx][param] = value;
      this.props.setWardrobesAction(wardrobes);
    }
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { wardrobes } = this.props.wardrobes;

    const { basic } = SEGMENT;
    const basicClassName = `section wardrobes ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

    const wardrobeItems = [];
    wardrobes.wardrobes.forEach((wardrobe, idx) => {
      wardrobeItems.push(
        <Fragment key={idx}>
          <h3> Ντουλάπα {idx + 1} </h3>
          <SimpleInput
            label='Πλάτος (σε cm)'
            placeholder='Πλάτος (σε cm)'
            value={ wardrobe.width }
            onChange={ (e) => { this.onWardrobeDimensionsChange(e.target.value, idx, 'width') } }
            type="number"
          />

          <SimpleInput
            label='Υψος (σε cm)'
            placeholder='Υψος (σε cm)'
            value={ wardrobe.height }
            onChange={ (e) => { this.onWardrobeDimensionsChange(e.target.value, idx, 'height') } }
            type="number"
          />
        </Fragment>
      );
    });

    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Ντουλάπες'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Κατασκευή ντουλάπας'
            />

            <SimpleToggle
              label='Κατασκευή ντουλάπας'
              onChange={ (value) => { this.onWardrobesChange(value); }}
              value={ wardrobes.value }
            />

            { wardrobes.value &&
              <Fragment>
                <SimpleInput
                  label='Αριθμός ντουλαπών'
                  placeholder='Αριθμός ντουλαπών'
                  value={ wardrobes.number }
                  onChange={ (e) => { this.onWardrobesNumberChange(e) } }
                  type="number"
                />

                { wardrobeItems }
              </Fragment>
            }

            { wardrobes.value && wardrobes.number > 0 &&
              <SimpleMessage
                icon="info"
                header="Πληροφορίες"
                content="Η κοστολόγηση αφορά μελαμινικό σκελετό και ανοιγόμενες πόρτες"
              />
            }

            </SimpleSegment>
        </SimpleSegment>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWardrobesAction: (value) => dispatch(setWardrobesAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Wardrobes);
