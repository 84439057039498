import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setArmoredDoorsFramesAction } from '../../../redux/actions/action-frames';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class ArmoredDoors extends Component {
  onArmoredDoorsChange(value) {
    const { armoredDoors } = this.props.frames;
    armoredDoors.value = value;

    this.props.setArmoredDoorsFramesAction(armoredDoors);
  }

  onArmoredDoorsNumberChange(event) {
    const { armoredDoors } = this.props.frames;
    armoredDoors.number = event.target.value;

    this.props.setArmoredDoorsFramesAction(armoredDoors);
  }

  render() {
    const { armoredDoors } = this.props.frames;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Θωρακισμένες πόρτες'
        />

        <SimpleToggle
          label='Θωρακισμένες πόρτες'
          onChange={ (value) => { this.onArmoredDoorsChange(value); }}
          value={ armoredDoors.value }
        />

        { armoredDoors.value &&
          <SimpleInput
            label='Πλήθος θωρακισμένων πορτών'
            placeholder='Πλήθος θωρακισμένων πορτών'
            value={ armoredDoors.number }
            onChange={ (e) => { this.onArmoredDoorsNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setArmoredDoorsFramesAction: (value) => dispatch(setArmoredDoorsFramesAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ArmoredDoors);
