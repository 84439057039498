import { CONSTANT_VALUES } from '../constants';

const { plasterboard } = CONSTANT_VALUES;

export const defaultPlasterboard = {
  ceilings: {
    value: false,
    surface: 0,
    type: 'simple', // Could be simple or advanced (for architecture solution)
  },
  wallCladding: {
    value: false,
    surface: 0,
  },
  plasterboardCost: plasterboard,
};
