export const SET_KITCHEN_DISMANTLING = 'SET_KITCHEN_DISMANTLING';
export const SET_INTERNAL_FRAMES_DISMANTLING = 'SET_INTERNAL_FRAMES_DISMANTLING';
export const SET_EXTERNAL_FRAMES_DISMANTLING = 'SET_EXTERNAL_FRAMES_DISMANTLING';
export const SET_BATH_DISMANTLING = 'SET_BATH_DISMANTLING';
export const SET_WC_DISMANTLING = 'SET_WC_DISMANTLING';
export const SET_LOFT_DISMANTLING = 'SET_LOFT_DISMANTLING';
export const SET_FLOOR_DISMANTLING = 'SET_FLOOR_DISMANTLING';
export const SET_WALL_DISMANTLING = 'SET_WALL_DISMANTLING';

export const setKitchenDismantlingAction = (kitchen) =>
  dispatch => {
    dispatch({
      type: SET_KITCHEN_DISMANTLING,
      payload: kitchen,
    });
  };

export const setInternalFramesDismantlingAction = (internalFrames) =>
  dispatch => {
    dispatch({
      type: SET_INTERNAL_FRAMES_DISMANTLING,
      payload: internalFrames,
    });
  };

export const setExternalFramesDismantlingAction = (externalFrames) =>
  dispatch => {
    dispatch({
      type: SET_EXTERNAL_FRAMES_DISMANTLING,
      payload: externalFrames,
    });
  };

export const setBathDismantlingAction = (bath) =>
  dispatch => {
    dispatch({
      type: SET_BATH_DISMANTLING,
      payload: bath,
    });
  };

export const setWcDismantlingAction = (wc) =>
  dispatch => {
    dispatch({
      type: SET_WC_DISMANTLING,
      payload: wc,
    });
  };

export const setLoftDismantlingAction = (loft) =>
  dispatch => {
    dispatch({
      type: SET_LOFT_DISMANTLING,
      payload: loft,
    });
  };

export const setFloorDismantlingAction = (floor) =>
  dispatch => {
    dispatch({
      type: SET_FLOOR_DISMANTLING,
      payload: floor,
    });
  };

export const setWallDismantlingAction = (wall) =>
  dispatch => {
    dispatch({
      type: SET_WALL_DISMANTLING,
      payload: wall,
    });
  };
