import React from 'react';
import { connect } from 'react-redux';
import { setBalconyFaucetPlumbingAction } from '../../../redux/actions/action-plumbing';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class BalconyFaucet extends React.Component {
  onBalconyFaucetPlumbingChange(value) {
    const { balconyFaucet } = this.props.plumbing;
    balconyFaucet.installation = value;

    this.props.setBalconyFaucetPlumbingAction(balconyFaucet);
  }

  onBalconyFaucetNumberChange(event) {
    const { balconyFaucet } = this.props.plumbing;
    balconyFaucet.number = event.target.value;

    this.props.setBalconyFaucetPlumbingAction(balconyFaucet);
  }

  render() {
    const { balconyFaucet } = this.props.plumbing;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Βρύση στο μπαλκόνι'
        />
        
        <SimpleToggle
          label='Βρύση στο μπαλκόνι'
          onChange={ (value) => { this.onBalconyFaucetPlumbingChange(value); }}
          value={ balconyFaucet.installation }
        />

        { balconyFaucet.installation &&
          <SimpleInput
            label='Αριθμός παροχών'
            placeholder='Αριθμός παροχών'
            value={ balconyFaucet.number }
            onChange={ (e) => { this.onBalconyFaucetNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setBalconyFaucetPlumbingAction: (value) => dispatch(setBalconyFaucetPlumbingAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BalconyFaucet);
