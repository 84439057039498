import React from 'react';
import { connect } from 'react-redux';
import { setWallDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class WallDismantling extends React.Component {
  onWallDismantlingChange(value) {
    const { wall } = this.props.dismantling;
    wall.value = value;

    this.props.setWallDismantlingAction(wall);
  }

  onWallNumberChange(event) {
    const { wall } = this.props.dismantling;
    wall.number = event.target.value;

    this.props.setWallDismantlingAction(wall);
  }

  render() {
    const { wall } = this.props.dismantling;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Καθαίρεση Τοίχων'
        />
        
        <SimpleToggle
          label='Καθαίρεση Τοίχων'
          onChange={ (value) => { this.onWallDismantlingChange(value); }}
          value={ wall.value }
        />

        { wall.value &&
          <SimpleInput
            label='Αριθμός τοίχων'
            placeholder='Αριθμός τοίχων'
            value={ wall.number }
            onChange={ (e) => { this.onWallNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setWallDismantlingAction: (value) => dispatch(setWallDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(WallDismantling);
