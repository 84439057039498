import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setNewSourceHeatingAction } from '../../../redux/actions/action-heating';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const networkOptions = [
  { text: 'Εντός δαπέδου σωλήνωση (τουμπόραμα/ppr)', value: 'inFloor' },
  { text: 'Εκτός δαπέδου σωλήνωση (χαλκοσωλήνα)', value: 'outOfFloor' },
];

class NewHeatingNetwork extends Component {
  onSrcNumberChange(event, srcType) {
    const { newSource } = this.props.heating;
    const srcNumber = event.target.value;

    newSource.srcNumber[srcType] = srcNumber;
    this.props.setNewSourceHeatingAction(newSource);
  }

  onNewSourceNetworkChange(value) {
    const { newSource } = this.props.heating;
    newSource.newNetwork.value = value;

    this.props.setNewSourceHeatingAction(newSource);
  }

  onNewSourceNetworkTypeChange(value) {
    const { newSource } = this.props.heating;
    newSource.newNetwork.type = value;

    this.props.setNewSourceHeatingAction(newSource);
  }

  render() {
    const { newSource } = this.props.heating;
    const oilOrGas = newSource.value === 'oil' || newSource.value === 'gas';
    const segmentClass = `sub-segment ${oilOrGas ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Νέο δίκτυο θέρμανσης - Σωληνώσεις θέρμανσης'
        />

        <SimpleToggle
          label='Νέο δίκτυο θέρμανσης'
          onChange={ (value) => { this.onNewSourceNetworkChange(value); }}
          value={ newSource.newNetwork.value }
        />

        { newSource.newNetwork.value &&
          <Fragment>
            <SimpleInput
              label='Αριθμός σωμάτων'
              placeholder='Αριθμός σωμάτων'
              value={ newSource.srcNumber[newSource.value] }
              onChange={ (e) => { this.onSrcNumberChange(e, newSource.value) } }
              type="number"
            />

            <SimpleSelect
              label="Είδος σωλήνωσης"
              placeholder="Είδος σωλήνωσης"
              options={ networkOptions }
              value={ newSource.newNetwork.type }
              onChange={ (value) => { this.onNewSourceNetworkTypeChange(value) } }
            />
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewSourceHeatingAction: (value) => dispatch(setNewSourceHeatingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewHeatingNetwork);
