export const buildingPermitCalc = (state) => {
  const { smallScaleLicense } = state.smallScaleLicense;
  const { buildingPermit, buildingPermitCost } = state.buildingPermit;

  const permitCost = !smallScaleLicense.value && buildingPermit.value
    ? buildingPermitCost.cost : 0;

  const cost = {
    buildingPermit: {
      display: permitCost > 0,
      text: 'Κόστος έκδοσης δόμησης',
      cost: permitCost > 0 ? permitCost : 0,
    }
  };

  const all = cost.buildingPermit.cost;
  const title = 'Έκδοση άδειας δόμησης';
  const sumTitle = 'Κόστος έκδοσης δόμησης';

  return { title, sumTitle, cost, all };
};
