import { CONSTANT_VALUES } from '../constants';

const { smallScaleLicense } = CONSTANT_VALUES;

export const defaultSmallScaleLicense = {
  smallScaleLicense: {
    value: false,
  },
  smallScaleLicenseCost: smallScaleLicense,
};
