export const SET_WALL_INSULATION  = 'SET_WALL_INSULATION';
export const SET_WATERPROOFING_INSULATION  = 'SET_WATERPROOFING_INSULATION';
export const SET_THERMAL_INSULATION  = 'SET_THERMAL_INSULATION';
export const SET_INTERNAL_FLOOR_INSULATION  = 'SET_INTERNAL_FLOOR_INSULATION';

export const setWallInsulationAction = (wallInsulation) =>
  dispatch => {
    dispatch({
      type: SET_WALL_INSULATION,
      payload: wallInsulation,
    });
  };

export const setWaterproofingInsulationAction = (waterproofing) =>
  dispatch => {
    dispatch({
      type: SET_WATERPROOFING_INSULATION,
      payload: waterproofing,
    });
  };

export const setThermalInsulationAction = (thermalInsulation) =>
  dispatch => {
    dispatch({
      type: SET_THERMAL_INSULATION,
      payload: thermalInsulation,
    });
  };

export const setInternalFloorInsulationAction = (internalFloorInsulation) =>
  dispatch => {
    dispatch({
      type: SET_INTERNAL_FLOOR_INSULATION,
      payload: internalFloorInsulation,
    });
  };
