import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const houseNeedsOptions = [
  { text: 'Επαρκούν οι υφιστάμενες παροχές', value: 'dontNeedNew' },
  { text: 'Απαιτούνται νέες παροχές', value: 'needNew' },
];

class HouseNeeds extends Component {
  onHouseNeedsChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.value = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Ανάγκες κατοικίας'
        />

        <SimpleSelect
          label="Ανάγκες κατοικίας"
          placeholder="Ανάγκες κατοικίας"
          options={ houseNeedsOptions }
          value={ houseNeeds.value }
          onChange={ (value) => { this.onHouseNeedsChange(value) } }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(HouseNeeds);
