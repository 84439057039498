export const SET_BATH_PLUMBING = 'SET_BATH_PLUMBING';
export const SET_WC_PLUMBING = 'SET_WC_PLUMBING';
export const SET_BATH_WC_COATINGS = 'SET_BATH_WC_COATINGS';

export const setBathPlumbingAction = (bath) =>
  dispatch => {
    dispatch({
      type: SET_BATH_PLUMBING,
      payload: bath,
    });
  };

export const setWcPlumbingAction = (wc) =>
  dispatch => {
    dispatch({
      type: SET_WC_PLUMBING,
      payload: wc,
    });
  };

export const setBathWcCoatingsAction = (bathWcCoatings) =>
  dispatch => {
    dispatch({
      type: SET_BATH_WC_COATINGS,
      payload: bathWcCoatings,
    });
  };
