import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_NEW_AIR_CONDITION_COOLING,
} from '../actions/action-cooling';

const { newAC, coolingCost } = defaultValues.cooling;

export default (state = { newAC, coolingCost }, action) => {
  switch (action.type) {
    case SET_NEW_AIR_CONDITION_COOLING:
      return copyObject(state, {
        newAC: action.payload,
      });
    default:
      return state;
  }
}
