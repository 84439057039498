import { defaultValues } from '../../config/default-values';

const { coatingsCost } = defaultValues.coatings;

export default (state = { coatingsCost }, action) => {
  switch (action.type) {
    default:
      return state;
  }
}
