import { CONSTANT_VALUES } from '../constants';

const { floors } = CONSTANT_VALUES;

export const defaultFloors = {
  outdoorTile: {
    value: false,
    surface: 0,
    type: 'tile-cat1', // Could be tile-cat1 or tile-cat2 or tile-cat3
  },
  indoorFloor: {
    value: false,
    sameFloorType: true,
    tileCat1Surface: 0,
    tileCat2Surface: 0,
    tileCat3Surface: 0,
    laminateCat1Surface: 0,
    laminateCat2Surface: 0,
    laminateCat3Surface: 0,
    woodenNailedCat1Surface: 0,
    woodenNailedCat2Surface: 0,
    woodenNailedCat3Surface: 0,
    woodenSwimmingCat1Surface: 0,
    woodenSwimmingCat2Surface: 0,
    woodenSwimmingCat3Surface: 0,
    marbleCat1Surface: 0,
    marbleCat2Surface: 0,
    marbleCat3Surface: 0,
    vinylStripsCat1Surface: 0,
    vinylStripsCat2Surface: 0,
    vinylStripsCat3Surface: 0,
    surface: 0,
    types: [], // Could be more than one of the following types
    type: 'tile-cat2',  // Could be tile-cat1, tile-cat2, tile-cat3
                        // laminate-cat1, laminate-cat2, laminate-cat3,
                        // wooden-nailed-cat1, wooden-nailed-cat2, wooden-nailed-cat3,
                        // wooden-swimming-cat1, wooden-swimming-cat2, wooden-swimming-cat3,
                        // marble-cat1, marble-cat2, marble-cat3,
                        // vinyl-strips-cat1, vinyl-strips-cat2 or vinyl-strips-cat3
  },
  marbleAndMosaicRubbing: {
    value: false,
    surface: 0,
  },
  woodenRubbing: {
    value: false,
    surface: 0,
  },
  marbleFeet: {
    value: false,
    surface: 0,
    type: 'rubbing',    // rubbing or new-marble
  },
  floorsCost: floors,
};
