import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_KITCHEN_PLUMBING,
  SET_SOLAR_HEATER_PLUMBING, SET_BALCONY_FAUCET_PLUMBING,
  SET_WASHING_MACHINE_PLUMBING,
} from '../actions/action-plumbing';

const {
  kitchen, solarHeater, balconyFaucet, washingMachine,
} = defaultValues.plumbing;

export default (state = {
  kitchen, solarHeater, balconyFaucet, washingMachine,
}, action) => {
  switch (action.type) {
    case SET_KITCHEN_PLUMBING:
      return copyObject(state, {
        kitchen: action.payload,
      });
    case SET_SOLAR_HEATER_PLUMBING:
      return copyObject(state, {
        solarHeater: action.payload,
      });
    case SET_BALCONY_FAUCET_PLUMBING:
      return copyObject(state, {
        balconyFaucet: action.payload,
      });
    case SET_WASHING_MACHINE_PLUMBING:
      return copyObject(state, {
        washingMachine: action.payload,
      });
    default:
      return state;
  }
}
 