import React from 'react';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleMessage from '../../simple-components/SimpleMessage';
import { SEGMENT } from '../../../config/constants';

class BathWcInstallation extends React.Component {
  render() {
    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Εγκατάσταση μπάνιου / wc'
        />

        <SimpleMessage
          icon="info"
          header="Πληροφορίες"
          content="Για νέα εγκατάσταση μπάνιου / wc δείτε την κατηγορία Μπάνια / WC"
        />
      </SimpleSegment>
    );
  }
};

export default BathWcInstallation;
