import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHeatersDismantlingAction } from '../../../redux/actions/action-heating';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class HeatersDismantling extends Component {
  onHeatersDismantlingChange(value) {
    const { heatersDismantling } = this.props.heating;
    heatersDismantling.value = value;

    this.props.setHeatersDismantlingAction(heatersDismantling);
  }

  render() {
    const { newSource, heatersDismantling } = this.props.heating;
    const nothing = newSource.value === 'nothing';
    const segmentClass = `sub-segment ${nothing ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση θερμαντικών σωμάτων'
        />

        <SimpleToggle
          label='Αποξήλωση θερμαντικών σωμάτων'
          onChange={ (value) => { this.onHeatersDismantlingChange(value); }}
          value={ heatersDismantling.value }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHeatersDismantlingAction: (value) => dispatch(setHeatersDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeatersDismantling);
