import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleToggle from '../../simple-components/SimpleToggle';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class Lamps extends Component {
  onNewLampsChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.newLamps = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  onNewLampsWiringChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.newLampsWiring = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Φωτιστικά σώματα'
        />

        <SimpleToggle
          label='Τοποθέτηση νέων φωτιστικών'
          onChange={ (value) => { this.onNewLampsChange(value); }}
          value={ houseNeeds.newLamps }
        />

        <SimpleToggle
          label='Νέα καλωδίωση φωτιστικών σωμάτων'
          onChange={ (value) => { this.onNewLampsWiringChange(value); }}
          value={ houseNeeds.newLampsWiring }
        />

      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(Lamps);
