import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setHouseNeedsElectricalAction } from '../../../redux/actions/action-electrical';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class StrongNetworks extends Component {
  onStrongNetworksChange(value) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.strongNetworks.value = value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  onStrongNetworksNumberChange(event) {
    const { houseNeeds } = this.props.electrical;
    houseNeeds.strongNetworks.socketNumber = event.target.value;

    this.props.setHouseNeedsElectricalAction(houseNeeds);
  }

  render() {
    const { houseNeeds } = this.props.electrical;
    const { radicalWiringRehabilitation } = houseNeeds;
    const segmentClass = `sub-segment ${radicalWiringRehabilitation ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Νέα καλωδίωση ισχυρών δικτύων'
        />
        <SimpleToggle
          label='Νέα καλωδίωση ισχυρών δικτύων'
          onChange={ (value) => { this.onStrongNetworksChange(value); }}
          value={ houseNeeds.strongNetworks.value }
        />

        { houseNeeds.strongNetworks.value &&
          <SimpleInput
            label='Αριθμός νέων πριζών'
            placeholder='Αριθμός νέων πριζών'
            value={ houseNeeds.strongNetworks.socketNumber }
            onChange={ (e) => { this.onStrongNetworksNumberChange(e) } }
            type="number"
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setHouseNeedsElectricalAction: (value) => dispatch(setHouseNeedsElectricalAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(StrongNetworks);
