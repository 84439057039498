import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import configureStore from './redux/store';
import App from './components/SimpleApp';
import registerServiceWorker from './helpers/register-service-worker';

import './style/style.css';

ReactDOM.render (
  <Provider store={configureStore()}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

registerServiceWorker();
