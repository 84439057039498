import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_WARDROBES,
} from '../actions/action-wardrobes';

const { wardrobes, wardrobesCost } = defaultValues.wardrobes;

export default (state = {
  wardrobes, wardrobesCost,
}, action) => {
  switch (action.type) {
    case SET_WARDROBES:
      return copyObject(state, {
        wardrobes: action.payload,
      });
    default:
      return state;
  }
}
