import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_FLOOR_PRELIMINARY_QUESTIONS, SET_ROOMS_PRELIMINARY_QUESTIONS,
  SET_SURFACE_PRELIMINARY_QUESTIONS, SET_RUBBLE_REMOVAL_PRELIMINARY_QUESTIONS,
  SET_REGION_PRELIMINARY_QUESTIONS, SET_BUILDING_PRELIMINARY_QUESTIONS,
} from '../actions/action-preliminary-questions';

const { floor, rooms, surface, rubbleRemoval, region, building } = defaultValues.preliminaryQuestions;

export default (state = {
  floor, rooms, surface, rubbleRemoval, region, building,
}, action) => {
  switch (action.type) {
    case SET_FLOOR_PRELIMINARY_QUESTIONS:
      const { building } = state;
      building.floorNumber = building.floorNumber < action.payload
        ? action.payload : building.floorNumber;

      return copyObject(state, {
        floor: action.payload,
        building,
      });
    case SET_ROOMS_PRELIMINARY_QUESTIONS:
      return copyObject(state, {
        rooms: action.payload,
      });
    case SET_SURFACE_PRELIMINARY_QUESTIONS:
      return copyObject(state, {
        surface: action.payload,
      });
    case SET_RUBBLE_REMOVAL_PRELIMINARY_QUESTIONS:
      return copyObject(state, {
        rubbleRemoval: action.payload,
      });
    case SET_REGION_PRELIMINARY_QUESTIONS:
      return copyObject(state, {
        region: action.payload,
      });
    case SET_BUILDING_PRELIMINARY_QUESTIONS:
      const buildingPayload = action.payload;
      buildingPayload.floorNumber = buildingPayload.floorNumber < state.floor
        ? state.floor : buildingPayload.floorNumber;

      return copyObject(state, {
        building: buildingPayload,
      });
    default:
      return state;
  }
}
