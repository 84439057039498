const internalMasonryCost = (masonryCost, surface, type) => {
  let cost = 0;
  if (surface > 0) {
    let internalCostPerM2 = 0;

    const {
      costPlasterboard11PerM2,
      costPlasterboard22PerM2,
      ytongSurfaceThreshold,
      costYTONGLessThanOrEqualSurfaceThresholdPerM2,
      costYTONGMoreThanSurfaceThresholdPerM2,
    } = masonryCost.newInternal;

    if (type === 'plasterboard1-1') {
      internalCostPerM2 = costPlasterboard11PerM2;
    } else if (type === 'plasterboard2-2') {
      internalCostPerM2 = costPlasterboard22PerM2;
    } else if (type === 'ytong' && surface <= ytongSurfaceThreshold) {
      internalCostPerM2 = costYTONGLessThanOrEqualSurfaceThresholdPerM2;
    } else if (type === 'ytong' && surface > ytongSurfaceThreshold) {
      internalCostPerM2 = costYTONGMoreThanSurfaceThresholdPerM2;
    }

    cost = surface * internalCostPerM2; 
  }

  return cost;
}

export const masonryCalc = (state) => {
  const { newExternal, newInternal, masonryCost } = state.masonry;
    
  const newExternalSurface = newExternal.value ? newExternal.surface : 0;
  const newExternalCost = newExternalSurface * masonryCost.newExternal.costPerM2;

  let newInternalCost = 0;
  if (newInternal.value) {
    newInternal.internalMasonries.forEach((masonry) => {
      const cost = internalMasonryCost(masonryCost, masonry.surface, masonry.type);
      newInternalCost += cost;
    });
  }

  const cost = {
    newExternalCost: {
      display: newExternalCost > 0,
      text: 'Κόστος ανέγερσης νέας εξωτερικής τοιχοποιίας',
      cost: newExternalCost > 0 ? newExternalCost : 0,
    },
    newInternalCost: {
      display: newInternalCost > 0,
      text: 'Κόστος ανέγερσης νέας εσωτερικής τοιχοποιίας',
      cost: newInternalCost > 0 ? newInternalCost : 0,
    },
  };

  const all = cost.newExternalCost.cost + cost.newInternalCost.cost;

  const title = 'Νέες τοιχοποιίες';
  const sumTitle = 'Συνολικό κόστος ανέγερσης νέων τοιχοποιίων';

  return { title, sumTitle, cost, all };
};
