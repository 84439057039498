import objectAssign from 'object-assign';

import RoomDimensions from './room-dimensions';
import BathWcCoating from './bath-wc-coating';

export const copyObject = (obj1, obj2) => objectAssign({}, obj1, obj2);

export const roundNumber = number => Math.round(number * 100) / 100;

export const setCoatings = (bath, wc, bathWcCoatings) => {
  const coatingsFlag = bathWcCoatings.value;
  const bathNumber = bath.newBath ? bath.newBathNumber : 0;
  const wcNumber = wc.newWc ? wc.newWcNumber : 0;
  const hasBathWc = bathNumber !== 0 || wcNumber !== 0;
  const coatingsWithoutBathWc = coatingsFlag && !hasBathWc;
  
  let bathWcNum = 0;
  if (coatingsFlag) {
    bathWcNum = !coatingsWithoutBathWc
      ? bathNumber + wcNumber : bathWcCoatings.bathNumber;
  }
  
  const coatings = [];
  for (let i = 0; i < bathWcNum; i++) {
    const sanitaryWare = {
      bath: 'built-in-shower',
      washbasin: 'bathroom-furniture',
      basin: 'floor-basin',
    };

    const coating = new BathWcCoating(
      'tiles', 'tile-cat1', sanitaryWare, 'nothing',
    );

    coatings.push(coating);
  }

  const dimensions = [];
  if (coatingsWithoutBathWc) {
    for (let i = 0; i < coatings.length; i++) {
      dimensions.push(new RoomDimensions(0, 0, 0));
    }
  }


  return { coatings, dimensions };
};
