import React from 'react'

import InternalWalls from './InternalWalls';
import InternalRoofs from './InternalRoofs';
import ExteriorColors from './ExteriorColors';
import RailingPaint from './RailingPaint';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Paints = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section paints ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Βαφές'
          headerClick={ () => { headerClick(); } }
        />

        <InternalWalls />
        <InternalRoofs />
        <ExteriorColors />
        <RailingPaint />
      </SimpleSegment>
    </div>
  );
};

export default Paints;
