import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentSourceHeatingAction } from '../../../redux/actions/action-heating';
import SimpleSelect from '../../simple-components/SimpleSelect';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

const heatingOptions = [
  { text: 'Τίποτα', value: 'nothing' },
  { text: 'Πετρέλαιο', value: 'oil' },
  { text: 'Φυσικό Αέριο', value: 'gas' },
  { text: 'Ρεύμα', value: 'power' },
];

class CurrentHeatingSource extends Component {
  onCurrentSourceHeatingChange(value) {
    const { currentSource } = this.props.heating;
    const selectedValue = heatingOptions.find(option => option.value === value);

    if (selectedValue !== undefined && selectedValue !== null) {
      currentSource.value = selectedValue.value;
      this.props.setCurrentSourceHeatingAction(currentSource);
    }
  }

  render() {
    const { currentSource } = this.props.heating;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Υφιστάμενη πηγή θέρμανσης'
        />

        <SimpleSelect
          label="Υφιστάμενη πηγή θέρμανσης"
          placeholder="Υφιστάμενη πηγή θέρμανσης"
          options={ heatingOptions }
          value={ currentSource.value }
          onChange={ (value) => { this.onCurrentSourceHeatingChange(value) } }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setCurrentSourceHeatingAction: (value) => dispatch(setCurrentSourceHeatingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrentHeatingSource);
