import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { coatingsCalc } from '../../../calcs/coatings-calc';
import { getSummaryTrs } from '../../../helpers/react-utils';

class CoatingsSummary extends React.Component {

  render() {
    const { title, sumTitle, cost, all } = coatingsCalc(this.props);
    const trs = getSummaryTrs(title, sumTitle, cost, all, this.props.showOnlySum);

    return (
      <Fragment>{ trs }</Fragment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(CoatingsSummary);
