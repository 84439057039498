import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { customFieldsCalc } from '../../../calcs/custom-fields-calc';
import { getSummaryTrs, getSummaryTrsWithoutSum } from '../../../helpers/react-utils';

class CustomFieldsSummary extends React.Component {

  render() {
    const { title, sumTitle, cost, all } = customFieldsCalc(this.props);
    const trs = this.props.showOnlySum
      ? getSummaryTrsWithoutSum(cost)
      : getSummaryTrs(title, sumTitle, cost, all, false);

    return (
      <Fragment>{ trs }</Fragment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(CustomFieldsSummary);
