export const dismantlingCalc = (state) => {
  const { preliminaryQuestions } = state;
  const {
    rubbleRemoval, kitchen, internalFrames,
    externalFrames, bath, wc, loft, floor,
    wall, bucket, cleanliness,
  } = state.dismantling;

  let rubbleRemovalCost = 0;
  let rubbleRemovalText = 'Κόστος απομάκρυνσης μπάζων με χρήση ';

  if (preliminaryQuestions.floor >= 1) {
    if (preliminaryQuestions.rubbleRemoval === 'elevator') {
      rubbleRemovalCost = rubbleRemoval.dismantlingCost.elevator;
      rubbleRemovalText += 'ασανσέρ';
    } else if (preliminaryQuestions.rubbleRemoval === 'stairs') {
      rubbleRemovalCost = rubbleRemoval.dismantlingCost.stairs;
      rubbleRemovalText += 'κλιμακοστάσιου';
    } else if (preliminaryQuestions.rubbleRemoval === 'mechanical-elevator') {
      rubbleRemovalCost = rubbleRemoval.dismantlingCost.mechanicalElevator;
      rubbleRemovalText += 'μηχανικού μέσου';
    } else if (preliminaryQuestions.rubbleRemoval === 'hopper') {
      rubbleRemovalCost = rubbleRemoval.dismantlingCost.hopper;
      rubbleRemovalText += 'χοάνης';
    } else if (preliminaryQuestions.rubbleRemoval === 'truck') {
      rubbleRemovalCost = rubbleRemoval.dismantlingCost.truck;
      rubbleRemovalText += 'καλαθοφόρου οχήματος';
    }
  }

  let kitchenCost = 0;
  if (kitchen.value) {
    if (kitchen.type === 'furniture') {
      kitchenCost = kitchen.dismantlingCost.furniture;
    } else if (kitchen.type === 'built') {
      kitchenCost = kitchen.dismantlingCost.built;
    }
  }

  let internalFramesCost = 0;
  if (internalFrames.value) {
    if (internalFrames.moreThanThreshold) {
      internalFramesCost = internalFrames.dismantlingCost.moreThanThreshold;
    } else {
      internalFramesCost = internalFrames.dismantlingCost.lessThanThreshold;
    }
  }

  let externalFramesCost = 0;
  if (externalFrames.value) {
    if (externalFrames.moreThanThreshold) {
      externalFramesCost = externalFrames.dismantlingCost.moreThanThreshold;
    } else {
      externalFramesCost = externalFrames.dismantlingCost.lessThanThreshold;
    }
  }

  let bathCost = 0;
  let bathBucket = 0;
  const bathrooms = preliminaryQuestions.rooms.bathroom;

  if (bath.value && bathrooms > 0) {
    const bathCostPerBath = bath.dismantlingCostPerBath;
    const bathBucketPerBath = bath.dismantlingBucketPerBath;

    bathCost = bathCostPerBath * bathrooms;
    bathBucket = bathBucketPerBath * bathrooms;
  }

  let wcCost = 0;
  let wcBucket = 0;
  const wcNumber = preliminaryQuestions.rooms.wc;

  if (wc.value && wcNumber > 0) {
    const wcCostPerWc = wc.dismantlingCostPerWc;
    const wcBucketPerWc = wc.dismantlingBucketPerWc;

    wcCost = wcCostPerWc * wcNumber;
    wcBucket = wcBucketPerWc * wcNumber;
  }

  let loftCost = 0;
  let loftBucket = 0;

  if (loft.value && loft.number > 0) {
    const loftCostPerLoft = loft.dismantlingCostPerLoft;
    const loftBucketPerLoft = loft.dismantlingBucketPerLoft;

    loftCost = loftCostPerLoft * loft.number;
    loftBucket = loftBucketPerLoft * loft.number;
  }

  let floorCost = {
    costSum: 0,
    surfaceSum: 0,
    wooden: 0,
    mosaic: 0,
    tile: 0,
    marble: 0,
  };
  let floorBucket = 0;

  if (floor.value && floor.types.length > 0) {
    const wooden = Number(floor.surface.wooden);
    const mosaic = Number(floor.surface.mosaic);
    const tile = Number(floor.surface.tile);
    const marble = Number(floor.surface.marble);

    if (wooden > 0 || mosaic > 0 || tile > 0 || marble > 0) {
      const bucketPerM2 = floor.dismantlingBucketPerM2;
      const costPerM2 = floor.dismantlingCostPerM2;

      floorCost.wooden = wooden * costPerM2.wooden;
      floorCost.mosaic = mosaic * costPerM2.mosaic;
      floorCost.tile = tile * costPerM2.tile;
      floorCost.marble = marble * costPerM2.marble;

      floorCost.surfaceSum = (wooden + mosaic + tile + marble);

      floorBucket = floorCost.surfaceSum * bucketPerM2;
      floorCost.costSum = floorCost.wooden + floorCost.mosaic +
        floorCost.tile + floorCost.marble;
    }
  }

  let wallCost = 0;
  let wallBucket = 0;

  if (wall.value && wall.number > 0) {
    
    const costPerWall = wall.dismantlingCostPerWall;
    const bucketPerWall = wall.dismantlingBucketPerWall;
    
    wallCost = costPerWall * wall.number;
    wallBucket = bucketPerWall * wall.number;
  }

  const basicBucketSum = bathBucket +
    wcBucket + loftBucket + floorBucket + wallBucket;

  const bucketSum = basicBucketSum > 0
    ? Math.floor(basicBucketSum + 1) : 0;

  const bucketCost = bucketSum * bucket.costPerBucket;

  const cost = {
    kitchen: {
      display: kitchenCost > 0,
      text: kitchen.type === 'furniture'
        ? 'Κόστος αποξήλωσης έπιπλου κουζίνας'
        : 'Κόστος αποξήλωσης χτιστής κουζίνας',
      cost: kitchenCost > 0 ? kitchenCost : 0,
    },
    internalFrames: {
      display: internalFramesCost > 0,
      text: internalFrames.moreThanThreshold
        ? `Κόστος αποξήλωσης εσωτερικών κουφωμάτων περισσότερων των ${ internalFrames.threshold }`
        : `Κόστος αποξήλωσης εσωτερικών κουφωμάτων λιγότερων των ${ internalFrames.threshold }`,
      cost: internalFramesCost > 0 ? internalFramesCost : 0,
    },
    externalFrames: {
      display: externalFramesCost > 0,
      text: externalFrames.moreThanThreshold
        ? `Κόστος αποξήλωσης εξωτερικών κουφωμάτων περισσότερων των ${ externalFrames.threshold }`
        : `Κόστος αποξήλωσης εξωτερικών κουφωμάτων λιγότερων των ${ externalFrames.threshold }`,
      cost: externalFramesCost > 0 ? externalFramesCost : 0,
    },
    bath: {
      display: bathCost > 0,
      text: `Κόστος αποξήλωσης μπάνιων (${ bathrooms })`,
      cost: bathCost > 0 ? bathCost : 0,
    },
    wc: {
      display: wcCost > 0,
      text: `Κόστος αποξήλωσης wc (${ wcNumber })`,
      cost: wcCost > 0 ? wcCost : 0,
    },
    loft: {
      display: loftCost > 0,
      text: `Κόστος αποξήλωσης παταριών (${ loft.number })`,
      cost: loftCost > 0 ? loftCost : 0,
    },
    // floor: {
    //   display: floorCost.costSum > 0,
    //   wooden: {
    //     display: floorCost.wooden > 0,
    //     text: `Kόστος αποξήλωσης ξύλινων δαπέδων επιφάνειας ${ floorCost.wooden }  τ.μ.`,
    //     cost: floorCost.wooden,
    //   },
    //   mosaic: {
    //     display: floorCost.mosaic > 0,
    //     text: `Kόστος αποξήλωσης μωσαϊκών επιφάνειας ${ floorCost.mosaic }  τ.μ.`,
    //     cost: floorCost.mosaic,
    //   },
    //   tile: {
    //     display: floorCost.tile > 0,
    //     text: `Kόστος αποξήλωσης δαπέδων με πλακάκια επιφάνειας ${ floorCost.tile }  τ.μ.`,
    //     cost: floorCost.tile,
    //   },
    //   marble: {
    //     display: floorCost.marble > 0,
    //     text: `Kόστος αποξήλωσης μαρμάρινων δαπέδων επιφάνειας ${ floorCost.marble }  τ.μ.`,
    //     cost: floorCost.marble,
    //   },
    //   text: `Κόστος αποξήλωσης δαπέδων συνολικής επιφάνειας ${ floorCost.surfaceSum }  τ.μ.`,
    //   cost: floorCost.costSum > 0 ? floorCost.costSum : 0,
    // },
    floorWooden: {
      display: floorCost.wooden > 0,
      text: `Kόστος αποξήλωσης ξύλινων δαπέδων επιφάνειας ${ floor.surface.wooden } τ.μ.`,
      cost: floorCost.wooden > 0 ? floorCost.wooden : 0,
    },
    floorMosaic: {
      display: floorCost.mosaic > 0,
      text: `Kόστος αποξήλωσης μωσαϊκών επιφάνειας ${ floor.surface.mosaic } τ.μ.`,
      cost: floorCost.mosaic > 0 ? floorCost.mosaic : 0,
    },
    floorTile: {
      display: floorCost.tile > 0,
      text: `Kόστος αποξήλωσης δαπέδων με πλακάκια επιφάνειας ${ floor.surface.tile } τ.μ.`,
      cost: floorCost.tile > 0 ? floorCost.tile : 0,
    },
    floorMarble: {
      display: floorCost.marble > 0,
      text: `Kόστος αποξήλωσης μαρμάρινων δαπέδων επιφάνειας ${ floor.surface.marble } τ.μ.`,
      cost: floorCost.marble > 0 ? floorCost.marble : 0,
    },
    // floor: {
    //   display: floorCost.costSum > 0,
    //   text: `Κόστος αποξήλωσης δαπέδων συνολικής επιφάνειας ${ floorCost.surfaceSum }  τ.μ.`,
    //   cost: floorCost.costSum > 0 ? floorCost.costSum : 0,
    // },
    wall: {
      display: wallCost > 0,
      text: `Κόστος αποξήλωσης τοίχων (${ wall.number })`,
      cost: wallCost > 0 ? wallCost : 0,
    },
    bucket: {
      display: bucketCost > 0,
      text: 'Κόστος κάδων',
      cost: bucketCost > 0 ? bucketCost : 0,
    },
    cleanliness: {
      display: false,
      text: 'Κόστος καθαριότητας εργοταξίου',
      cost: 0,
    },
    rubbleRemoval: {
      display: rubbleRemovalCost > 0,
      text: rubbleRemovalText,
      cost: rubbleRemovalCost > 0 ? rubbleRemovalCost : 0,
    },
  };

  const floorCostSum = floorCost.costSum > 0 ? floorCost.costSum : 0;

  const allExceptRubbleRemoval = cost.kitchen.cost + cost.internalFrames.cost +
    cost.externalFrames.cost + cost.bath.cost +
    cost.wc.cost + cost.loft.cost + floorCostSum + cost.wall.cost +
    cost.bucket.cost;

  if (allExceptRubbleRemoval === 0) {
    cost.rubbleRemoval.display = false;
    cost.rubbleRemoval.cost = 0;
  }

  let all = allExceptRubbleRemoval + cost.rubbleRemoval.cost;

  if (all > 0) {
    cost.cleanliness.display = true;
    cost.cleanliness.cost = cleanliness.fixedCost;

    all += cleanliness.fixedCost;
  }

  const title = 'Αποξήλωση';
  const sumTitle = 'Συνολικό κόστος εργασιών αποξήλωσης';

  return { title, sumTitle, cost, all };
};
