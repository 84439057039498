import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_WALL_INSULATION, SET_WATERPROOFING_INSULATION,
  SET_THERMAL_INSULATION, SET_INTERNAL_FLOOR_INSULATION,
} from '../actions/action-insulation';

const {
  wallInsulation, waterproofing, thermalInsulation,
  internalFloorInsulation, insulationCost,
} = defaultValues.insulation;

export default (state = {
  wallInsulation, waterproofing, thermalInsulation,
  internalFloorInsulation, insulationCost,
}, action) => {
  switch (action.type) {
    case SET_WALL_INSULATION:
      return copyObject(state, {
        wallInsulation: action.payload,
      });
    case SET_WATERPROOFING_INSULATION:
      return copyObject(state, {
        waterproofing: action.payload,
      });
    case SET_THERMAL_INSULATION:
      return copyObject(state, {
        thermalInsulation: action.payload,
      });
    case SET_INTERNAL_FLOOR_INSULATION:
      return copyObject(state, {
        internalFloorInsulation: action.payload,
      });
    default:
      return state;
  }
}
 