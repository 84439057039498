import React from 'react';
import { Message } from 'semantic-ui-react';

const SimpleMessage = ({ icon, header, content }) => (
  <Message
    icon={ icon }
    header={ header }
    content={ content }
  />
)

export default SimpleMessage;
