import React from 'react';
import { Label, Select } from 'semantic-ui-react';

const SimpleSelect = ( { label, options, placeholder, onChange, display, value } ) => {
  const displayInput = display || true;

  return (
    <div style={{ padding: '5px', display: displayInput ? 'block' : 'none' }}>
      <Label pointing='right'> { label } </Label>
      <Select
        placeholder={ placeholder }
        options={ options }
        onChange={ (_, { value }) => { onChange(value) }}
        value={ value }
      />
    </div>
  );
}

export default SimpleSelect;
