import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setNewBoilerAction } from '../../../redux/actions/action-heating';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class GasBoilerSupply extends Component {
  onNewBoilerChange(value) {
    const { newBoiler } = this.props.heating;
    newBoiler.gas = value;

    this.props.setNewBoilerAction(newBoiler);
  }

  render() {
    const { newSource, newBoiler } = this.props.heating;
    const gas = newSource.value === 'gas';

    const segmentClass = `sub-segment ${gas ? '' : 'hidden-ui'}`;

    return (
      <SimpleSegment
        bgColor={ SEGMENT.subSegment.bgColor }
        color={ SEGMENT.subSegment.color }
        className={ segmentClass }
      >
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Προμήθεια λέβητα συμπύκνωσης φυσικού αερίου'
        />

        <SimpleToggle
          label='Προμήθεια λέβητα φ.α.'
          onChange={ (value) => { this.onNewBoilerChange(value); }}
          value={ newBoiler.gas }
        />
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setNewBoilerAction: (value) => dispatch(setNewBoilerAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(GasBoilerSupply);
