import React, { Component } from 'react';
import { connect } from 'react-redux';

import { roundNumber } from '../../../helpers/utils';

import { allCalc } from '../../../calcs/all-calc';

import DismantlingSummary from './DismantlingSummary';
import PlumbingSummary from './PlumbingSummary';
import HeatingSummary from './HeatingSummary';
import CoolingSummary from './CoolingSummary';
import ElectricalSummary from './ElectricalSummary';
import MasonrySummary from './MasonrySummary';
import CoatingsSummary from './CoatingsSummary';
import PlasterboardSummary from './PlasterboardSummary';
import InsulationSummary from './InsulationSummary';
import BathroomWcSummary from './BathroomWcSummary';
import PlasterSummary from './PlasterSummary';
import FloorsSummary from './FloorsSummary';
import WallCladdingSummary from './WallCladdingSummary';
import FramesSummary from './FramesSummary';
import PaintsSummary from './PaintsSummary';
import KitchenSummary from './KitchenSummary';
import WardrobesSummary from './WardrobesSummary';
import ProjectDesignAndManagementSummary from './ProjectDesignAndManagementSummary';
import ProjectInventorySummary from './ProjectInventorySummary';
import SmallScaleLicenseSummary from './SmallScaleLicenseSummary';
import BuildingPermitSummary from './BuildingPermitSummary';
import CustomFieldsSummary from './CustomFieldsSummary';

class Summary extends Component {
  render() {
    const cost = roundNumber(allCalc(this.props));

    return (
      <div
        id="renovation-calc-result"
        className="renovation-calc-result"
        style={{
          background: 'white',
          padding: '4px',
          width: 'auto',
        }}
      >
        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%',
            display: cost > 0 ? 'table' : 'none'
          }}
        >
          <thead
            style={{
              fontSize: '1.3em',
              fontWeight: 'bold',
            }}
          >
            <tr
              style={{
                background: '#464646',
                color: 'white',
              }}
            >
              <th
                style={{
                  textAlign: 'center',
                  padding: '8px',
                }}
              > Υπηρεσία </th>
              <th
                style={{
                  textAlign: 'center',
                  padding: '8px',
                }}
              > Κόστος </th>
            </tr>
          </thead>
          <tbody
            style={{

            }}
          >
            <DismantlingSummary showOnlySum={true} />
            <PlumbingSummary showOnlySum={true} />
            <HeatingSummary showOnlySum={true} />
            <CoolingSummary showOnlySum={true} />
            <ElectricalSummary showOnlySum={true} />
            <MasonrySummary showOnlySum={true} />
            <CoatingsSummary showOnlySum={true} />
            <PlasterboardSummary showOnlySum={true} />
            <InsulationSummary showOnlySum={true} />
            <BathroomWcSummary showOnlySum={true} />
            <PlasterSummary showOnlySum={true} />
            <FloorsSummary showOnlySum={true} />
            <WallCladdingSummary showOnlySum={true} />
            <FramesSummary showOnlySum={true} />
            <PaintsSummary showOnlySum={true} />
            <KitchenSummary showOnlySum={true} />
            <WardrobesSummary showOnlySum={true} />
            <CustomFieldsSummary showOnlySum={true} />
            <ProjectDesignAndManagementSummary showOnlySum={true} />
            <ProjectInventorySummary showOnlySum={true} />
            <SmallScaleLicenseSummary showOnlySum={true} />
            <BuildingPermitSummary showOnlySum={true} />

          </tbody>
          <tfoot
            style={{

            }}
          >
            <tr
              style={{
                background: 'rgb(105, 103, 103)',
                color: 'white',
                fontSize: '1.3em',
                borderTop: '2px solid #e8af30',
              }}
            >
              <td
                style={{
                  textAlign: 'right',
                  padding: '8px',
                  paddingRight: '16px',
                  borderRight: '1px solid white',
                }}
              >
                Σύνολο
              </td>
              <td
                style={{
                  textAlign: 'center',
                  padding: '8px',
                }}
              >
                { cost } €
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(Summary);
