import React from 'react';
import { connect } from 'react-redux';
import { setInternalFramesDismantlingAction } from '../../../redux/actions/action-dismantling';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';

class InternalFramesDismantling extends React.Component {
  onInternalFramesChange(value) {
    const { internalFrames } = this.props.dismantling;
    internalFrames.value = value;

    this.props.setInternalFramesDismantlingAction(internalFrames);
  }

  onMoreThanThresholdChange(value) {
    const { internalFrames } = this.props.dismantling;
    internalFrames.moreThanThreshold = value;

    this.props.setInternalFramesDismantlingAction(internalFrames);
  }
  
  render() {
    const { internalFrames } = this.props.dismantling;
    const moreThanMessage = `Περισσότερα από ${ internalFrames.threshold } τεμάχια`;

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Αποξήλωση Εσωτερικών Κουφωμάτων'
        />

        <SimpleToggle
          label='Αποξήλωση Εσωτερικών Κουφωμάτων'
          onChange={ (value) => { this.onInternalFramesChange(value); }}
          value={ internalFrames.value }
        />

        { internalFrames.value &&
          <SimpleToggle
            label={ moreThanMessage }
            onChange={ (value) => { this.onMoreThanThresholdChange(value); }}
            value={ internalFrames.moreThanThreshold }
          />
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setInternalFramesDismantlingAction: (value) => dispatch(setInternalFramesDismantlingAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(InternalFramesDismantling);
