import React from 'react';
import { connect } from 'react-redux';

import PreliminaryQuestionsAllData from './PreliminaryQuestionsAllData';
// import DismantlingAllData from './DismantlingAllData';
// import PlumbingAllData from './PlumbingAllData';
// import HeatingAllData from './HeatingAllData';
// import CoolingAllData from './CoolingAllData';
// import ElectricalAllData from './ElectricalAllData';
// import MasonryAllData from './MasonryAllData';
// import PlasterboardAllData from './PlasterboardAllData';
// import InsulationAllData from './InsulationAllData';
// import BathroomWcAllData from './BathroomWcAllData';
// import PlasterAllData from './PlasterAllData';
// import FloorsAllData from './FloorsAllData';
// import WallCladdingAllData from './WallCladdingAllData';
// import FramesAllData from './FramesAllData';
// import PaintsAllData from './PaintsAllData';
// import KitchenAllData from './KitchenAllData';
// import WardrobesAllData from './WardrobesAllData';
// import ProjectDesignAndManagementAllData from './ProjectDesignAndManagementAllData';
// import ProjectInventoryAllData from './ProjectInventoryAllData';
// import SmallScaleLicenseAllData from './SmallScaleLicenseAllData';
// import BuildingPermitAllData from './BuildingPermitAllData';
// import CustomFieldsAllData from './CustomFieldsAllData';

class AllData extends React.Component {

  // For now I am going to show only the preliminary questions to user

  render() {
    return (
      <div
        id="renovation-calc-all-data"
        className="renovation-calc-all-data"
        style={{
          background: 'white',
          padding: '4px',
          width: 'auto',
        }}
      >
        <table
          style={{
            borderCollapse: 'collapse',
            width: '100%',
          }}
        >
          {/* <thead
            style={{
              fontSize: '1.3em',
              fontWeight: 'bold',
            }}
          >
            <tr
              style={{
                background: '#464646',
                color: 'white',
              }}
            >
              <th
                style={{
                  textAlign: 'center',
                  padding: '8px',
                }}
              > Label </th>
              <th
                style={{
                  textAlign: 'center',
                  padding: '8px',
                }}
              > Τιμή </th>
            </tr>
          </thead> */}
          <tbody
            style={{

            }}
          >
            <PreliminaryQuestionsAllData />
            {/* <DismantlingAllData />
            <PlumbingAllData />
            <HeatingAllData />
            <CoolingAllData />
            <ElectricalAllData />
            <MasonryAllData />
            <PlasterboardAllData />
            <InsulationAllData />
            <BathroomWcAllData />
            <PlasterAllData />
            <FloorsAllData />
            <WallCladdingAllData />
            <FramesAllData />
            <PaintsAllData />
            <KitchenAllData />
            <WardrobesAllData />
            <CustomFieldsAllData />
            <ProjectDesignAndManagementAllData />
            <ProjectInventoryAllData />
            <SmallScaleLicenseAllData />
            <BuildingPermitAllData /> */}
          </tbody>
        </table>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps)(AllData);
