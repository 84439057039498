export const paintsCalc = (state) => {
  const { rooms, surface } = state.preliminaryQuestions;
  const { bedroom, kitchen, livingRoom, corridor } = rooms;
  const {
    internalWalls, internalRoofs, exteriorColors,
    railingPaint, paintsCost,
  } = state.paints;
  
  let internalWallsCost = 0;
  let internalRoofsCost = 0;
  let exteriorColorsCost = 0;
  let railingPaintCost = 0;

  if (internalWalls.value) {
    const internalWallsCostPerM2 = internalWalls.spatulatory
      ? paintsCost.internalWalls.spatulatoryCostPerM2
      : paintsCost.internalWalls.simpleCostPerM2;

    const bedroomSurface = bedroom * paintsCost.wallSurfaces.surfacePerBedroom;
    const kitchenSurface = kitchen * paintsCost.wallSurfaces.surfacePerKitchen;
    const livingroomSurface = livingRoom * paintsCost.wallSurfaces.surfacePerLivingroom;
    const corridorSurface = corridor * paintsCost.wallSurfaces.surfacePerCorridor;

    const internalWallsSurface = bedroomSurface + kitchenSurface + livingroomSurface + corridorSurface;

    internalWallsCost = internalWallsCostPerM2 * internalWallsSurface;
  }

  if (internalRoofs.value) {
    let internalRoofsCostPerM2 = 0;

    if (internalRoofs.spatulatory) {
      internalRoofsCostPerM2 = paintsCost.internalRoofs.spatulatoryAndPlasticCostPerM2;
    } else {
      if (internalRoofs.paintMaterial === 'plastic') {
        internalRoofsCostPerM2 = paintsCost.internalRoofs.simpleAndPlasticCostPerM2;
      } else if (internalRoofs.paintMaterial === 'watercolor') {
        internalRoofsCostPerM2 = paintsCost.internalRoofs.simpleAndWatercolorCostPerM2;
      }
    }

    internalRoofsCost = surface * internalRoofsCostPerM2;
  }

  if (exteriorColors.value) {
    exteriorColorsCost = exteriorColors.surface * paintsCost.exteriorColorsCostPerM2;
  }

  if (railingPaint.value) {
    railingPaintCost = railingPaint.length * paintsCost.railingPaintCostPerM;
  }

  const cost = {
    internalWallsCost: {
      display: internalWallsCost > 0,
      text: 'Κόστος βαφής εσωτερικών τοίχων',
      cost: internalWallsCost > 0 ? internalWallsCost : 0,
    },
    internalRoofsCost: {
      display: internalRoofsCost > 0,
      text: 'Κόστος βαφής εσωτερικών οροφών',
      cost: internalRoofsCost > 0 ? internalRoofsCost : 0,
    },
    exteriorColorsCost: {
      display: exteriorColorsCost > 0,
      text: 'Κόστος εξωτερικών χρωματισμών',
      cost: exteriorColorsCost > 0 ? exteriorColorsCost : 0,
    },
    railingPaintCost: {
      display: railingPaintCost > 0,
      text: 'Κόστος βαφής κιγκλιδωμάτων',
      cost: railingPaintCost > 0 ? railingPaintCost : 0,
    },
  }

  const all = cost.internalWallsCost.cost
    + cost.internalRoofsCost.cost
    + cost.exteriorColorsCost.cost
    + cost.railingPaintCost.cost;

  const title = 'Βαφές';
  const sumTitle = 'Συνολικό κόστος βαφών';

  return { title, sumTitle, cost, all };
};
