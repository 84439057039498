import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setProjectDesignAndManagementAction } from '../../../redux/actions/action-project-design-and-management';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';
import { SEGMENT, HEADER } from '../../../config/constants';

class ProjectDesignAndManagement extends Component {
  onProjectDesignChange(value) {
    const { projectDesignAndManagement } = this.props.projectDesignAndManagement;
    projectDesignAndManagement.value = value;

    this.props.setProjectDesignAndManagementAction(projectDesignAndManagement);
  }

  render() {
    const { isCurrent, headerClick } = this.props;
    const { projectDesignAndManagement } = this.props.projectDesignAndManagement;

    const { basic } = SEGMENT;
    const basicClassName = `section project-design-and-management ${isCurrent ? 'current-tab' : ''}`;
    const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

    return (
      <div className={ basicClassName }>
        <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
          <SimpleHeader
            headerType='h2'
            icon={ headerIcon }
            content='Σχεδιασμός / Υλοποίηση / Διαχείριση έργου'
            headerClick={ () => { headerClick(); } }
          />

          <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
            <SimpleHeader
              headerType='h3'
              dividing={true}
              content='Σχεδιασμός / Υλοποίηση / Διαχείριση έργου'
            />

            <SimpleToggle
              label='Σχεδιασμός / Υλοποίηση / Διαχείριση έργου'
              onChange={ (value) => { this.onProjectDesignChange(value); }}
              value={ projectDesignAndManagement.value }
            />

          </SimpleSegment>
        </SimpleSegment>
      </div>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setProjectDesignAndManagementAction: (value) => dispatch(setProjectDesignAndManagementAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDesignAndManagement);
