import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_BATH_PLUMBING, SET_WC_PLUMBING, SET_BATH_WC_COATINGS,
} from '../actions/action-bathroom-wc';

const {
  bath, wc, bathWcCoatings, bathroomWcCost,
} = defaultValues.bathroomWc;

export default (state = {
  bath, wc, bathWcCoatings, bathroomWcCost,
}, action) => {
  switch (action.type) {
    case SET_BATH_PLUMBING:
      return copyObject(state, {
        bath: action.payload,
      });
    case SET_WC_PLUMBING:
      return copyObject(state, {
        wc: action.payload,
      });
    case SET_BATH_WC_COATINGS:
      return copyObject(state, {
        bathWcCoatings: action.payload,
      });
    default:
      return state;
  }
}
 