import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { setInternalDoorsFramesAction } from '../../../redux/actions/action-frames';
import SimpleToggle from '../../simple-components/SimpleToggle';
import SimpleInput from '../../simple-components/SimpleInput';
import SimpleSelect from '../../simple-components/SimpleSelect';

import SimpleHeader from '../../simple-components/SimpleHeader';
import SimpleSegment from '../../simple-components/SimpleSegment';
import { SEGMENT } from '../../../config/constants';
import SimpleMultiSelect from '../../simple-components/SimpleMultiSelect';
import DoorType from '../../../helpers/door-type';

const doorMaterialOptions = [
  { text: 'Laminate', value: 'laminate' },
  { text: 'Καπλαμάς', value: 'veneer' },
  { text: 'Λάκα', value: 'lacquer' },
];

const doorTypeOptions = [
  { text: 'Ανοιγόμενη', value: 'opening' },
  { text: 'Συρόμενη', value: 'sliding' },
  { text: 'Διπλή συρόμενη', value: 'double-sliding' },
  { text: 'Σπαστή', value: 'split' },
  { text: 'Παταριού', value: 'loft' },
]

class NewInternalDoors extends Component {
  onInternalDoorChange(value) {
    const { internalDoors } = this.props.frames;
    internalDoors.value = value;

    this.props.setInternalDoorsFramesAction(internalDoors);
  }

  onInternalDoorMaterialChange(value) {
    const { internalDoors } = this.props.frames;
    internalDoors.material = value;

    this.props.setInternalDoorsFramesAction(internalDoors);
  }

  onInternalDoorTypeChange(types) {
    const { internalDoors } = this.props.frames;
    internalDoors.types = types.map(type => new DoorType(type, 1));

    this.props.setInternalDoorsFramesAction(internalDoors);
  }

  onInternalDoorNumberOfTypeChange(event, type) {
    const { internalDoors } = this.props.frames;
    const { value } = event.target;
    
    internalDoors.types = internalDoors.types.map((internalDoorType) => {
      if (internalDoorType.type === type) {
        return new DoorType(type, value);
      }

      return internalDoorType;
    });

    this.props.setInternalDoorsFramesAction(internalDoors);
  }

  render() {
    const { internalDoors } = this.props.frames;

    const numberOfInternalDoors = internalDoors.types.map((type, idx) => {
      let currentLabel = '';
      if (type.type === 'opening') {
        currentLabel = 'Αριθμός ανοιγόμενων πορτών';
      } else if (type.type === 'sliding') {
        currentLabel = 'Αριθμός συρόμενων πορτών';
      } else if (type.type === 'double-sliding') {
        currentLabel = 'Αριθμός διπλά συρόμενων πορτών';
      } else if (type.type === 'split') {
        currentLabel = 'Αριθμός σπαστών πορτών';
      } else if (type.type === 'loft') {
        currentLabel = 'Αριθμός παταριών';
      } else {
        return null;
      }

      return (
        <SimpleInput
          key={idx}
          label={currentLabel}
          placeholder={currentLabel}
          value={ type.number }
          onChange={ (e) => {
            this.onInternalDoorNumberOfTypeChange(e, type.type);
          } }
          type="number"
        />
      );
    }).filter(type => type !== null);

    return (
      <SimpleSegment bgColor={ SEGMENT.subSegment.bgColor } color={ SEGMENT.subSegment.color } className="sub-segment">
        <SimpleHeader
          headerType='h3'
          dividing={true}
          content='Τοποθέτηση νέων εσωτερικών πορτών'
        />

        <SimpleToggle
          label='Τοποθέτηση πορτών'
          onChange={ (value) => { this.onInternalDoorChange(value); }}
          value={ internalDoors.value }
        />

        { internalDoors.value &&
          <Fragment>
            <SimpleSelect
              label="Υλικό επένδυσης πόρτας"
              placeholder="Υλικό επένδυσης πόρτας"
              options={ doorMaterialOptions }
              value={ internalDoors.material }
              onChange={ (value) => { this.onInternalDoorMaterialChange(value) } }
            />

            <SimpleMultiSelect
              label="Είδος πόρτας"
              placeholder="Είδος πόρτας"
              options={ doorTypeOptions }
              values={ internalDoors.types.map(type => type.type) }
              onChange={ (values) => { this.onInternalDoorTypeChange(values) } }
            />

            { numberOfInternalDoors }
          </Fragment>
        }
      </SimpleSegment>
    );
  }
};

const mapStateToProps = state => ({ ...state });

const mapDispatchToProps = dispatch => ({
  setInternalDoorsFramesAction: (value) => dispatch(setInternalDoorsFramesAction(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewInternalDoors);
