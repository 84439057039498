import { preliminaryQuestions } from './constants/constants-preliminary-questions';
import { dismantling } from './constants/constants-dismantling';
import { plumbing } from './constants/constants-plumbing';
import { heating } from './constants/constants-heating';
import { cooling } from './constants/constants-cooling';
import { electrical } from './constants/constants-electrical';
import { masonry } from './constants/constants-masonry';
import { coatings } from './constants/constants-coatings';
import { plasterboard } from './constants/constants-plasterboard';
import { insulation } from './constants/constants-insulation';
import { bathroomWc } from './constants/constants-bathroom-wc';
import { plaster } from './constants/constants-plaster';
import { floors } from './constants/constants-floors';
import { wallCladding } from './constants/constants-wall-cladding';
import { frames } from './constants/constants-frames';
import { paints } from './constants/constants-paints';
import { kitchen } from './constants/constants-kitchen';
import { wardrobes } from './constants/constants-wardrobes';
import { projectDesignAndManagement } from './constants/constants-project-design-and-management';
import { projectInventory } from './constants/constants-project-inventory';
import { smallScaleLicense } from './constants/constants-small-scale-license';
import { buildingPermit } from './constants/contstants-building-permit';

export const SEGMENT = {
  basic: {
    color: 'orange',
    bgColor: '#fbfbfb',
  },
  subSegment: {
    color: 'grey',
    bgColor: 'white',
  }
};

export const HEADER = {
  icon: {
    opened: 'minus',
    closed: 'plus',
  },
};

export const CONSTANT_VALUES = {
  preliminaryQuestions,
  dismantling,
  plumbing,
  heating,
  cooling,
  electrical,
  masonry,
  coatings,
  plasterboard,
  insulation,
  bathroomWc,
  plaster,
  floors,
  wallCladding,
  frames,
  paints,
  kitchen,
  wardrobes,
  projectDesignAndManagement,
  projectInventory,
  smallScaleLicense,
  buildingPermit,
};
