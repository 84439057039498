import React from 'react'

import HouseNeeds from './HouseNeeds';
import RadicalWiringRehabilitation from './RadicalWiringRehabilitation';
import ElectricalPanel from './ElectricalPanel';
import PatientNetworks from './PatientNetworks';
import StrongNetworks from './StrongNetworks';
// import ElectricalNetworkExtension from './ElectricalNetworkExtension';
import MagneticContacts from './MagneticContacts';
import SecuritySystems from './SecuritySystems';
import SocketSwitcherMaterial from './SocketSwitcherMaterial';
import Lamps from './Lamps';
import ElectricalDevices from './ElectricalDevices';
import { HEADER, SEGMENT } from '../../../config/constants';
import SimpleSegment from '../../simple-components/SimpleSegment';
import SimpleHeader from '../../simple-components/SimpleHeader';

const Electrical = ({ isCurrent, headerClick }) => {
  const { basic } = SEGMENT;
  const basicClassName = `section electrical ${isCurrent ? 'current-tab' : ''}`;
  const headerIcon = isCurrent ? HEADER.icon.opened : HEADER.icon.closed;

  return (
    <div className={ basicClassName }>
      <SimpleSegment bgColor={ basic.bgColor } color={ basic.color } className="basic-segment">
        <SimpleHeader
          headerType='h2'
          icon={ headerIcon }
          content='Ηλεκτρολογικές Εργασίες'
          headerClick={ () => { headerClick(); } }
        />

        <HouseNeeds />
        <RadicalWiringRehabilitation />
        <ElectricalPanel />
        <PatientNetworks />
        <StrongNetworks />
        {/* <ElectricalNetworkExtension /> */}
        <MagneticContacts />
        <SecuritySystems />
        <SocketSwitcherMaterial />
        <Lamps />
        <ElectricalDevices />
      </SimpleSegment>
    </div>
  );
};

export default Electrical;
