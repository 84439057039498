import { copyObject } from '../../helpers/utils';

import {
  SET_ACCORDION_INDEX,
} from '../actions/action-app';

export default (state = {
  currentIdx: 0,
}, action) => {
  switch (action.type) {
    case SET_ACCORDION_INDEX:
      return copyObject(state, {
        currentIdx: action.payload,
      });
    default:
      return state;
  }
}
