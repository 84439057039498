import { copyObject } from '../../helpers/utils';
import { defaultValues } from '../../config/default-values';

import {
  SET_WALL_CLADDING,
} from '../actions/action-wall-cladding';

const { wallCladding, wallCladdingCost } = defaultValues.wallCladding;

export default (state = {
  wallCladding, wallCladdingCost,
}, action) => {
  switch (action.type) {
    case SET_WALL_CLADDING:
      return copyObject(state, {
        wallCladding: action.payload,
      });
    default:
      return state;
  }
}
