import { CONSTANT_VALUES } from '../constants';

const { paints } = CONSTANT_VALUES;

export const defaultPaints = {
  internalWalls: {
    value: false,
    spatulatory: false,
  },
  internalRoofs: {
    value: false,
    spatulatory: false,
    paintMaterial: 'plastic', // Could be plastic or watercolor
  },
  exteriorColors: {
    value: false,
    surface: 0,
  },
  railingPaint: {
    value: false,
    length: 0,
  },
  paintsCost: paints,
};
